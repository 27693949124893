import React from 'react';
import { useSelector } from 'react-redux';
import { downloadFile } from '../../utils/Common';

const CourseSyllabus = () => {
  const { currentModule } = useSelector((state) => state.module);
  const { isHelpCenter } = useSelector((state) => state.course);

  if (!currentModule.syllabus_url || currentModule.syllabus_url === '')
    return <></>;

  const handleDownload = async () => {
    try {
      let response = await fetch(currentModule.syllabus_url);
      let blob = await response.blob();

      downloadFile(
        currentModule.syllabus_file_name.split('_').splice(1).join('_'),
        blob
      );
    } catch (e) {
      alert('Something went wrong!');
    }
  };

  return (
    <>
      <div className="card bg-transparent border-0">
        <div className="card-body p-0" style={{ padding: '0 !important' }}>
          <p className="m-0">
            <a
              href="#"
              className="cursor-pointer font-poppins font-13 text-color-primary text-underline"
              title="Download Module Guide & Activity"
              onClick={handleDownload}
            >
              {isHelpCenter
                ? 'Download FAQs'
                : 'Download Module Guide & Activity'}
            </a>
          </p>
        </div>
      </div>
    </>
  );
};
export default CourseSyllabus;
