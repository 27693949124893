import React from 'react';

const TermsAndConditions = () => {
  return (
    <>
      <p className="fw-700 mb-0">Bounceback LLC</p>
      <p className="fw-700">Terms and Conditions of Use</p>

      <p>
        These Terms and Conditions of Use (“Terms of Use”) are entered into by
        Bounceback LLC, a limited liability company based in the State of
        Tennessee in the U.S.A. (“Bounceback,” “We,” “Us,” or “Our”) and you
        (“User,” “You,” or “Your”). The following Terms of Use, together with
        any documents they expressly incorporate by reference, govern Your
        access to and use of Bounceback’s website, available at{' '}
        <a href="https://www.mybounceback.com">https://www.mybounceback.com</a>,
        including any and all content, functionality, and services offered on or
        through the website (collectively, the “Website”), and the web-based
        application made accessible on the Website, available at{' '}
        <a href="https://platform.mybounceback.com">
          https://platform.mybounceback.com
        </a>{' '}
        (the “Platform”). Hereinafter, Bounceback and User may be referred to in
        the singular as a “Party” and collectively as the “Parties.”
      </p>

      <p className="fw-700">RECITALS</p>

      <p>
        <span className="fw-700">WHEREAS</span>, Bounceback is in the business
        of, among other things, creating, developing, hosting, and providing an
        online, interactive tool to and for educators and other personnel within
        scholastic institutions and educational districts (“Bounceback’s
        Services”), for the purpose of promoting, advancing, and supporting
        wellness for, and the professional development of, the educators or
        other personnel (collectively, “Bounceback’s Mission”);
      </p>

      <p>
        <span className="fw-700">WHEREAS</span>, Bounceback offers the Platform
        to its Users to provide, deliver, and facilitate Bounceback’s Services
        and to realize and actualize Bounceback’s Mission;
      </p>

      <p>
        <span className="fw-700">WHEREAS</span>, Bounceback is the owner of
        certain patentable (and non-patentable) inventions, patents, trademarks,
        trade dress, copyrightable (and non-copyrightable) works of authorship,
        copyrights, trade secrets, technology, know-how, proprietary and
        confidential information, and/or other intellectual property, including
        the intellectual property underlying the Platform (collectively, the
        “Bounceback Intellectual Property”);
      </p>

      <p>
        <span className="fw-700">WHEREAS</span>, conditioned upon the use of and
        access to the Website and the Platform, User accepts and agrees to be
        bound by Bounceback’s policy on privacy (“Privacy Policy”), which is
        incorporated herein by reference at
        https://www.mybounceback.com/privacy.
      </p>

      <p>
        <span className="fw-700">WHEREAS</span>, subject to the terms and
        conditions of these Terms of Use, Bounceback desires to provide the
        Website and the Platform for User to access and use, and User desires to
        access and use such Platform, conditioned upon acceptance of these Terms
        of Use.
      </p>

      <p>
        <span className="fw-700">NOW, THEREFORE</span>, in consideration of the
        mutual covenants and terms hereinafter set forth and for other good and
        valuable consideration, the receipt and sufficiency of which are hereby
        acknowledged, the Parties hereto agree as follows:
      </p>

      <p className="fw-700">TERMS AND CONDITIONS</p>

      <ol type="I">
        <li className="mb-3">
          <p className="fw-700">Definitions</p>
          <ol type="A">
            <li className="mb-3">
              <span className="fw-700 text-underline">Device</span>. “Device”
              means any unit of physical hardware or equipment that provides one
              or more computing functions within a computer system including,
              but not limited to, desktop computers, laptop (or otherwise
              portable) computers, mobile/cellular phones, tablets, and other
              computers capable of using or accessing the Website or the
              Platform.
            </li>
            <li>
              <span className="fw-700 text-underline">User</span>. “User” means
              any and all individuals or entities that access, use, or visit the
              Platform.
            </li>
          </ol>
        </li>
        <li className="mb-3">
          <p className="fw-700">Conditions of Use</p>
          <ol type="A">
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Eligible to be Bound
              </span>
              . This Platform is offered and available to Users who meet the
              “Eligibility Requirements,” as set forth in Schedule 1. By using
              and accessing this Platform, User represents and warrants that
              User is of legal age and of legal capacity to form a binding
              contract with Bounceback, and meets all of the Eligibility
              Requirements. If User does not meet all of the aforementioned
              requirements, User may not access or use the Platform.
            </li>

            <li className="mb-3">
              <span className="fw-700 text-underline">
                Geographic Restrictions
              </span>
              . Bounceback, the owner of the Platform, is based in the State of
              Tennessee in the U.S.A. Bounceback provides this Platform for use
              only by individuals or entities located in those regions as
              provided for in Schedule 1. Bounceback makes no claims,
              assertions, representations, or warranties that the Platform is
              accessible or appropriate outside of the regions so authorized in
              Schedule 1. If User accesses or uses the Platform from outside the
              regions so authorized in Schedule 1, User does so on User’s own
              initiative and is solely responsible for compliance with local
              laws of the unauthorized regions.
            </li>

            <li className="mb-3">
              <span className="fw-700 text-underline">
                Use of or Access to the Platform
              </span>
              . User shall read the Terms of Use carefully before User commences
              use of, or access to, the Website or the Platform. By using or
              accessing the Website or the Platform, User accepts and agrees to
              be bound and abide by these Terms of Use and Privacy Policy. If
              User elects not to agree to these Terms of Use and Privacy Policy,
              User must not access, use, or visit the Website or the Platform.
            </li>

            <li className="mb-3">
              <span className="fw-700 text-underline">
                Reservation of Right to Alter Terms of Use
              </span>
              . Bounceback, from time to time, and in its sole and absolute
              discretion and without prior notice, may revise, update, or
              otherwise modify these Terms of Use (“Changes”). User’s continued
              use of or access to the Website or the Platform, following the
              posting of revised, updated, or otherwise modified Terms of Use,
              means, signals, and indicates that User accepts and agrees to
              abide by the Changes. User is expected to routinely access, check,
              and monitor these Terms of Use so that User is on notice and
              remains abreast of any Changes, as the Changes are binding on
              User.
            </li>

            <li className="mb-3">
              <span className="fw-700 text-underline">
                Reservation of Right to Amend, Withdraw, or Suspend
              </span>
              . Bounceback, from time to time, and in its sole and absolute
              discretion and without prior notice, may withdraw, suspend, or
              amend the Website or Platform, in part or in whole, and any
              service or material Bounceback provides or delivers on the Website
              or the Platform. Bounceback will not be liable if, for any reason,
              all or any part of the Website or the Platform is unavailable at
              any time or for any period. Bounceback, from time to time, and in
              its sole and absolute discretion and without prior notice, may
              restrict, limit, or suspend access to or use of some components or
              the entirety of the Website or the Platform.
            </li>

            <li>
              <span className="fw-700 text-underline">
                Availability, Errors, and Inaccuracies
              </span>
              . Bounceback is regularly, routinely, consistently, and constantly
              updating product- and/or service-offerings on the Platform. As a
              result, Bounceback may experience delays in updating information
              on the Platform and in Our advertisements, marketing, or
              promotions on other websites, other webpages, and other online
              multi-media platforms. In addition, the information found on the
              Platform may contain errors or inaccuracies and may not be
              complete or current. Therefore, Bounceback, in its sole and
              absolute discretion and without prior notice or liability,
              reserves the right to change or update information and to correct
              errors, inaccuracies, or omissions at any time.
            </li>
          </ol>
        </li>
        <li className="mb-3">
          <p className="fw-700">Access to and Use of the Platform</p>
          <ol type="A">
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Registration Information
              </span>
              . To access or use the Platform, and its contents, resources,
              offerings, or services, User may be asked to provide certain
              Registration Information (as defined herein) or other information,
              so as to register for an account with the Platform and Bounceback
              (the “Account”). It is a condition of User’s access to or use of
              the Platform that all the information User provides on the
              Platform is correct, accurate, current, non-misleading, and
              complete. As part of the Account registration process, We require
              all Users to verify with which scholastic institutions and/or
              educational districts they are affiliated. User agrees that all
              information User provides to register with this Platform or
              otherwise is governed by Our Privacy Policy; consequently, User
              consents to all actions Bounceback takes with respect to User’s
              information consistent with and pursuant to Our Privacy Policy and
              applicable federal, state, and local laws and regulations.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Registration Personal to User
              </span>
              . User acknowledges that User’s Account is personal, confidential,
              private, and exclusive to User, and User agrees not to provide any
              third-party individual or entity with the use, access, or means by
              which to use or access this Platform or portions of the Platform
              using User’s registration information, which includes, but is not
              limited to, name (first and last), email address, login
              credentials, scholastic institution or educational district with
              which the User is affiliated, and other identifying information
              (collectively, the “Registration Information”).
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">Memberships</span>. To
              create an Account to use and access the Platform, and for ongoing
              use and access to the Platform, the User, or an entity acting on
              behalf of the User (e.g., scholastic institution or educational
              district), is required to pay Bounceback a fee for membership (a
              “Membership”). The Membership will entitle the User to full,
              uninterrupted use of and access to the Platform. At least some of
              the features available to a User having a Membership are specified
              and set forth in Schedule 3 (“Features for Users Having
              Membership”).
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Renewal and Cancellation
              </span>
              . The User’s Membership will renew at the then-applicable fee,
              unless Bounceback, in its sole and absolute discretion, elects to
              adjust the quantity of the value of the fee at renewal. User
              acknowledges and agrees that the User’s Membership will renew in
              accordance with the foregoing, unless either Bounceback or User,
              or an entity acting on behalf of the User (e.g., scholastic
              institution or educational district), elects to cancel the
              Membership. In the event that User, or the entity acting on behalf
              of the User (e.g., scholastic institution or educational
              district), fails to pay, for any reason, the fee for Membership
              access, User shall not be permitted to have full, interrupted use
              of and access to the Platform, as outlined in Schedule 3. Except
              when required by law, fees for the Membership are non-refundable.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Unauthorized Access; Security Awareness
              </span>
              . User shall immediately notify Bounceback of any unauthorized
              access to, use of, or disclosure of User’s Registration
              Information and/or Account, or any other security incident or
              breach. User shall exit from the Platform at the end of each
              session. User should exercise special caution when using or
              accessing the Platform from a public or shared computer, publicly
              accessible network, or other nonsecure network-based platforms,
              for the purpose of preventing third parties from accessing, using,
              or disclosing User’s Registration Information and/or Account. In
              the event of actual, imminent, potential, or threatened access to,
              use of, or disclosure of User’s Registration Information, or in
              the event that User has otherwise violated the Terms of Use
              herein, Bounceback reserves the right, in its sole and absolute
              discretion and without liability, to disable, restrict, limit, or
              deactivate User’s access to or use of the Platform.
            </li>
          </ol>
        </li>
        <li className="mb-3">
          <p className="fw-700">
            Other Sites and Resources; Third-Party Software
          </p>
          <ol type="A">
            <li className="mb-3">
              <span className="fw-700 text-underline">Other Terms of Use</span>.
              To the extent that You submit, present, or offer any personal
              information, including Your Registration Information, to any third
              party, or otherwise undertake the use of or access to any
              third-party site, resource, or application (“Third-Party Sites and
              Resources”), such third party’s use of such information may be
              governed by the third party’s terms of use.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Applications and Services
              </span>
              . The Platform, or the Website, may include, directly or
              indirectly, Third-Party Sites and Resources. Because we do not
              control, directly or indirectly, Third-Party Sites and Resources,
              User acknowledges and agrees that Bounceback is not responsible or
              liable, directly or indirectly, for any Third-Party Sites and
              Resources, including the performance, accuracy, integrity,
              quality, legality, usefulness, or safety of, or intellectual
              property rights relating to, Third-Party Sites and Resources.
              Bounceback has no obligation or commitment to monitor Third-Party
              Sites and Resources. Bounceback, in its sole and absolute
              discretion and without prior notice, may remove, limit, suspend,
              or otherwise restrict access to any Third-Party Sites and
              Resources (in whole or part). The availability of Third-Party
              Sites and Resources through and/or on the Platform, or the
              Website, does not imply, directly or indirectly, Our endorsement
              or sponsorship of, or Our affiliation with any third party.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Links to Other Sites and Resources
              </span>
              . If the Platform, or the Website, contains links to Third-Party
              Sites and Resources, these links are provided for User’s
              convenience only. This may include links contained in
              advertisements and other marketing endeavors, including banner
              advertisements and sponsored links. Bounceback has neither
              ownership nor control over the contents of those Third-Party Sites
              and Resources, and Bounceback is not responsible or liable,
              directly or indirectly, to User for any loss, damage, injury,
              harm, or cost arising from, related to, or in connection with
              User’s use of or reliance on the contents, goods, services,
              offers, advertisements, or promotions of the Third-Party Sites and
              Resources. If User decides to access any of the Third-Party Sites
              and Resources linked to the Platform, or the Website, User does so
              entirely at User’s own risk and peril. By accessing such
              Third-Party Sites and Resources, User is bound to those terms and
              conditions of use of the third parties owning or controlling the
              Third-Party Sites and Resources.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Third-Party Software
              </span>
              . Software User downloads in connection with the Platform consists
              of a package of components that may include certain third-party
              software (“Third-Party Software”), provided under separate terms
              and conditions (the “Third-Party Terms”). Use of the Third-Party
              Software in conjunction with the Platform, in a manner consistent
              with these Terms of Use, is permitted.
            </li>
          </ol>
        </li>
        <li className="mb-3">
          <p className="fw-700">Communications</p>
          <ol type="A">
            <li className="mb-3">
              <span className="fw-700 text-underline">Opt-Out</span>. User
              agrees to subscribe to newsletters, marketing, advertisements, or
              promotional materials and other information We may send. However,
              User may opt out of receiving any, or all, of these communications
              from Bounceback by following the unsubscribe link or instructions
              provided in any email Bounceback transmits. Bounceback does not
              discriminate against Users who elect to opt-out of promotional
              materials and other communications. Please note that a User may
              continue to receive promotional and/or non-promotional
              communications for a period of time while Bounceback works to
              deactivate Your Account. In addition, please note that You may
              receive communications confirming the activation or disablement,
              restriction, limitation, or deactivation of Your access to or use
              of the Platform. User may expressly opt out of receiving any, or
              all, of the aforementioned communications from Bounceback by
              accessing a web-link to unsubscribe, or alternatively, notifying
              Bounceback in accordance with the Privacy Policy or these Terms of
              Use, as provided for under the section titled “Comments, Concerns,
              and Complaints.”
            </li>
          </ol>
        </li>
        <li className="mb-3">
          <p className="fw-700">Uses</p>
          <ol type="A">
            <li className="mb-3">
              <span className="fw-700 text-underline">Prohibited Uses</span>.
              User may access and use the Website and the Platform only for
              lawful purposes and in accordance with these Terms of Use. User
              hereby agrees not to access or use the Website or the Platform:
              <ol type="i" className="mt-3 p-0">
                <li className="mb-3">
                  in any way that violates any applicable federal, state, local,
                  or international law or regulation (including, without
                  limitation, any laws regarding the import or export of data or
                  software to and from the United States of America or other
                  countries);
                </li>

                <li className="mb-3">
                  for the purpose of exploiting, harming, or attempting to
                  exploit or harm minors (i.e., persons below the age of
                  eighteen (18)) in any way by exposing them to inappropriate,
                  obscene, pornographic, vulgar, or lewd content, asking for
                  personally identifiable information, or otherwise;
                </li>

                <li className="mb-3">
                  to impersonate or attempt to impersonate Bounceback, a
                  Bounceback supervisor, officer, director, employee, employee
                  agent, or professional representative, another User, or any
                  other third-party person or entity;
                </li>

                <li className="mb-3">
                  to engage in any other conduct that restricts or inhibits
                  anyone’s use or enjoyment of the Website or the Platform, or
                  which, as determined in Bounceback’s sole and absolute
                  discretion, may harm, injure, or damage Bounceback or the
                  Users of the Platform, or expose Bounceback and the Users to
                  any potential, threatened, imminent, or actual liability;
                </li>

                <li className="mb-3">
                  to transmit, or procure the sending of, any advertising or
                  promotional material including any material constituting
                  solicitation (e.g., “junk mail,” “chain letter,” “spam,” or
                  any other similar solicitation); or
                </li>

                <li className="mb-3">
                  to send, knowingly receive, upload, download, process,
                  disclose, use, or re-use any material that does not comply
                  with the Use Standards, as set forth in Schedule 2.
                </li>

                <p>Moreover, User shall not:</p>

                <li className="mb-3">
                  disable, overburden, damage, or impair the Website or the
                  Platform or interfere with any other User’s use of or access
                  to the Website or the Platform, including the Users’ ability
                  to engage in real-time activities through the Website or the
                  Platform;
                </li>

                <li className="mb-3">
                  introduce any robot, spider, or other automatic device,
                  process, or means to access the Website or the Platform for
                  any purpose, including monitoring or copying any of the
                  material on the Website or the Platform;
                </li>

                <li className="mb-3">
                  use any process to monitor or copy any of the material on the
                  Website or the Platform or for any other purpose not expressly
                  authorized in these Terms of Use, without Our prior express,
                  written consent;
                </li>

                <li className="mb-3">
                  use, access, or employ any device, computer, processor, tool,
                  method, software, or routine that interferes with the proper
                  operability, functionality, viability, continuity, and
                  maintenance of the Website or the Platform;
                </li>

                <li className="mb-3">
                  introduce any viruses, Trojan horses, worms, bots, logic
                  bombs, or other code that is malicious or technologically
                  harmful;
                </li>

                <li className="mb-3">
                  attempt to gain unauthorized access to, interfere with,
                  damage, or disrupt any parts of the Website or the Platform,
                  the server on which the Website or the Platform is stored, or
                  any server, computer, or database connected to the Website or
                  the Platform;
                </li>

                <li className="mb-3">
                  attack the Website or the Platform via a denial-of-service
                  (“DoS”) attack, a distributed denial-of-service (“DDoS”)
                  attack, command and control (“C2”), bots, botnets, or any
                  other means by which a malicious actor seeks to make a machine
                  or network resource unavailable to its intended users by
                  temporarily or indefinitely disrupting services of a host or
                  server;
                </li>

                <li className="mb-3">
                  defame, harass, abuse, stalk, threaten, or otherwise violate
                  the legal rights of others, including but not limited to a
                  Bounceback director, officer, supervisor, employee, employee
                  agent, or professional representative and other Users; or
                </li>

                <li className="mb-3">
                  otherwise attempt to interfere with the proper working of the
                  Website or the Platform.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li className="mb-3">
          <p className="fw-700">Scrutiny and Enforcement</p>
          <ol type="A">
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Monitoring and Enforcement
              </span>
              . Bounceback, in its sole and absolute discretion and without
              prior notice, has the right, but is under no obligation, to:
              <ol type="i" className="p-0 mt-3">
                <li className="mb-3">
                  monitor, evaluate, analyze, assess, or alter the Website
                  and/or the Platform;
                </li>

                <li className="mb-3">
                  take appropriate legal action, including without limitation,
                  referral to law enforcement, for any illegal or unauthorized
                  use of or access to the Website or the Platform; or
                </li>

                <li className="mb-3">
                  terminate, suspend, limit, restrict, or narrow User’s access
                  to or use of all or part of the Website or the Platform for
                  any or no reason, including without limitation, any violation
                  of these Terms of Use.
                </li>
              </ol>
            </li>

            <li className="mb-3">
              <span className="fw-700 text-underline">
                Cooperation with Law Enforcement
              </span>
              . Without limiting the foregoing, Bounceback reserves the right to
              fully cooperate with any law enforcement authorities or court
              order requesting or directing Us to disclose the identity, or
              other related information, of any individual or entity, including
              the User, posting, linking, storing, sharing, submitting,
              publishing, or displaying any materials on or through the Website
              or the Platform.
            </li>
          </ol>
        </li>
        <li className="mb-3">
          <p className="fw-700">
            Limited License; Intellectual Property Rights
          </p>
          <ol type="A">
            <li className="mb-3">
              <span className="fw-700 text-underline">Grant of License</span>.
              The Platform, the Website, and the Bounceback Intellectual
              Property underlying, embodying, applicable to, or related to the
              foregoing, are licensed to Users for personal, non-commercial use
              of and access to the Website and the Platform, solely in
              accordance with these Terms of Use. Subject to Your complete and
              ongoing compliance with the terms and conditions of these Terms of
              Use, Bounceback hereby grants you a personal, limited, revocable,
              non-transferable, non-exclusive, non-commercial, and
              non-sublicensable license to:
              <ol type="i" className="p-0 mt-3">
                <li className="mb-3">
                  access and use the Website and the Platform, and the
                  Bounceback Intellectual Property therein, on compatible,
                  authorized Devices that You own or control; and
                </li>

                <li className="mb-3">
                  access and use the Website and the Platform, and the
                  Bounceback Intellectual Property therein, solely for Your own
                  use or for the entity (e.g., scholastic institution or
                  educational district) on whose behalf You are authorized to
                  act.
                </li>
              </ol>
            </li>

            <li className="mb-3">
              <span className="fw-700 text-underline">
                Limitation on License
              </span>
              . You may not modify, alter, reproduce, or distribute the Website
              or the Platform in part or entirety. You may not directly rent,
              lease, lend, sell, redistribute, or sublicense the Website or the
              Platform. You must not decompile, reverse engineer, deconstruct,
              disassemble, attempt to derive the source code or object code of,
              modify, or create derivative works of any portion of the Website
              or the Platform, any updates, or any part thereof, nor attempt to
              disable or circumvent any security or other technological measure
              designed to protect the Website or the Platform or any content
              available through the Website or the Platform. The Website and the
              Platform and its entire contents, features, and functionality
              (including but not limited to all information, software, text,
              displays, images, video and audio, and the design, selection, and
              arrangement thereof) are owned by Bounceback, its licensors, or
              other providers of such material, and are protected by United
              States and international copyright, trademark, patent, trade
              secret, and other intellectual property or proprietary rights
              laws. User may not:
              <ol type="i" className="p-0 mt-3">
                <li className="mb-3">
                  copy, modify, publish, transmit, participate in the transfer
                  or sale of, reproduce, create new works from, distribute,
                  perform, display, or in any way exploit, any of the content
                  (including related computer software) of the Website or the
                  Platform, in whole or in part;
                </li>
                <li className="mb-3">
                  use any illustrations, text, photographs, video, or audio
                  sequences or any graphics separately from the Website or the
                  Platform;
                </li>
                <li className="mb-3">
                  delete or alter any copyright, trademark, patents, or other
                  proprietary rights notices from copies of materials from the
                  Website or the Platform; or
                </li>
                <li className="mb-3">
                  access or use for any commercial purposes any part of the
                  Website or the Platform or any services or materials available
                  through the Website or the Platform, except as otherwise
                  permitted in these Terms of Use.
                </li>
              </ol>
            </li>

            <li className="mb-3">
              <span className="fw-700 text-underline">
                Breach of Restriction
              </span>
              . If You breach these license restrictions, or otherwise exceed
              the scope of the licenses granted in these Terms of Use, Your
              right to use or access the Website and/or the Platform will cease
              immediately. Any use of or access to the Website or the Platform
              not expressly permitted by these Terms of Use is a breach of these
              Terms of Use, and may violate the Bounceback Intellectual Property
              and/or other parties’ copyrights, trademarks, trade dress,
              patents, trade secrets, proprietary information, intellectual
              property, and other legally protected rights.
            </li>

            <li className="mb-3">
              <span className="fw-700 text-underline">
                Reservation of Rights
              </span>
              . All right, title, and interest in and to the Website, the
              Platform, and the Bounceback Intellectual Property shall remain
              the sole and exclusive property of Bounceback. Bounceback hereby
              expressly reserves all rights not expressly granted to the User
              under these Terms of Use.
            </li>
          </ol>
        </li>
        <li className="mb-3">
          <p className="fw-700">Representations and Warranties</p>
          <ol type="A">
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Mutual Representations and Warranties
              </span>
              . The Parties represent and warrant to the other Party the
              following:
              <ol type="i" className="p-0 mt-3">
                <li className="mb-3">
                  both Parties have the full right, power, and authority to
                  enter into these Terms of Use and to perform its obligations
                  hereunder; and
                </li>
                <li className="mb-3">
                  these Terms of Use will constitute the legal, valid, and
                  binding obligation of the Parties, enforceable against each
                  Party in accordance with its terms.
                </li>
              </ol>
            </li>

            <li className="mb-3">
              <span className="fw-700 text-underline">
                User Representations and Warranties
              </span>
              . User represents and warrants to Bounceback the following:
              <ol type="i" className="p-0 mt-3">
                <li className="mb-3">
                  User will abide by and consent to the Use Standards, as set
                  forth in Schedule 2;
                </li>
                <li className="mb-3">
                  User will not engage or participate in any activity or course
                  of action that could diminish or tarnish the image or
                  reputation of Bounceback, Bounceback’s Services, Bounceback’s
                  Mission, or the Bounceback Intellectual Property;
                </li>
                <li className="mb-3">
                  User’s use of or access to the Bounceback Intellectual
                  Property or the Platform will not infringe, misappropriate, or
                  otherwise violate the intellectual property rights or
                  proprietary rights of Bounceback;
                </li>
                <li className="mb-3">
                  User will not infringe, violate, or misappropriate any
                  third-party right, including any copyright, trademark, patent,
                  trade secret, moral right, privacy right, right of publicity,
                  or any other intellectual property or proprietary right or
                  legal right of a third-party individual or entity;
                </li>
                <li className="mb-3">
                  User will not defame, slander, libel, or invade the right of
                  privacy, publicity, or other property rights of any other
                  individual, including other Users; and
                </li>
                <li className="mb-3">
                  User will not otherwise violate the rights of any other User
                  or third party, or violate any applicable local, state,
                  federal, or international law, code, or regulation.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li className="mb-3">
          <p className="fw-700">Term</p>
          <ol type="A">
            <li className="mb-3">
              <span className="fw-700 text-underline">Commencement</span>. The
              term of these Terms of Use (the “Term”) shall commence on January
              1, {new Date().getFullYear()}. Bounceback, in its sole and
              absolute discretion and without prior notice, may terminate,
              restrict, narrow, or limit User’s access to and use of the
              Platform.
            </li>

            <li className="mb-3">
              <span className="fw-700 text-underline">
                Termination by Bounceback
              </span>
              . Notwithstanding the foregoing, Bounceback may terminate these
              Terms of Use, without opportunity for User to cure, prior to the
              end of the Term (“Termination”), if the User:
              <ol type="i" className="p-0 mt-3">
                <li className="mb-3">
                  violates any Use Standards, any representations and warranties
                  made under these Terms of Use, and any other provisions in
                  these Terms of Use;
                </li>
                <li className="mb-3">
                  materially breaches User’s obligations hereunder, including,
                  without limitation, infringement of Bounceback Intellectual
                  Property, misappropriation of Bounceback Intellectual
                  Property, any unauthorized transfer, conveyance, or license of
                  Bounceback Intellectual Property, or non-compliance with all
                  laws and regulations governing User’s use of or access to the
                  Website or the Platform;
                </li>
                <li className="mb-3">
                  infringes upon, misappropriates, or violates the intellectual
                  property rights, publicity rights, privacy rights, or other
                  legal rights of third-party individual or entity;
                </li>
                <li className="mb-3">
                  breaches or causes another to breach a contract with a
                  third-party person or entity; or
                </li>
                <li className="mb-3">
                  engages in conduct that reflects materially and unfavorably
                  upon Bounceback, Bounceback’s operations, Bounceback’s
                  Services, Bounceback’s Mission, the Bounceback Intellectual
                  Property, and/or the reputation of Bounceback.
                </li>
              </ol>
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Prohibition on Re-Registration
              </span>
              . Unless Bounceback agrees to otherwise, if Bounceback terminates
              or suspends a User’s use of or access to the Platform for any
              reason, such as the disablement of the User’s Account, the User is
              prohibited from seeking or acquiring access to, or use of, the
              Platform under the User’s name, credentials, or an alias.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Effect of Termination
              </span>
              . Upon Termination of these Terms of Use, User shall immediately
              discontinue, cease and desist, and terminate the use of or access
              to the Platform.
            </li>
          </ol>
        </li>
        <li className="mb-3">
          <p className="fw-700">Copyright Infringement.</p>
          <ol type="A">
            <li className="mb-3">
              <span className="fw-700 text-underline">In General</span>. The
              Digital Millennium Copyright Act of 1998 (the “DMCA”) provides
              recourse for copyright owners who believe that material appearing
              on the Internet infringes the copyright owners’ rights under U.S.
              copyright law.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Copyright Notification
              </span>
              . If the User believes, in good faith, that materials available on
              the Website or the Platform infringe Your copyright, You, or Your
              representative or agent, may send Bounceback a notice requesting
              that We remove the material (in whole or in part) or disable
              access to the material. If, however, the User believes, in good
              faith, that a third party (or other User) has wrongly filed a
              notice of copyright infringement against You, in connection with
              Your authorized use of or access to the Website or the Platform,
              the DMCA permits You to send Bounceback a counter-notice. Notices
              and counter-notices must meet the then-current statutory
              requirements imposed by the DMCA, as available at
              https://www.copyright.gov/dmca-directory/faq.html. Notices and
              counter-notices must be sent to:
              <ol type="i" className="p-0 mt-3">
                <li className="mb-3">E-Mail: support@mybounceback.com.</li>
              </ol>
            </li>
          </ol>
        </li>

        <li className="mb-3">
          <p className="fw-700">
            General Disclaimer; Disclaimer of Platform; Limitation of Liability
            for Platform; Equitable Relief
          </p>
          <ol type="A">
            <li className="mb-3">
              <span className="fw-700 text-underline">
                No Liability for Use of Information; No Endorsement
              </span>
              . The information conveyed and communicated on the Website and the
              Platform is solely and exclusively for general informational
              purposes. NEITHER BOUNCEBACK, ITS AFFILIATES, NOR BOUNCEBACK’S AND
              ITS AFFILIATES’ DIRECTORS, OFFICERS, SUPERVISORS, EMPLOYEES,
              AGENTS, CONTRACTORS, LICENSORS, OR OTHER PROFESSIONAL
              REPRESENTATIVES SHALL BE HELD LIABLE OR RESPONSIBLE FOR ANY ERRORS
              OR OMISSIONS ON THE WEBSITE OR THE PLATFORM OR FOR ANY DAMAGE YOU
              MAY SUFFER AS A RESULT OF ACTING UPON, RELYING UPON, OR USING THE
              INFORMATION CONVEYED AND COMMUNICATED TO YOU. BOUNCEBACK IS NOT AN
              ACCREDITED OR LICENSED SCHOLASTIC INSTITUTION OR EDUCATIONAL
              DISTRICT. BOUNCEBACK DOES NOT RECOMMEND OR ENDORSE ANY CLINICIANS,
              COUNSELORS, PHYSICIANS, EDUCATORS, INSTITUTIONS, PSYCHIATRISTS,
              SOCIAL WORKERS, PRODUCTS, PROCEDURES, OPNIONS, OR OTHER CONTENT
              AND INFORMATION PRESENTED ON THE WEBSITE OR THE PLATFORM, UNLESS
              OTHERWISE STATED.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">General Disclaimer</span>.
              THE CONTENT AND INFORMATION CONVEYED AND COMMUNICATED ON THE
              PLATFORM AND THE WEBSITE ARE FOR INFORMATINOAL PURPOSES ONLY. THE
              CONTENT IS NOT INTENDED TO BE A SUBSTITUTE FOR PROFESSIONAL
              ADVICE, DIAGNOSIS, OR TREATMENT OR AS A SUBSTITUTE FOR LICENSED
              AND/OR AUTHORIZED MENTAL-HELATH OR PHYSICAL-HEALTH EXERCISES,
              PRACTICES, OR TECHNIQUES. IF YOU HAVE ANY QUESTIONS REGARDING YOUR
              WELLNESS OR HEALTH, ALWAYS SEEK THE ADVICE OF A LICENSED HEALTH
              CARE PROVIDER, A MENTAL HEALTH PROFESSIONAL, OR ANOTHER QUALIFIED
              HEALTH CARE PROVIDER. NEVER DISREGARD SEEKING PROFESSIONAL ADVICE
              OR DELAY IN SEEKING SAID ADVICE BECAUSE OF SOMETHING YOU HAVE READ
              OR UNDERSTOOD ON THE WEBSITE OR THE PLATFORM. IF YOU ARE IN A
              PHYSICAL, MENTAL, OR PSYCHOLOGICAL HEALTH CRISIS, INCLUDING
              IMMINENT, ACTUAL, OR THREATS OF VIOLENCE, INJURY (TO THE SELF OR
              TO THE OTHERS), PLEASE IMMEDIATELY DIAL “911.” BOUNCEBACK IS UNDER
              NO OBLIGATION TO MONITOR OR RESPOND TO EMERGENCY COMMUNICATIONS ON
              AND THROUGH THE WEBSITE OR THE PLATFORM.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">
                No Warranty as to Contents of Website or Platform
              </span>
              . BOUNCEBACK MAKES NO, AND YOU ACKNOWLEDGE THAT YOU RECEIVE NO,
              WARRANTY (PROMISE), EXPRESS OR IMPLIED, INCLUDING A WARRANTY OF
              NON-INFRINGEMENT, WITH RESPECT TO THE CONTENTS OF THE WEBSITE OR
              THE PLATFORM OR THE FUNCTIONALITY, VIABILITY, CONTINUITY, OR
              OPERABILITY OF THE WEBSITE OR THE PLATFORM. YOUR USE OF OR ACCESS
              TO THE WEBSITE OR THE PLATFORM, OR THE CONTENT OR SERVICES
              OBTAINED THROUGH THE WEBSITE OR THE PLATFORM, IS AT YOUR OWN RISK.
              THE PLATFORM AND THE WEBSITE IS PROVIDED ON AN “AS IS” AND “AS
              AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
              EXPRESS OR IMPLIED. ANY STATEMENTS MADE TO THE CONTRARY ARE VOID.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">
                No Warranty as to Functionality of Platform
              </span>
              . NEITHER BOUNCEBACK, BOUNCEBACK’S AFFILIATES, NOR BOUNCEBACK’S
              AND ITS AFFILIATES’ DIRECTORS, OFFICERS, SUPERVISORS, EMPLOYEES,
              AGENTS, CONTRACTORS, LICENSORS, OR OTHER PROFESSIONAL
              REPRESENTATIVES MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT
              TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
              AVAILABILITY OF THE WEBSITE OR THE PLATFORM. WITHOUT LIMITING THE
              FOREGOING, NEITHER BOUNCEBACK, BOUNCEBACK’S AFFILIATES, NOR
              BOUNCEBACK’S AND ITS AFFILIATES’ DIRECTORS, OFFICERS, SUPERVISORS,
              EMPLOYEES, AGENTS, CONTRACTORS, LICENSORS, OR OTHER PROFESSIONAL
              REPRESENTATIVES REPRESENTS OR WARRANTS THAT THE WEBSITE OR THE
              PLATFORM, OR THE CONTENTS AND SERVICES OBTAINED THROUGH THE
              WEBSITE OR THE PLATFORM, WILL BE ACCURATE, RELIABLE, ERROR-FREE,
              OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE
              PLATFORM OR THE WEBSITE, OOR THE SERVER THAT MAKES THEM AVAILABLE,
              ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE
              PLATFORM OR THE WEBSITE, OR ANY CONTENT OR SERVICES OBTAINED
              THROUGH THE WEBSITE OR THE PLATFORM WILL OTHERWISE MEET YOUR NEEDS
              OR EXPECTATIONS.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Limitation of Liability
              </span>
              . TO THE FULLEST EXTENT PROVIDED BY LAW, NEITHER BOUNCEBACK,
              BOUNCEBACK’S AFFILIATES, NOR BOUNCEBACK’S AND ITS AFFILIATES’
              DIRECTORS, OFFICERS, SUPERVISORS, EMPLOYEES, AGENTS, CONTRACTORS,
              LICENSORS, OR OTHER PROFESSIONAL REPRESENTATIVES WILL BE LIABLE OR
              RESPONSIBLE FOR ANY LOSS OR DAMAGE CAUSED BY A DENIAL-OF-SERVICE
              ATTACK, DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, ANY MALWARE
              OR MALICIOUS CODE, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT
              MAY INFECT YOUR DEVICES, COMPUTER PROGRAMS, DATA, OR OTHER
              PROPRIETARY MATERIAL DUE TO YOUR USE OF OR ACCESS TO THE WEBSITE
              OR THE PLATFORM, OR ANY SERVICES OBTAINED THROUGH THE WEBSITE OR
              THE PLATFORM OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED,
              PUBLISHED, DISPLAYED, OR TRANSMITTED ON THE WEBSITE OR THE
              PLATFORM, OR ON ANY WEBSITE OR OTHER THIRD-PARTY MULTI-MEDIA
              PLATFORM OR THIRD-PARTY SITES AND RESOURCES LINKED TO THE WEBSITE
              OR THE PLATFORM.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Disclaimer of Certain Damages
              </span>
              . THE PARTIES HERETO AGREE THAT, NOTWITHSTANDING ANY OTHER
              PROVISION IN THIS AGREEMENT, EXCEPT FOR (A) USER’S USE OF THE
              WEBSITE OR THE PLATFORM OTHER THAN EXPRESSLY PERMITTED IN THESE
              TERMS OF USE ABOVE, AND (B) LIABILITY ARISING FROM THE USER’S
              INDEMNIFICATION OBLIGATIONS BELOW, IN NO EVENT WILL EITHER PARTY
              BE LIABLE TO THE OTHER FOR ANY SPECIAL, INDIRECT, RELIANCE,
              INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND, LOST OR DAMAGED
              DATA, LOST PROFITS OR LOST REVENUE, WHETHER ARISING IN CONTRACT,
              TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EVEN IF A PARTY HAS
              BEEN NOTIFIED OF THE POSSIBILITY THEREOF.
            </li>
          </ol>
        </li>
        <li className="mb-3">
          <p className="fw-700">Indemnification; Arbitration</p>
          <ol type="A">
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Indemnification by User
              </span>
              . Bounceback assumes no liability to the User or third parties
              with respect to the operability, functionality, viability,
              continuity, and maintenance of the Website or the Platform. In
              addition, Bounceback assumes no liability to the User regarding
              the accuracy of the marketing, delivery, display, performance,
              distribution, or use of the Bounceback Intellectual Property or
              any segments thereof. User acknowledges that Bounceback shall not
              be responsible, in any way, for advice or information given by the
              User to third parties in connection with the Platform, the
              Website, or the Bounceback Intellectual Property or otherwise.
              User shall indemnify, hold harmless, and defend Bounceback and its
              directors, officers, supervisors, employees, agents, professional
              representatives, affiliates, and successors against any and all
              losses, damages, liabilities, deficiencies, claims, actions,
              judgments, settlements, interest, awards, penalties, fines, costs,
              or expenses of whatever kind, including professional fees and
              attorneys’ fees, that are incurred by Bounceback or awarded
              against Bounceback in any judgment, administrative proceeding, or
              any alternative dispute resolution proceeding, arising out of any
              third-party claim alleging:
              <ol className="mt-3 p-0" type="i">
                <li className="mb-3">
                  breach or non-fulfillment of any representation, warranty, or
                  covenant set forth in these Terms of Use;
                </li>
                <li className="mb-3">
                  any negligent or more culpable act or omission (including any
                  reckless or willful misconduct) of User or its agents,
                  officers, employees, partners, attorneys, or other
                  professional representatives, in connection with the
                  performance of its obligations under these Terms of Use;
                </li>
                <li className="mb-3">
                  any infringement, violation, or misappropriation of third
                  parties’ intellectual property rights in connection with or
                  related to the Website, the Platform, the Bounceback
                  Intellectual Property, or these Terms of Use;
                </li>
                <li className="mb-3">
                  any failure by User to abide by the Use Standards; or
                </li>
                <li className="mb-3">
                  any failure by User to comply with any applicable federal,
                  state, or local laws, regulations, or codes in the performance
                  of its obligations under these Terms of Use.
                </li>
              </ol>
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Procedure for Indemnification
              </span>
              . Bounceback shall notify the User in writing of any and all
              losses, damages, liabilities, deficiencies, claims, actions,
              judgments, settlements, interest, awards, penalties, fines, costs,
              or expenses of whatever kind arising out of the any third-party
              claim alleging the aforementioned claims. User shall cooperate
              with Bounceback at the User’s sole cost and expense. User shall
              not settle, nor attempt to settle, any matter in a manner that
              adversely affects the rights of Bounceback without Bounceback’s
              prior written consent. To the extent applicable, Bounceback may
              participate in and observe the proceedings at its own cost and
              expense.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">Arbitration</span>. In
              Bounceback’s sole and absolute discretion, Bounceback may require
              User to submit any disputes arising from, related to, or in
              connection with these Terms of Use or access to or use of the
              Website or the Platform, including disputes arising from or
              concerning their interpretation, violation, invalidity,
              non-performance, or termination, to final and binding arbitration
              under the Rules of Arbitration of the American Arbitration
              Association applying the laws of the United States of America and
              the State of Tennessee. In addition, this agreement to arbitrate
              disputes includes all claims arising out of or relating to any
              aspect of these Terms of Use, whether based in contract, tort,
              statute, fraud, misrepresentation, or any other legal theory, and
              regardless of whether a claim arises during or after the
              termination of these Terms of Use. YOU UNDERSTAND AND AGREE THAT,
              BY ENTERING INTO THESE TERMS OF USE, YOU ARE WAIVING THE RIGHT TO
              A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION TO THE FULLEST
              EXTENT PERMITTED UNDER APPLICABLE LAW.
            </li>
          </ol>
        </li>
        <li className="mb-3">
          <p className="fw-700">
            Entirety of the Terms of Use; General Provisions; Equitable Relief
          </p>
          <ol type="A">
            <li className="mb-3">
              <span className="fw-700 text-underline">Equitable Relief</span>.
              User recognizes that, in the event the User fails to perform,
              observe, or discharge any of its obligations or liabilities under
              these Terms of Use, or threatens to fail to perform, observe or
              discharge such obligations or liabilities, any remedy at law may
              prove to be inadequate relief to Bounceback; therefore, Bounceback
              shall be entitled to temporary and permanent injunctive relief in
              any such case without the necessity of proving that actual damages
              are not an adequate remedy. User acknowledges that actual damages
              would be both inadequate and insufficient to protect Bounceback
              from liability related to User’s failure to perform, observe, or
              discharge any of its obligations or liabilities under these Terms
              of Use.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">Privacy Policy</span>.
              Conditioned upon the use of and access to the Platform and its
              contents, services, and resources, Bounceback mandates User to
              review and agree to be bound by Bounceback’s policy on its
              handling and processing of User’s data and information, including
              personally identifiable information (“Privacy Policy”), which is
              incorporated herein by reference at:{' '}
              <a href="https://www.mybounceback.com/privacy">
                https://www.mybounceback.com/privacy
              </a>
              .
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">
                Comments, Concerns, and Complaints
              </span>
              . All notices of infringement or misappropriation as to the
              Bounceback Intellectual Property or any third-party’s intellectual
              property should be directed to:{' '}
              <a href="mailto:support@mybounceback.com">
                support@mybounceback.com
              </a>
              . All other feedback, comments, requests for technical support,
              and other communications relating to the Website or the Platform
              should be directed to:{' '}
              <a href="mailto:support@mybounceback.com">
                support@mybounceback.com
              </a>
              .
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">Merger</span>. Each Party
              acknowledges that it has read these Terms of Use, that it
              understands it, and that it is the complete and exclusive
              statement of the agreement between the Parties, which supersedes
              and merges all prior and contemporaneous proposals,
              representations and warranties, understandings, and all other
              agreements, oral and written, between the Parties relating to the
              subject matter of these Terms of Use and the Website.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">Enforceability</span>. If
              any provision of these Terms of Use is held by a court or other
              tribunal of competent jurisdiction to be invalid, illegal, or
              unenforceable for any reason, such provision shall be eliminated
              or limited to the minimum extent such that the remaining
              provisions of these Terms of Use will continue in full force and
              effect. To the extent any provision of these Terms of Use is
              judicially determined to be invalid, illegal, or unenforceable for
              any reason, a court of competent jurisdiction may reform any such
              provision to make it valid, legal, and enforceable.
            </li>
            <li className="mb-3">
              <span className="fw-700">Governing Law; Jurisdiction</span>. These
              Terms of Use are governed and interpreted pursuant to the laws of
              the State of Tennessee, United States of America, notwithstanding
              any principles of conflict of law. For all disputes, legal suits,
              actions, or proceedings not subject to arbitration under Section
              XV hereof, You agree to irrevocably submit to the personal
              jurisdiction and venue of the courts located within or embracing
              Williamson County, Brentwood, Tennessee, and waive any and all
              objections to the exercise of jurisdiction over You by such
              courts, as well as venue in such courts.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">No Assignment</span>.
              These Terms of Use may not be assigned or transferred by User
              without Bounceback’s prior written consent. User acknowledges and
              agrees that these Terms of Use, and all incorporated agreements,
              may be automatically assigned by Bounceback in its sole and
              absolute discretion.
            </li>
            <li className="mb-3">
              <span className="fw-700 text-underline">Attachments</span>. The
              exhibits, schedules, and appendices attached hereto are included
              in these Terms of Use and incorporated into these Terms of Use as
              part of a unified document.
            </li>
          </ol>

          <div>
            <p className="fw-700 text-underline">
              SCHEDULE 1 – ELIGIBILITY REQUIREMENTS
            </p>
            <p>
              In order to use or access the Platform, the User must meet the
              following eligibility requirements:
            </p>

            <ol type="1">
              <li className="mb-3">
                User must be of legal age (eighteen (18) years or older) and of
                legal capacity to form a binding contract with Bounceback; and
              </li>
              <li className="mb-3">
                User must be an educator or other personnel affiliated with, or
                employed by, a scholastic institution or educational district.
              </li>
            </ol>

            <p className="fw-700 text-underline">SCHEDULE 2 – USE STANDARDS</p>
            <p>
              Without limiting the foregoing and pursuant to the Terms of Use,
              User shall adhere to the following Use Standards. User shall not:
            </p>

            <ol type="1">
              <li className="mb-3">
                Infringe or misappropriate any Bounceback Intellectual Property
                or other intellectual property rights of any third party;
              </li>
              <li className="mb-3">
                Violate the legal rights (including the rights of publicity and
                privacy) of third-party individuals or entities or contain any
                material that could give rise to any civil or criminal liability
                under applicable laws or regulations or that otherwise may be in
                conflict with these Terms of Use and the Privacy Policy;
              </li>
              <li className="mb-3">
                Conceal, mispresent, or omit any material information to
                Bounceback or any third party in connection with the Terms of
                Use and the Platform;
              </li>
              <li className="mb-3">
                Promote any illegal activity, or advocate, promote, or assist
                any unlawful act;
              </li>
              <li className="mb-3">
                Cause annoyance, inconvenience, or needless anxiety or be likely
                to upset, harass, mortify, humiliate, embarrass, alarm,
                aggravate, or annoy any other third-party person or entity;
              </li>
              <li className="mb-3">
                Impersonate any person, or misrepresent, mislead, or conceal the
                identity or affiliation with Bounceback or any third-party
                person or entity; and
              </li>
              <li className="mb-3">
                Involve commercial activities or sales, such as contests,
                sweepstakes, and other sales promotions, barter, marketing or
                advertising, except as otherwise authorized by Bounceback.
              </li>
            </ol>

            <p className="fw-700 text-underline">
              SCHEDULE 3 – FEATURES FOR USERS HAVING MEMBERSHIP
            </p>
            <p>
              The following features are available to a User who registered for
              an Account, having Membership. The exclusive features available
              for Users who have registered an Account to access and use the
              Platform are as follows:
            </p>

            <ol type="i">
              <li className="mb-3">
                Video content promoting wellness and professional development;
              </li>
              <li className="mb-3">Interactive surveys;</li>
              <li className="mb-3">
                Module guides and other downloadable documents; and
              </li>
              <li className="mb-3">
                Other such features authorized and created by Bounceback.
              </li>
            </ol>
          </div>
        </li>
      </ol>
    </>
  );
};

export default TermsAndConditions;
