import React, { useEffect, useState } from 'react';
import ModuleHeader from './ModuleHeader';
import SectionHome from './SectionHome';
import SectionItem from './SectionItem/SectionItem';
import Accordion from 'react-bootstrap/Accordion';
import { useDispatch, useSelector } from 'react-redux';
import SurveyBox from './SurveyBox';
import Survey from './Survey';
import { fetchSurvey } from '../redux/actions/surveyActions';
import CustomSpinner from './CustomSpinner';
import { useAuthContext } from '../context/authContext';

const Module = (props) => {
  const dispatch = useDispatch();

  const {
    state: { user },
  } = useAuthContext();

  const { showKnowledgeChecks } = useSelector((state) => state.knowledgeCheck);
  const { currentModule } = useSelector((state) => state.module);
  const { isHelpCenter } = useSelector((state) => state.course);
  const { currentSection, sections, loadingSections } = useSelector(
    (state) => state.section
  );

  const [isSurveyStart, setIsSurveyStart] = useState(false);

  const { campaign_content } = useSelector((state) => state.campaign);

  const [localItems, setLocalItems] = useState([]);
  const [stopVideo, setStopVideo] = useState(false);
  const [moduleContinue, setModuleContinue] = useState(true);

  const onClickContinueButton = () => setModuleContinue(!moduleContinue);

  const sortItem = (a, b) => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  };

  const onSurveyClick = () => {
    setIsSurveyStart(!isSurveyStart);
    dispatch(fetchSurvey(currentSection?.id));
  };

  const goBack = () => {
    setIsSurveyStart(false);
  };

  useEffect(() => {
    if (sections && sections.length > 0) {
      if (
        !!!localItems.length ||
        JSON.stringify(localItems) !== JSON.stringify(sections)
      ) {
        setLocalItems([...sections].sort(sortItem));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  if (isHelpCenter && !currentModule) {
    return (
      <>
        {props.main_school_graphic ? (
          <div id="org-graphic-container">
            <h1>
              <img
                tabIndex="0"
                alt={
                  user.schools[0].notes
                    ? user.schools[0].notes
                    : 'Bounceback Disctrict Welcome Image'
                }
                src={props.main_school_graphic}
              />
            </h1>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }

  if (loadingSections) {
    return <CustomSpinner />;
  }

  return (
    <>
      {!currentModule ? (
        <CustomSpinner />
      ) : (
        <section aria-label={currentModule.name + ' module'}>
          {moduleContinue && (
            <ModuleHeader
              moduleId={currentModule.id}
              moduleName={currentModule.name}
              description={currentModule.description || 'Module description'}
              onClick={onClickContinueButton}
            />
          )}

          {loadingSections ? (
            <CustomSpinner />
          ) : !(sections && sections?.length > 0) ? (
            <></>
          ) : (
            <Accordion
              id={`kc-ac-${currentModule?.id}`}
              className={`kc-accordeon ${
                showKnowledgeChecks ? 'kc-container' : 'section-container'
              }`}
            >
              {localItems.map((section, idx) => {
                let sectionBody;
                if (
                  (!section.items ||
                    (section.items && !section.items.length)) &&
                  !section.has_surveys
                ) {
                  sectionBody = <>No data</>;
                } else {
                  sectionBody = (
                    <>
                      {campaign_content?.allowed_videos_ids?.length &&
                      !campaign_content?.allowed_videos_ids?.includes(
                        section?.items[0]?.id
                      ) ? (
                        <div>One day at a time!</div>
                      ) : (
                        <SectionHome
                          section={section}
                          stopVideo={stopVideo}
                          fromFavorites={false}
                        />
                      )}
                      {section && section.has_surveys ? (
                        <div
                          className="section-home-container"
                          onClick={() => {
                            // avoid onClick event in Accordion been executed
                          }}
                        >
                          <div className="mt-3">
                            {isSurveyStart ? (
                              <Survey
                                goBack={() => {
                                  goBack();
                                }}
                              />
                            ) : (
                              // OR
                              <SurveyBox
                                enabled={section && section.has_pending_surveys}
                                onClick={onSurveyClick}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  );
                }

                if (section.items.length > 0) {
                  if (
                    campaign_content?.allowed_videos_ids?.length &&
                    !campaign_content?.allowed_videos_ids?.includes(
                      section?.items[0]?.id
                    )
                  ) {
                    sectionBody = <>One day at a time!</>;
                  } else {
                    sectionBody = (
                      <SectionHome
                        section={section}
                        stopVideo={stopVideo}
                        fromFavorites={false}
                      />
                    );
                  }
                }

                return (
                  <SectionItem
                    key={`section-${section.name}-${idx * 10}`}
                    section={section}
                    index={section?.id}
                    sectionScore={section?.progress || 0}
                    name={section.name || section.title}
                    children={sectionBody}
                    onAccordionClick={() => {
                      setStopVideo(!stopVideo);
                    }}
                    eventKey={idx}
                  />
                );
              })}
            </Accordion>
          )}
        </section>
      )}
    </>
  );
};

export default React.memo(Module);
