export const UPDATE_HAS_SURVEY = 'UPDATE_HAS_SURVEY';

export const FETCH_SECTION_REQUEST = 'FETCH_SECTION_REQUEST';
export const FETCH_SECTION_SUCCESS = 'FETCH_SECTION_SUCCESS';
export const FETCH_SECTION_FAILURE = 'FETCH_SECTION_FAILURE';

export const FETCH_SECTIONS_REQUEST = 'FETCH_SECTIONS_REQUEST';
export const FETCH_SECTIONS_SUCCESS = 'FETCH_SECTIONS_SUCCESS';
export const FETCH_SECTIONS_FAILURE = 'FETCH_SECTIONS_FAILURE';

export const FETCH_VIDEO_RATE_REQUEST = 'FETCH_VIDEO_RATE_REQUEST';
export const FETCH_VIDEO_RATE_SUCCESS = 'FETCH_VIDEO_RATE_SUCCESS';
export const FETCH_VIDEO_RATE_FAILURE = 'FETCH_VIDEO_RATE_FAILURE';

export const FETCH_VIDEO_PROGRESS_REQUEST = 'FETCH_VIDEO_PROGRESS_REQUEST';
export const FETCH_VIDEO_PROGRESS_SUCCESS = 'FETCH_VIDEO_PROGRESS_SUCCESS';
export const FETCH_VIDEO_PROGRESS_FAILURE = 'FETCH_VIDEO_PROGRESS_FAILURE';

export const FETCH_VIDEO_FAVORITE_REQUEST = 'FETCH_VIDEO_FAVORITE_REQUEST';
export const FETCH_VIDEO_FAVORITE_SUCCESS = 'FETCH_VIDEO_FAVORITE_SUCCESS';
export const FETCH_VIDEO_FAVORITE_FAILURE = 'FETCH_VIDEO_FAVORITE_FAILURE';

export const UPDATE_FAVORITE_VIDEO_AFTER_RATE =
  'UPDATE_FAVORITE_VIDEO_AFTER_RATE';
export const UPDATE_SECTIONS_HISTORY = 'UPDATE_SECTIONS_HISTORY';
export const UPDATE_SECTION_HISTORY = 'UPDATE_SECTION_HISTORY';

export const CREATE_RATING_REQUEST = 'CREATE_RATING_REQUEST';
export const CREATE_RATING_SUCCESS = 'CREATE_RATING_SUCCESS';
export const CREATE_RATING_FAILURE = 'CREATE_RATING_FAILURE';
