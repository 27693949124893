import {
  FETCH_VIDEO_FAILURE,
  FETCH_VIDEO_REQUEST,
  FETCH_VIDEO_SUCCESS,
  GET_SECTION_BY_VIDEO_FROM_MENU,
} from '../types/videoTypes';

const initialState = {
  error: '',
  loadingVideo: false,
  currentVideo: undefined,
  sectionByVideoFromMenu: 0,
  currentFavoriteVideoOff: 0,
};

export const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VIDEO_REQUEST:
      return {
        ...state,
        loadingVideo: true,
      };
    case FETCH_VIDEO_SUCCESS:
      return {
        ...state,
        loadingVideo: false,
        currentVideo: action.payload,
      };
    case FETCH_VIDEO_FAILURE:
      return {
        ...state,
        error: action.payload,
        loadingVideo: false,
        currentVideo: undefined,
      };
    case GET_SECTION_BY_VIDEO_FROM_MENU:
      return {
        ...state,
        sectionByVideoFromMenu: action.payload,
      };
    default:
      return state;
  }
};
