import {
  SEARCH_ELEMENT_FAILURE,
  SEARCH_ELEMENT_REQUEST,
  SEARCH_ELEMENT_SUCCESS,
} from '../types/searchTypes';

const initialState = {
  error: '',
  searchValue: '',
  searchSuccess: false,
  searchLoading: false,
  modules: [],
  sections: [],
  videos: [],
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_ELEMENT_REQUEST:
      return {
        ...state,
        error: '',
        searchLoading: true,
        searchSuccess: false,
        searchValue: action.payload,
      };
    case SEARCH_ELEMENT_SUCCESS:
      return {
        ...state,
        searchSuccess: true,
        searchLoading: false,
        courses: action.payload.courses,
        modules: action.payload.course_modules,
        sections: action.payload.module_sections,
        videos: action.payload.module_section_videos,
      };
    case SEARCH_ELEMENT_FAILURE:
      return {
        ...state,
        searchLoading: false,
        searchSuccess: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
