import React, { useEffect, useState } from 'react';
import { Form, Modal, Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_API_URL } from '../../constants/constants';
import { useAuthContext } from '../../context/authContext';
import { enable2fa, get2faQrCode } from '../../redux/actions/dashboardActions';
import { getUserToken } from '../../services/localStorage';

import appStyle from '../../styles/App.module.scss';
import CustomButton from '../CustomButton';

const User2FA = (props) => {
  const {
    state: { user },
  } = useAuthContext();
  const [inputs, setInputs] = useState({ otpPrompt: '' });
  const [isOTPAttemptValid, setIsOtpAttemptValid] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const reduxDispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboard);
  const [qrLoading, setQrLoading] = useState(false);
  const [backupCodes, setBackupCodes] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleToggleToast = (e) => {
    setShowToast(!showToast);
  };

  useEffect(() => {
    const { otpPrompt } = inputs;
    setShowToast(false);
    setIsOtpAttemptValid(otpPrompt.length >= 6);
  }, [inputs]);

  const handleClose = (e) => {
    props.handleClose();
  };

  const handle2FAEnabled = async (e) => {
    e.preventDefault();
    reduxDispatch(enable2fa(inputs['otpPrompt']));
    user.otp_required_for_login = true;
    localStorage.setItem('user', JSON.stringify(user));
    handleClose();
    setShowToast(true);
    inputs['otpPrompt'] = '';
    setInputs(inputs);
  };

  useEffect(() => {
    if (!qrLoading) {
      setQrLoading(true);
      reduxDispatch(get2faQrCode());
    }
  }, [qrLoading, reduxDispatch]);

  useEffect(() => {
    if (props.show) {
      const token = getUserToken();
      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
        },
      };
      fetch(`${BASE_API_URL}/users/show_2fa_backup_codes`, requestOptions)
        .then((r) => r.json())
        .then((data) => {
          setBackupCodes(data.codes.join('\n'));
        });
    }
  }, [props.show]);

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Two Factor Authentication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handle2FAEnabled}>
            <div className={appStyle['b-form-body']}>
              <span>
                Please scan this QR code with{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&oco=0"
                >
                  Google Authenticator
                </a>{' '}
                and enter the generated OTP code.
              </span>
              <div
                className="qrCode"
                dangerouslySetInnerHTML={{ __html: dashboard.qrCode2fa }}
              />
              <span className="mt-4">
                These are your backup codes, please store them somewhere safe
                before enabling 2FA:{' '}
                {backupCodes.length > 0 ? (
                  <a
                    download="bounceback_backup_codes.txt"
                    href={
                      'data:application/octet-stream;filename="backup_codes.txt";charset=utf-16le;base64,' +
                      Buffer.from(backupCodes).toString('base64')
                    }
                  >
                    Save.
                  </a>
                ) : (
                  'Loading...'
                )}
              </span>
              <pre className="mt-2 mb-4 text-center">{backupCodes}</pre>
              <Form.Group
                controlId="formOtpPrompt"
                className={appStyle['custom-form-group']}
              >
                <div className={appStyle['form-custom-label']}>OTP Code</div>
                <Form.Control
                  type="text"
                  name="otpPrompt"
                  required
                  value={inputs['otpPrompt']}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </div>
            <CustomButton
              label="Enable 2FA"
              disabled={!isOTPAttemptValid}
              className={
                'custom-button-default ' + (isOTPAttemptValid ? '' : 'disabled')
              }
              onClick={handle2FAEnabled}
            />
          </Form>
        </Modal.Body>
      </Modal>

      <Toast
        show={showToast}
        onClose={handleToggleToast}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <Toast.Header>
          <strong className="rounded mr-4">
            2FA {dashboard.enabled2fa ? 'Enabled' : 'Disabled'}
          </strong>
        </Toast.Header>
        <Toast.Body>
          2FA{' '}
          {dashboard.enabled2fa ? 'has been enabled' : 'could not be enabled'}{' '}
          for your account!
        </Toast.Body>
      </Toast>
    </>
  );
};

export default User2FA;
