import React from 'react';
import bounceBack from '../assets/bounceback.svg';
import { Image } from 'react-bootstrap';
function Logo({ onClick }) {
  return (
    <Image
      fluid
      src={bounceBack}
      alt="Bounceback"
      onClick={onClick}
      style={{ padding: 10 }}
    />
  );
}

export default Logo;
