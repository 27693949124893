import {
  OPEN_FAVORITE_SECTION,
  RELOAD_FAVORITE_VIDEOS_SUCCESS,
  REPLACE_FAVORITE_VIDEOS_SUCCESS,
  SELECTED_FAVORITE_VIDEO_SUCCESS,
  SHOW_FAVORITE_VIDEOS_FAILURE,
  SHOW_FAVORITE_VIDEOS_SUCCESS,
} from '../types/favoriteTypes';
import { requestGetApi } from '../../services/api/api';
import { BASE_API_URL } from '../../constants/constants';

const openFavoriteSection = (open) => ({
  type: OPEN_FAVORITE_SECTION,
  payload: open,
});

const showFavoriteVideosSuccess = (module_section_videos) => ({
  type: SHOW_FAVORITE_VIDEOS_SUCCESS,
  payload: module_section_videos,
});

const showFavoriteVideosFailure = (error) => ({
  type: SHOW_FAVORITE_VIDEOS_FAILURE,
  payload: error,
});

export const reloadFavoriteVideos = (video_id) => ({
  type: RELOAD_FAVORITE_VIDEOS_SUCCESS,
  payload: video_id,
});

export const replaceFavoriteVideos = (video) => ({
  type: REPLACE_FAVORITE_VIDEOS_SUCCESS,
  payload: video,
});

export const onSelectedFavoriteVideosSuccess = (video) => ({
  type: SELECTED_FAVORITE_VIDEO_SUCCESS,
  payload: video,
});

export const onOpenFavoriteSection = (open) => {
  return async (dispatch) => {
    dispatch(openFavoriteSection(open));
    if (!open) return;

    try {
      const response = await requestGetApi(
        `${BASE_API_URL}/module_section_videos/favorite`
      );
      if (response) {
        dispatch(showFavoriteVideosSuccess(response.module_section_videos));
      } else {
        dispatch(showFavoriteVideosFailure('error fetching favorite videos'));
      }
    } catch (ex) {
      dispatch(showFavoriteVideosFailure(ex.toString()));
    }
  };
};
