import appStyle from '../../styles/Modal.module.scss';
import { Col, Row } from 'react-bootstrap';
import React from 'react';

const AlertModalScore = ({ score, minScore, onClose, screenerTitle }) => {
  const successMessage = () => {
    return (
      <div>
        <p className={appStyle['title']}>
          Score{' '}
          <span className={appStyle['score-text-success']}>{`${score}%`}</span>
        </p>
        <p className={appStyle['title']}>Congratulations!</p>

        <button onClick={onClose} className="custom-button-default w-100">
          Keep going
        </button>
      </div>
    );
  };

  const screenerMessage = () => {
    return (
      <div>
        <p className="font-poppins font-14 text-color-gray">{screenerTitle}</p>

        <button onClick={onClose} className="custom-button-default w-100">
          OK
        </button>
      </div>
    );
  };

  const failureMessage = () => {
    return (
      <div>
        <Row className="mt-3">
          <Col xs={12}>
            <p className={appStyle['title']}>
              Try again, you need {minScore}% to pass
            </p>
            <p className={appStyle['title']}>
              Score{' '}
              <span
                className={appStyle['score-text-failure']}
              >{`${score}%`}</span>
            </p>
          </Col>
          <Col sm={12} md={3} className={'mt-3'}></Col>
          <Col sm={12} md={6} className={'mt-3'}>
            <button onClick={onClose} className="custom-button-default w-100">
              Retake
            </button>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      <div className={appStyle['card-modal']}>
        {screenerTitle && screenerTitle.props
          ? screenerMessage()
          : score >= minScore
          ? successMessage()
          : failureMessage()}
      </div>
    </>
  );
};

export default AlertModalScore;
