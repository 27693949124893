import React, { useEffect, useState } from 'react';
import '../../styles/KnowledgeCheck.scss';
import '../../App.scss';
import CertificationIcon from '../../assets/icons/certification.svg';
import { useSelector } from 'react-redux';

const SurveyBox = ({ onClick, enabled = true }) => {
  const [score, setScore] = useState();
  const [approved, setApproved] = useState();

  const { currentSection } = useSelector((state) => state.section);

  useEffect(() => {
    setScore(0);
    setApproved(false);
  }, [currentSection]);

  return (
    <div
      className={`knowledge-check-container ${approved ? 'warning' : ''}`}
      style={{ opacity: !enabled ? '.3' : '1' }}
    >
      <div className="left-container mb-3 mb-md-0">
        <div className="certification-container">
          <img
            src={CertificationIcon}
            alt="certification"
            width={32}
            height={32}
          />
        </div>
        <div className="d-flex flex-column align-items-start justify-content-start">
          <div className="pl-2 pl-sm-3 pl-md-3 pl-lg-3 title">Survey</div>
          {score > 0 && (
            <p className="pl-2 pl-sm-3 pl-md-3 pl-lg-3 title font-weight-normal m-0">
              Score: {score?.toFixed(0)}%
            </p>
          )}
        </div>
      </div>

      {enabled ? (
        <button
          className={`${
            approved ? 'custom-button-default w-auto' : 'custom-button-danger'
          }`}
          onClick={onClick}
          aria-label={approved ? 'Retake Survey' : 'Start Survey'}
        >
          {!approved && <span>Start</span>}
          {approved && <span>Retake</span>}
        </button>
      ) : (
        // OR
        <button className="custom-button-danger">Survey has been taken</button>
      )}
    </div>
  );
};

export default SurveyBox;
