import React from 'react';
import Search from '../Search';
import UserSettings from '../UserSettings';
import dashboardStyle from '../../styles/Dashboard.module.scss';
import { Col, Row } from 'react-bootstrap';

const Header = ({ user = '', showSearch = true }) => {
  return (
    <>
      <Row className={dashboardStyle['header-container']}>
        <Col
          xs={8}
          sm={8}
          lg={4}
          className={'d-flex d-sm-flex d-md-flex d-lg-none'}
        >
          <h3 className="m-0 font-20 font-poppins fw-300 text-color-accent">
            Welcome,{' '}
            <span className="text-break">
              {' '}
              {user.username || user?.first_name}
            </span>
          </h3>
        </Col>

        <Col
          xs={4}
          sm={4}
          className={`d-flex d-sm-flex d-md-flex d-lg-none justify-content-end`}
        >
          <div className={`d-flex justify-content-end`}>
            <UserSettings className={`ml-3`} />
          </div>
        </Col>

        <Col sm={12} xs={12} lg={5} className={`mt-4 mt-lg-0`}>
          {showSearch ? <Search /> : ''}
        </Col>

        <Col
          sm={4}
          lg={4}
          className={`d-none d-lg-flex align-items-center justify-content-end`}
        >
          <h3 className="m-0 font-20 font-poppins fw-300 text-color-accent">
            Welcome,{' '}
            <span className="text-break">
              {' '}
              {user.username || user?.first_name}
            </span>
          </h3>
          <UserSettings className={`ml-3`} />
        </Col>
      </Row>
    </>
  );
};

export default Header;
