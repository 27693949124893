import React, { useEffect, useRef, useState } from 'react';
import CustomVideo from '../CustomVideo';
import '../../styles/Sections.scss';
import KnowledgeCheckBox from '../../components/KnowledgeCheckBox';
import KnowledgeCheckList from '../KnowledgeCheckList';
import { useAuthContext } from '../../context/authContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchKnowledgeCheck,
  hideKnowledgeCheck,
} from '../../redux/actions/knowledgeCheckActions';
import { getUser } from '../../services/localStorage';
import Survey from '../Survey';
import { fetchSurvey } from '../../redux/actions/surveyActions';
import SurveyBox from '../SurveyBox';
import { onSelectCurrentVideo } from '../../redux/actions/videoActions';
import { updateModuleSectionVideoProgress } from '../../redux/actions/sectionActions';
import {
  BASE_API_URL,
  SCREENER_PARENT_SECTION,
  SCREENER_FAVORITE_BASELINE,
} from '../../constants/constants';
import { requestGetApi } from '../../services/api/api';
import Favorite from '../Favorite';
import ScreenerThumbnail from '../../assets/screener_thumbnail.jpg';
import ScreenerThumbnail2 from '../../assets/screener_thumbnail2.jpg';
import ScreenerThumbnail3 from '../../assets/screener_thumbnail3.jpg';
import ScreenerThumbnail4 from '../../assets/screener_thumbnail4.jpg';
import ScreenerThumbnail5 from '../../assets/screener_thumbnail5.jpg';
import ScreenerThumbnail6 from '../../assets/screener_thumbnail6.jpg';
import { Col, Jumbotron, Modal, Toast } from 'react-bootstrap';
import StarRating from 'react-star-rating-component';

import { createRating } from '../../redux/actions/sectionActions';

import { getSectionsByModule } from '../../redux/actions/sectionActions';

const percentages = [
  0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7,
  0.75, 0.8, 0.85, 0.9, 0.95, 0.97, 1,
];
const SectionHome = ({ section, stopVideo, fromFavorites }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();

  const { openFavoriteSection } = useSelector((state) => state.favorite);
  const { showKnowledgeChecks, knowledgeChecks } = useSelector(
    (state) => state.knowledgeCheck
  );
  const { currentVideo } = useSelector((state) => state.video);
  const { currentModule } = useSelector((state) => state.module);
  const { favoriteVideos } = useSelector((state) => state.favorite);
  const { currentSection, currentFavoriteVideoOff } = useSelector(
    (state) => state.section
  );
  const { selectedFavoriteVideo } = useSelector((state) => state.favorite);

  const {
    state: { user },
  } = useAuthContext();

  const [videos, setVideos] = useState([]);
  const [isStart, setIsStart] = useState(false);
  const [isSurveyStart, setIsSurveyStart] = useState(false);
  const [videoProgresses, setVideoProgresses] = useState({});
  const [currentPlayingVideo, setCurrentPlayingVideo] = useState(0);
  const [screeners, setScreeners] = useState([]);
  const [sectionRating, setSectionRating] = useState(0);
  const unmountRef = useRef([]);

  const [shouldRenderVideos, setShouldRenderVideos] = useState(false);

  const getScreenerThumbnail = (sItem) => {
    switch (sItem.screener_type) {
      case 'stress':
        return ScreenerThumbnail;
      case 'mindfulness':
        return ScreenerThumbnail2;
      case 'anxiety':
        return ScreenerThumbnail3;
      case 'activity':
        return ScreenerThumbnail4;
      case 'sel_check_in':
        return ScreenerThumbnail5;
      case 'sel_skills':
        return ScreenerThumbnail6;
    }
    return ScreenerThumbnail;
  };

  const [modal, setModal] = useState(false);

  const [initialAllVideosCompleted, setInitialAllVideosCompleted] = useState(false);

  useEffect(async () => {
    setCurrentPlayingVideo(0);
  }, [stopVideo]);

  useEffect(() => {
    if (section) {
      setVideos(section.items);
      requestGetApi(`${BASE_API_URL}/screeners`)
        .then(async (data) => {
          let screeners = [];
          for (let s of data.screeners) {
            if (s.module_section_id == section.id) {
              let sVideoId = SCREENER_FAVORITE_BASELINE;
              switch (s.screener_type) {
                case 'stress':
                  sVideoId += 0;
                  break;
                case 'mindfulness':
                  sVideoId += 1;
                  break;
                case 'anxiety':
                  sVideoId += 2;
                  break;
                case 'activity':
                  sVideoId += 3;
                  break;
                case 'sel_check_in':
                  sVideoId += 8;
                  break;
                case 'sel_skills':
                  sVideoId += 9;
                  break;
              }
              s.video = await requestGetApi(
                `${BASE_API_URL}/module_section_videos/${sVideoId}`
              );
              screeners.push(s);
            }
          }
          setScreeners(screeners);
        })
        .catch((e) => console.error(e));
    }
  }, [section]);

  function renderScreener(sItem) {
    let screenerSectionId = parseInt(SCREENER_PARENT_SECTION);
    switch (sItem.screener_type) {
      case 'stress':
        screenerSectionId += 0;
        break;
      case 'mindfulness':
        screenerSectionId += 1;
        break;
      case 'anxiety':
        screenerSectionId += 2;
        break;
      case 'activity':
        screenerSectionId += 3;
        break;
      case 'sel_check_in':
        screenerSectionId += 10;
        break;
      case 'sel_skills':
        screenerSectionId += 11;
        break;
    }

    console.log('Screener Section Selected:', screenerSectionId);
    setIsStart(!isStart);
    dispatch(fetchKnowledgeCheck(screenerSectionId));
    hideUnCollapsedItems(true);
  }

  // *********************
  // get favorite videos
  useEffect(() => {
    if (fromFavorites && favoriteVideos && favoriteVideos.length) {
      setVideos([...favoriteVideos]);
      const filteredSections = [];
      for (const fv of favoriteVideos) {
        filteredSections.push(fv.module_section_id);
      }
      requestGetApi(`${BASE_API_URL}/screeners`)
        .then(async (data) => {
          let screeners = new Map();
          for (let s of data.screeners) {
            if (filteredSections.includes(s.module_section_id)) {
              let sVideoId = SCREENER_FAVORITE_BASELINE;
              switch (s.screener_type) {
                case 'stress':
                  sVideoId += 0;
                  break;
                case 'mindfulness':
                  sVideoId += 1;
                  break;
                case 'anxiety':
                  sVideoId += 2;
                  break;
                case 'activity':
                  sVideoId += 3;
                  break;
                case 'sel_check_in':
                  sVideoId += 8;
                  break;
                case 'sel_skills':
                  sVideoId += 9;
                  break;
              }
              s.video = await requestGetApi(
                `${BASE_API_URL}/module_section_videos/${sVideoId}`
              );
              screeners.set(s.screener_type, s);
            }
          }
          setScreeners(Array.from(screeners, ([name, value]) => value));
        })
        .catch((e) => console.error(e));
    }
  }, [fromFavorites, favoriteVideos]);

  useEffect(() => {
    if (selectedFavoriteVideo) {
      // scroll to video when favorite section is open
      setTimeout(() => {
        scrollToVideo(selectedFavoriteVideo);
      }, 200);
    }
  }, [selectedFavoriteVideo]);

  // ********************************
  // get videos from currenSection
  useEffect(() => {
    let temp = {};

    if (
      !fromFavorites &&
      currentSection &&
      currentSection.items &&
      currentSection.items.length
    ) {
      currentSection.items.forEach((video) => {
        let progresses =
          (video.progresses.length && video.progresses[0].progress) || 0;
        let videoProgress = percentages
          .filter((percentage) => progresses / video.duration <= percentage)
          .map((percentage) => percentage * video.duration);

        if (!temp[video.id]) temp[video.id] = [];
        temp[video.id] = videoProgress;
      });

      setVideoProgresses({ ...temp });
    }

    // eslint-disable-next-line
  }, [currentSection]);

  useEffect(() => {
    hideUnCollapsedItems(false);

    // when component unmount, send the played value to the API
    return () => {
      if (unmountRef.current) {
        unmountRef.current.forEach((e) => sendVideoProgress(e));

        unmountRef.current = [];
      }
    };

    // eslint-disable-next-line
  }, [currentModule]);

  useEffect(() => {
    if (showKnowledgeChecks) {
      dispatch(hideKnowledgeCheck());
    }
    // eslint-disable-next-line
  }, [currentSection, currentModule, currentVideo]);

  const scrollToVideo = (video) => {
    if (!video) return;

    const element = document.getElementById(`video-${video.id}`);
    if (!element) return;

    setTimeout(() => {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }, 200);
  };

  const sendVideoProgress = (video) => {
    if (video.playedSeconds > video.progresses) {
      const videoDuration = +video.duration;
      const percentToDone = videoDuration > 1200 ? 0.97 : 0.95;
      const almostDone = video.playedSeconds >= videoDuration * percentToDone;
      const module_section_videos_progress_params = {
        progress: almostDone ? videoDuration : video.playedSeconds,
        module_section_video_id: video.video,
        status: 'in_progress',
        user_id: user.id,
      };

      dispatch(
        updateModuleSectionVideoProgress(module_section_videos_progress_params)
      );
    }
  };

  const hideUnCollapsedItems = (hide = false) => {
    if (!hide) {
      try {
        const currentModuleAccordion = document.getElementById(
          `kc-ac-${currentModule?.id}`
        );
        if (!currentModuleAccordion) return;
        const unselectedItems =
          currentModuleAccordion.querySelectorAll(`.mt-5.card`);
        unselectedItems.forEach((card) => {
          // card.style.removeProperty('border');
          card.classList.remove('d-none');
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const currentModuleAccordion = document.getElementById(
          `kc-ac-${currentModule?.id}`
        );
        const unselectedItems =
          currentModuleAccordion.querySelectorAll(`.mt-5.card`);
        if (!currentModuleAccordion) return;

        unselectedItems.forEach((card) => {
          if (!!!card.querySelector('.show')) {
            // card.style.setProperty('border', 'none', 'important');
            card.classList.add('d-none');
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onClick = () => {
    setIsStart(!isStart);
    dispatch(fetchKnowledgeCheck(currentSection?.id));

    hideUnCollapsedItems(true);
  };

  const onSurveyClick = () => {
    setIsSurveyStart(!isSurveyStart);
    dispatch(fetchSurvey(currentSection?.id));
  };

  let progressRequestWaiting = false;
  let lastProgress = null;
  let lastVideo = null;

  const onProgress = (progress, video) => {

    lastProgress = progress;
    lastVideo = video;

    if (!progressRequestWaiting) {
      progressRequestWaiting = true;
      setTimeout(function () {
        const localProgress = lastProgress;
        const localVideo = lastVideo;
        if (localProgress) {
          // this update progress and save in db
          let index = unmountRef.current.findIndex(
            (e) => e.video === localVideo.id
          );

          let objectVideo = {
            video: localVideo.id,
            duration: localVideo.duration,
            progresses:
              (localVideo.progresses.length &&
                localVideo.progresses[0].progress) ||
              0,
            playedSeconds: localProgress.playedSeconds,
          };

          if (index === -1) unmountRef.current.push(objectVideo);
          else
            unmountRef.current[index].playedSeconds =
              localProgress.playedSeconds;

          if (
            videoProgresses[localVideo.id] &&
            videoProgresses[localVideo.id].some(
              (percentage) => percentage < localProgress.playedSeconds
            )
          ) {
            let temp = videoProgresses[localVideo.id].filter(
              (percentage) => percentage < localProgress.playedSeconds
            );
            temp = videoProgresses[localVideo.id].filter(
              (percentage) => !temp.some((e) => e === percentage)
            );

            sendVideoProgress(objectVideo);

            videoProgresses[localVideo.id] = [...temp];
            setVideoProgresses({ ...videoProgresses });
          }
        }
        progressRequestWaiting = false;
      }, 1000);
    }
  };

  const sortItem = (a, b) => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  };

  const goBack = () => {
    setIsStart(false);
    dispatch(hideKnowledgeCheck());
    setCurrentPlayingVideo(0);

    hideUnCollapsedItems(false);
    setIsSurveyStart(false);
  };

  const showKnowledgeCheck = () => {
    return (
      currentSection.items.length &&
      currentSection.items.every(
        (x) => x.progresses.length && x.progresses[0].status === 'completed'
      )
    );
  };

  const onChangeRating = (videoRate, sectionId) => {
    setSectionRating(videoRate);
    console.log('Rating section', sectionId);
  };

  useEffect(() => {
    setShouldRenderVideos(
      fromFavorites
        ? true
        : currentSection && videos && videos.length
          ? videos[0].module_section_id === currentSection.id
          : false
    );
  }, [fromFavorites, currentSection, videos]);

  if (!currentUser.can_view_videos)
    return (
      <>
        <p className="m-0 text-danger">
          Please contact your organization’s leadership
        </p>
      </>
    );

  const areAllSectionVideosCompleted = () => {
    if (currentSection && currentSection.items && currentSection.items.length > 0) {
      return currentSection.items.every(video => {
        const progresses = video.progresses;
        return progresses.length > 0 && progresses[0].status === 'completed';
      });
    }
    return false;
  };

  const areAllModuleVideosCompleted2 = async () => {
    try {
      const response = await requestGetApi(
        `${BASE_API_URL}/module_sections?course_module_id=${currentModule.id}`
      );
      console.log('Module sections ', response.module_sections);
      // Check if the response has 'module_sections' property and it is an array
      if (response.module_sections && Array.isArray(response.module_sections)) {
        // Iterate through each module section
        for (const section of response.module_sections) {
          // Check if the section has items (videos)
          if (section.items && Array.isArray(section.items)) {
            // Iterate through each video in the section
            for (const video of section.items) {
              // Check if the video has progresses and if any progress has status other than "completed"
              if (video.progresses && Array.isArray(video.progresses) &&
                video.progresses.some(progress => progress.status !== 'completed')) {
                // If any video is not completed, return false
                return false;
              }
            }
          }
        }

        // If all videos are completed, return true
        return true;
      } else {
        console.error('Invalid response format');
        return false; // or handle the error accordingly
      }
    } catch (error) {
      console.error('Error fetching module sections:', error);
      return false; // or handle the error accordingly
    }
  };

  const areAllModuleVideosCompleted = async () => {
    try {
      const response = await requestGetApi(
        `${BASE_API_URL}/module_sections?course_module_id=${currentModule.id}`
      );
      //console.log(response.module_sections);

      // Check if the response has 'module_sections' property and it is an array
      if (response.module_sections && Array.isArray(response.module_sections)) {
        // Iterate through each module section
        for (const section of response.module_sections) {
          // Check if the section has items (videos)
          if (section.items && Array.isArray(section.items)) {
            // Iterate through each video in the section
            for (const video of section.items) {
              // Check if the video has progresses
              if (video.progresses && Array.isArray(video.progresses)) {
                // Check if any progress has status other than "completed"
                const isVideoCompleted = video.progresses.some(
                  (progress) => progress.status === 'completed'
                );

                // If any video is not completed, return false
                if (!isVideoCompleted) {
                  return false;
                }
              } else {
                // If the video has no progresses, consider it not completed
                return false;
              }
            }
          }
        }

        // If all videos are completed, return true
        return true;
      } else {
        console.error('Invalid response format');
        return false; // or handle the error accordingly
      }
    } catch (error) {
      console.error('Error fetching module sections:', error);
      return false; // or handle the error accordingly
    }
  };



  const closeModal = () => {
    setModal(!modal);
  };

  const submitRating = () => {

    // Dispatch createRating action with relevant parameters
    const moduleId = currentModule.id;
    const sectionId = currentSection.id;
    const videoId = currentVideo.id;
    dispatch(createRating(moduleId, sectionId, videoId, rating));

    setModal(!modal);
  };

  const [rating, setRating] = useState(0);

  const onStarClick = (nextValue, prevValue, name) => {
    setRating(nextValue);
  };

  return (
    <>
      {!isStart && !isSurveyStart && shouldRenderVideos ? (
        <div
          className="section-home-container"
          onClick={(event) => {
            // avoid onClick event in Accordion been executed
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          {videos.sort(sortItem).map((video, index) => {
            return (
              ![
                SCREENER_FAVORITE_BASELINE,
                SCREENER_FAVORITE_BASELINE + 1,
                SCREENER_FAVORITE_BASELINE + 2,
                SCREENER_FAVORITE_BASELINE + 3,
                SCREENER_FAVORITE_BASELINE + 8,
                SCREENER_FAVORITE_BASELINE + 9,
              ].includes(video.id) && (
                <section
                  id={`section-video-${video.id}`}
                  key={index}
                  aria-label={video.title + ' video section'}
                >
                  <div id={`video-${video.id}`} className={`mb-4`}>
                    <div
                      className={`video-favorite-container ${openFavoriteSection &&
                        currentFavoriteVideoOff === video.id
                        ? 'opacity-0'
                        : ''
                        }`}
                    >
                      <CustomVideo
                        video={video}
                        playing={
                          currentPlayingVideo === 0
                            ? false
                            : currentPlayingVideo === video.id
                        }
                        onPlay={(videoId) => {

                          const fetchAllVideosCompletion = async () => {
                            const completionStatus = await areAllModuleVideosCompleted();
                            setInitialAllVideosCompleted(completionStatus);
                            console.log('initialAllVideosCompleted', completionStatus);
                          };

                          fetchAllVideosCompletion();

                          setCurrentPlayingVideo(videoId);
                          dispatch(onSelectCurrentVideo({ ...video }));
                        }}
                        onProgress={(progress) =>
                          currentPlayingVideo === video.id
                            ? onProgress(progress, video)
                            : {}
                        }
                        onEnded={() => {
                          setTimeout(async () => {

                            const response = await requestGetApi(
                              `${BASE_API_URL}/module_sections?course_module_id=${currentModule.id}`
                            );

                            const allVideosCompleted = await areAllModuleVideosCompleted();
                            console.log('initialAllVideosCompleted', initialAllVideosCompleted);
                            console.log('areAllVideosCompleted', allVideosCompleted);
                            if (initialAllVideosCompleted == false && allVideosCompleted) {
                              setInitialAllVideosCompleted(true);
                              setRating(0);
                              // show the modal
                              setModal(true);
                            }
                          }, 2000);
                        }}
                      />
                    </div>
                  </div>
                </section>
              )
            );
          })}

          {screeners.length > 0 && (currentSection || fromFavorites) ? (
            <div
              id={
                'screenersContainer' +
                (currentSection ? currentSection.id : 'Favorites')
              }
            >
              {screeners.map(
                (sItem) =>
                  ((currentSection && !fromFavorites) ||
                    (fromFavorites && sItem.video?.user_favorite)) && (
                    <section id={'screener' + sItem.id}>
                      <div className="mt-3">
                        <h3 className="text-color-gray font-24 font-poppins fw-300">
                          <a
                            href="#"
                            onClick={(e) => {
                              renderScreener(sItem);
                            }}
                            id={'screenerHeader' + sItem.id}
                            className="text-color-gray font-24 font-poppins fw-300"
                            style={{ textDecoration: 'none' }}
                            aria-label={'Screener'}
                            aria-expanded={true}
                            role="button"
                          >
                            {sItem.screener_type == 'mindfulness'
                              ? 'Are You Mindful?'
                              : sItem.screener_type == 'anxiety'
                                ? 'Ever Feel Anxious?'
                                : sItem.screener_type == 'activity'
                                  ? 'Barriers to Being Active'
                                  : sItem.screener_type == 'sel_check_in'
                                    ? 'SEL Check-In'
                                    : sItem.screener_type == 'sel_skills'
                                      ? 'Rate Your SEL Skills'
                                      : 'Measure Your Stress'}
                          </a>
                        </h3>
                      </div>
                      <div className="mt-3">
                        <img
                          src={getScreenerThumbnail(sItem)}
                          onClick={(e) => {
                            renderScreener(sItem);
                          }}
                          width="100%"
                          height="auto"
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                      <div className="mt-3">
                        <div className="video-description-container">
                          <div className="d-flex flex-wrap justify-content-between align-items-start align-items-sm-center">
                            <div className="ml-2">
                              <div className="d-flex align-items-center justify-content-start">
                                <div className="mr-3 cursor-pointer">
                                  <Favorite
                                    video={sItem.video}
                                    user_favorite={sItem.video?.user_favorite}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div aria-hidden="true" className="like-section mr-2">
                          <RateControl
                            onChange={(videoRate) =>
                              onChangeRating(videoRate, section?.id)
                            }
                            rating={sectionRating}
                          />
                        </div> */}
                          </div>
                          <div className="video-description">
                            {sItem.screener_type !== 'activity' && sItem.screener_type !== 'sel_check_in' &&
                              sItem.screener_type !== 'sel_skills' && (
                                <div className="description">
                                  Check your {sItem.screener_type} level with a
                                  confidential, optional, and scientifically
                                  validated screener. (5 minutes)
                                </div>
                              )}
                            {sItem.screener_type === 'activity' && (
                              <div className="description">
                                Understand your barriers to physical activity
                                with a confidential and optional screener
                                adapted from a quiz developed by the U.S.
                                Centers for Disease Control and Prevention. (5
                                minutes)
                              </div>
                            )}
                            {sItem.screener_type === 'sel_check_in' && (
                              <div className="description">
                                Check in on your social and emotional awareness
                                with  a confidential and optional screener
                                adapted from a survey developed by the American
                                Institutes for Research. (5 minutes)
                              </div>
                            )}
                            {sItem.screener_type === 'sel_skills' && (
                              <div className="description">
                                Rate your social and emotional learning (SEL)
                                skills with a confidential and optional
                                screener adapted from a self-reflection tool
                                developed by the Collaborative for Academic,
                                Social, and Emotional Learning. (25 minutes)
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  )
              )}
            </div>
          ) : (
            ''
          )}

          {currentSection &&
            currentSection.has_knowledge_check &&
            !openFavoriteSection ? (
            <div className="mt-3">
              <KnowledgeCheckBox
                enabled={
                  !showKnowledgeChecks && currentSection && showKnowledgeCheck()
                }
                onClick={onClick}
              />
            </div>
          ) : (
            ''
          )}
          {currentSection &&
            currentSection.has_surveys &&
            !openFavoriteSection ? (
            <div className="mt-3">
              <SurveyBox
                enabled={currentSection && currentSection.has_pending_surveys}
                onClick={onSurveyClick}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        // OR
        ''
      )}

      {isStart ? (
        <KnowledgeCheckList
          module={currentModule}
          section={currentSection}
          content={knowledgeChecks}
          goBack={goBack}
        />
      ) : (
        // OR
        ''
      )}

      {isSurveyStart ? (
        <Survey
          goBack={(reload = false) => {
            if (reload) {
              document.location.reload(true);
            } else {
              goBack();
            }
          }}
        />
      ) : (
        // OR
        ''
      )}

      {(
        <Modal show={modal} fullscreen={'true'}>
          <Modal.Body>
            <Jumbotron className="text-center mb-1 mt-0" style={{ borderRadius: '5px', backgroundColor: '#e8f6fc', height: '320px', paddingTop: '30px' }}>
              <h1 className={`text-center`}>Congratulations!</h1>
              <p className={`text-center`}>
                You have completed the  <strong>{currentModule?.name}</strong> module. Your certificate will be available shortly under "My Certificates" in your account profile.
              </p>
              <p className={`text-center`}>
                Please take a moment to share your feedback on this module by rating it 1-5 stars.
              </p>
              <div className={`text-center`} style={{ fontSize: 30 }}>
                <StarRating
                  name="rating"
                  starCount={5}
                  value={rating}
                  onStarClick={onStarClick}
                  starColor="#0c7087"
                  emptyStarColor="#D3D3D3"
                />
              </div>
            </Jumbotron>
            <button
              className={`custom-button-default w-100 d-flex justify-content-center ${rating <= 0 ? "custom-submit-button-disabled" : ""
                }`}
              onClick={submitRating}
              disabled={rating <= 0}
            >
              <label className={`custom-submit-button-text ${rating <= 0 ? "custom-submit-button-text-disabled" : ""
                }`}>Submit Rating</label>
            </button>
            <p className={`text-right  mt-2 mb-0 mr-1`}>
              <a href="#" className="gray-link" onClick={closeModal}>
                Skip
              </a>
            </p>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default SectionHome;
