import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { useAuthContext } from '../../context/authContext';
import Module from '../../components/Module';
import styles from '../../styles/Dashboard.module.scss';
import Logo from '../../components/Logo';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentMenuFromStorage, saveCurrentVideoOnStorage } from '../../services/localStorage';
import { LEVELS } from '../../components/Menu';
import { setFirstLoadPage } from '../../redux/actions/dashboardActions';
import SearchResults from '../../components/SearchResults';
import FavoriteVideos from '../../components/FavoriteVideos';
import { getUserInfo, UserModel } from '../../context/authContext/authActions';
import AlertDismissible from '../../components/AlertDismissible';
import { getSectionById } from '../../redux/actions/sectionActions';
import { getModuleById } from '../../redux/actions/moduleActions';
import { getVideoByUrl, openVideoFromMenu } from '../../redux/actions/videoActions';
import CustomSpinner from '../../components/CustomSpinner';
import { useParams } from 'react-router-dom';

const CustomUrl = (props) => {
  const dispatch = useDispatch();

  const { searchValue } = useSelector((state) => state.search);
  const { openFavoriteSection } = useSelector((state) => state.favorite);
  const { currentModule, loadingCurrentModule } = useSelector(
    (state) => state.module
  );
  const { currentSection } = useSelector((state) => state.section);
  const { courses } = useSelector((state) => state.course);
  const { firstLoadPage, globalLoading } = useSelector(
    (state) => state.dashboard
  );

  const {
    state: { user },
  } = useAuthContext();

  const [showSearchPage, setShowSearchPage] = useState();
  const [showModule, setShowModule] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const { model, param1, param2 } = useParams();
  useEffect(() => {
    console.log(param1, model, param2);
  }, []);

  useEffect(() => {
    setShowSearchPage(searchValue !== '');
  }, [searchValue]);

  // init course, module, section
  useEffect(() => {
    if (courses.length > 0 && firstLoadPage) {
      let data = getCurrentMenuFromStorage();

      let module_id = data[LEVELS.CURRENT_MODULE];
      let section_id = data[LEVELS.CURRENT_SECTION];
      let video_id = data[LEVELS.CURRENT_VIDEO];

      if (video_id !== 0) {
        dispatch(openVideoFromMenu(video_id, section_id ? section_id : 0));
      } else if (section_id !== 0) {
        dispatch(getSectionById(section_id));
      } else {
        dispatch(getModuleById(module_id));
      }

      if (param1 == '1' && model == 'video') {
        dispatch(openVideoFromMenu(param2));
        saveCurrentVideoOnStorage(param2);
      }

      if (param1 == '2' && model == 'page') {
        dispatch(getVideoByUrl(param2));
      }

      // prevent to execute this useEffect again
      // only execute when first reload or press f5
      dispatch(setFirstLoadPage());
    }

    // eslint-disable-next-line
  }, [courses, firstLoadPage]);

  useEffect(() => {
    if (user) {
      getUserInfo(user.id).then(
        (response) => {
          let userModel = new UserModel(response.user);

          // Update user token in local storage
          localStorage.setItem('user', JSON.stringify(userModel));
        },
        () => {}
      );
    }
  }, [user]);

  useEffect(() => {
    if (props.location.search) {
      const searchQuery = props.location.search.replace('?', '');
      const queryParams = searchQuery.split('&');

      if (queryParams[0].indexOf('confirmed') !== -1) {
        let confirmed = queryParams[0].split('=');
        let value = confirmed[1];

        setShowAlert(value);
      }
    }
  }, [props.location.search]);

  const onCloseAlert = () => {
    window.history.replaceState(null, '', '/dashboard');
    setShowAlert((prevShowAlert) => !prevShowAlert);
  };

  useEffect(() => {
    setShowModule(!!currentModule && !showSearchPage);
  }, [currentModule, currentSection, showSearchPage]);

  const renderContent = () => {
    if (loadingCurrentModule) {
      return <CustomSpinner />;
    }

    if (showSearchPage) {
      return <></>;
    }

    if (openFavoriteSection) {
      return <FavoriteVideos />;
    }

    if (showModule) {
      return <Module />;
    }

    if (
      user &&
      user.main_school_graphic &&
      user.main_school_graphic.length > 0
    ) {
      return (
        <div id="org-graphic-container">
          <h1>
            <img
              tabIndex="0"
              alt={
                user.schools[0].notes
                  ? user.schools[0].notes
                  : 'Bounceback Disctrict Welcome Image'
              }
              src={user.main_school_graphic}
            />
          </h1>
        </div>
      );
    }

    return <></>;
  };

  return (
    <>
      <div className={styles['dashboard-container']}>
        <Row>
          <Col className={`pl-0 pl-md-3`} md={3} xs={12}>
            <div className="d-none d-md-block logo mt-3 mt-sm-0">
              <header>
                <Logo />
              </header>
            </div>
            <div className="d-block d-md-none logo mt-3 mt-sm-0">
              <header>
                <Logo />
              </header>
            </div>
          </Col>

          <Col md={9} xs={12}>
            <aside aria-label="Search videos">
              <Header user={user} />
            </aside>

            {showAlert ? (
              <AlertDismissible
                variant={'info'}
                content={`Your email address has been confirmed`}
                showAlert={showAlert}
                onCloseAlert={onCloseAlert}
              />
            ) : (
              // OR
              ''
            )}
          </Col>
        </Row>

        <Row>
          <Col className={`pl-0 pl-md-3`} md={3} xs={12}>
            <nav aria-label="Main menu">
              <Sidebar />
            </nav>
          </Col>

          <Col md={9} xs={12}>
            <main>
              {renderContent()}
              <SearchResults display={showSearchPage} />
            </main>
          </Col>
        </Row>
        {/*Spinner or Blocking Modal*/}
        {globalLoading && (
          <div className="loading">
            <div className="loader"></div>
          </div>
        )}
      </div>

      <div className={styles['dashboard-footer']}>
        <p className="font-13 font-poppins .text-color-tertiary m-0">
          All Rights Reserved © {new Date().getFullYear()} Bounceback LLC
          <span className="text-color-secondary font-weight-bolder mx-2">
            |
          </span>{' '}
          <a
            className={`font-16 font-poppins text-color-tertiary text-decoration-none`}
            target="_blank"
            href="https://www.mybounceback.com/privacy/"
          >
            Privacy Policy
          </a>
        </p>

        <p className="font-16 font-poppins text-color-tertiary m-0">
          Need Help?{' '}
          <span className="text-color-secondary font-weight-bolder mx-2">
            |
          </span>{' '}
          <a
            className={`font-16 font-poppins text-color-tertiary text-decoration-none`}
            href="mailto:support@mybounceback.com"
          >
            support@mybounceback.com
          </a>
        </p>
      </div>
    </>
  );
};

export default React.memo(CustomUrl);
