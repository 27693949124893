import React from 'react';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AlertBounceDismissible = ({
  showAlert,
  cls,
  variant = 'danger',
  content,
  onCloseAlert,
  icon,
}) => {
  if (showAlert) {
    return (
      <Alert
        className={cls}
        variant={variant}
        onClose={onCloseAlert}
        dismissible={true}
      >
        <p className={`mb-0`}>
          {icon && (
            <span className={`icon`}>
              <FontAwesomeIcon icon={icon} size="lg" />
            </span>
          )}
          {content}
        </p>
      </Alert>
    );
  }
  return <></>;
};

export default AlertBounceDismissible;
