import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useAuthContext } from '../context/authContext';

const PrivateRoute = (props) => {
  const { ...location } = useLocation();
  const {
    state: { user },
  } = useAuthContext();

  if (
    location.pathname === '/require-new-password' &&
    user &&
    !user.require_new_password
  )
    return (
      <Redirect to={{ pathname: '/dashboard', state: { from: location } }} />
    );

  if (
    location.pathname !== '/require-new-password' &&
    user &&
    user.require_new_password
  )
    return (
      <Redirect
        to={{ pathname: '/require-new-password', state: { from: location } }}
      />
    );

  if (user && !user.schools?.length) {
    if (user.provider === 'clever') {
      return (
        <Redirect to={{ pathname: '/signup/clever', state: { user: user } }} />
      );
    }
    if (user.provider === 'google_oauth2') {
      return (
        <Redirect to={{ pathname: '/signup/google', state: { user: user } }} />
      );
    }
  }


  let urlParams = new URLSearchParams(location.search);
  if (!user)
    return <Redirect to={{ pathname: '/signin', state: { from: location, token: urlParams.get('secret') } }} />;

  return (
    <>
      <Route {...props} />
    </>
  );
};

export default PrivateRoute;
