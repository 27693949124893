import {
  FETCH_MODULE_FAILURE,
  FETCH_MODULE_REQUEST,
  FETCH_MODULE_SUCCESS,
  UPDATE_MODULE_HISTORY,
} from '../types/moduleTypes';

const initialState = {
  currentModule: undefined,
  errorCurrentModule: '',
  loadingCurrentModule: false,
  moduleHistory: undefined,
};

export const moduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MODULE_HISTORY:
      return {
        ...state,
        moduleHistory: action.payload,
      };
    case FETCH_MODULE_REQUEST:
      return {
        ...state,
        errorCurrentModule: '',
        loadingCurrentModule: true,
      };
    case FETCH_MODULE_SUCCESS:
      return {
        ...state,
        loadingCurrentModule: false,
        currentModule: action.payload,
      };
    case FETCH_MODULE_FAILURE:
      return {
        ...state,
        loadingCurrentModule: false,
        errorCurrentModule: action.payload,
      };
    default:
      return state;
  }
};
