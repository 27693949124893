import {
  FETCH_CAMPAIGN_COURSE_SUCCESS,
  FETCH_CAMPAIGN_DATA_FAILURE,
  FETCH_CAMPAIGN_DATA_REQUEST,
  FETCH_CAMPAIGN_DATA_SUCCESS,
  FETCH_CAMPAIGN_ENROLLMENT_FAILURE,
  FETCH_CAMPAIGN_ENROLLMENT_REQUEST,
  FETCH_CAMPAIGN_ENROLLMENT_SUCCESS,
  FETCH_CAMPAIGN_FAILURE,
  FETCH_CAMPAIGN_REQUEST,
  FETCH_CAMPAIGN_SUCCESS,
} from '../types/campaignTypes';

const initialState = {
  campaign: undefined,
  campaign_content: undefined,
  day: undefined,
  courseModules: [],
  error: '',
};

export const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CAMPAIGN_REQUEST:
      return {
        ...state,
        error: '',
      };
    case FETCH_CAMPAIGN_DATA_REQUEST:
      return {
        ...state,
        error: '',
      };
    case FETCH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.payload,
      };
    case FETCH_CAMPAIGN_DATA_SUCCESS:
      return {
        ...state,
        campaign_content: action.payload,
      };
    case FETCH_CAMPAIGN_ENROLLMENT_REQUEST:
      return {
        ...state,
        error: '',
      };
    case FETCH_CAMPAIGN_ENROLLMENT_SUCCESS:
      return {
        ...state,
        campaign: action.payload,
        day: 1,
      };
    case FETCH_CAMPAIGN_COURSE_SUCCESS:
      return {
        ...state,
        courseModules: action.payload,
      };
    case FETCH_CAMPAIGN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_CAMPAIGN_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_CAMPAIGN_ENROLLMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
