// Enrollment status
export const FETCH_CAMPAIGN_ENROLLMENT_STATUS_REQUEST =
  'FETCH_CAMPAIGN_ENROLLMENT_STATUS_REQUEST';
export const FETCH_CAMPAIGN_ENROLLMENT_STATUS_SUCCESS =
  'FETCH_CAMPAIGN_ENROLLMENT_STATUS_SUCCESS';
export const FETCH_CAMPAIGN_ENROLLMENT_STATUS_FAILURE =
  'FETCH_CAMPAIGN_ENROLLMENT_STATUS_FAILURE';

// Enrollment request
export const FETCH_CAMPAIGN_ENROLLMENT_REQUEST =
  'FETCH_CAMPAIGN_ENROLLMENT_REQUEST';
export const FETCH_CAMPAIGN_ENROLLMENT_SUCCESS =
  'FETCH_CAMPAIGN_ENROLLMENT_SUCCESS';
export const FETCH_CAMPAIGN_ENROLLMENT_FAILURE =
  'FETCH_CAMPAIGN_ENROLLMENT_FAILURE';

export const FETCH_CAMPAIGN_REQUEST = 'FETCH_CAMPAIGN_REQUEST';
export const FETCH_CAMPAIGN_SUCCESS = 'FETCH_CAMPAIGN_SUCCESS';
export const FETCH_CAMPAIGN_FAILURE = 'FETCH_CAMPAIGN_FAILURE';

export const FETCH_CAMPAIGN_DATA_REQUEST = 'FETCH_CAMPAIGN_DATA_REQUEST';
export const FETCH_CAMPAIGN_DATA_SUCCESS = 'FETCH_CAMPAIGN_DATA_SUCCESS';
export const FETCH_CAMPAIGN_DATA_FAILURE = 'FETCH_CAMPAIGN_DATA_FAILURE';

export const FETCH_CAMPAIGN_COURSE_REQUEST = 'FETCH_CAMPAIGN_COURSE_REQUEST';
export const FETCH_CAMPAIGN_COURSE_SUCCESS = 'FETCH_CAMPAIGN_COURSE_SUCCESS';
export const FETCH_CAMPAIGN_COURSE_FAILURE = 'FETCH_CAMPAIGN_COURSE_FAILURE';
