import {
  FETCH_VIDEO_FAILURE,
  FETCH_VIDEO_REQUEST,
  FETCH_VIDEO_SUCCESS,
  GET_SECTION_BY_VIDEO_FROM_MENU,
} from '../types/videoTypes';
import { BASE_API_URL } from '../../constants/constants';
import { requestGetApi } from '../../services/api/api';
import { getSectionById } from './sectionActions';

const fetchVideoRequest = () => ({
  type: FETCH_VIDEO_REQUEST,
});

const fetchVideoSuccess = (video) => ({
  type: FETCH_VIDEO_SUCCESS,
  payload: video,
});

const fetchVideoFailure = (error) => ({
  type: FETCH_VIDEO_FAILURE,
  payload: error,
});

export const getSectionByVideoFromMenu = (section_id) => ({
  type: GET_SECTION_BY_VIDEO_FROM_MENU,
  payload: section_id,
});

export const openVideoFromMenu = (id) => {
  return async (dispatch) => {
    dispatch(getVideoById(id));
  };
};

export const getVideoById = (id = 0) => {
  return async (dispatch, getState) => {
    if (id === 0) {
      dispatch(onSelectCurrentVideo(undefined));
      return;
    }

    const { sections } = getState().section;
    const { currentVideo } = getState().video;

    // if currentVideo already exists
    // avoid to do the search again
    if (currentVideo && id === currentVideo.id) {
      dispatch(onSelectCurrentVideo({ ...currentVideo }));
      return;
    }

    // search in sections if current video exists
    if (sections && sections.length) {
      let findVideo = undefined;
      sections.forEach((section) => {
        section.items.forEach((video) => {
          if (video.id === id) {
            findVideo = video;
          }
        });
      });

      if (findVideo) {
        dispatch(onSelectCurrentVideo({ ...findVideo }));
        return;
      }
    }

    try {
      dispatch(fetchVideoRequest());
      const video = await requestGetApi(
        `${BASE_API_URL}/module_section_videos/${id}`
      );
      dispatch(onSelectCurrentVideo({ ...video }));
    } catch (error) {
      dispatch(fetchVideoFailure(error.toString()));
    }
  };
};

export const getVideoByUrl = (url = '') => {
  return async (dispatch, getState) => {
    if (url === '') {
      dispatch(onSelectCurrentVideo(undefined));
      return;
    }

    const { sections } = getState().section;
    const { currentVideo } = getState().video;

    // if currentVideo already exists
    // avoid to do the search again
    if (currentVideo && url === currentVideo.custom_url) {
      dispatch(onSelectCurrentVideo({ ...currentVideo }));
      return;
    }

    // search in sections if current video exists
    if (sections && sections.length) {
      let findVideo = undefined;
      sections.forEach((section) => {
        section.items.forEach((video) => {
          if (video.custom_url === url) {
            findVideo = video;
          }
        });
      });

      if (findVideo) {
        dispatch(onSelectCurrentVideo({ ...findVideo }));
        return;
      }
    }

    try {
      dispatch(fetchVideoRequest());
      const video = await requestGetApi(
        `${BASE_API_URL}/module_section_videos/by_url/${url}`
      );
      dispatch(onSelectCurrentVideo({ ...video }));
    } catch (error) {
      dispatch(fetchVideoFailure(error.toString()));
    }
  };
};

export const onSelectCurrentVideo = (video) => {
  return async (dispatch) => {
    dispatch(fetchVideoSuccess(video));
    if (!video) return;

    // is section exists
    // set section parent
    if (video.module_section) {
      dispatch(getSectionById(video.module_section.id));
    } else if (video.module_section_id) {
      dispatch(getSectionById(video.module_section_id));
    }
  };
};
