export const OPEN_FAVORITE_SECTION = 'OPEN_FAVORITE_SECTION';

export const SHOW_FAVORITE_VIDEOS_SUCCESS = 'SHOW_FAVORITE_VIDEOS_SUCCESS';
export const SHOW_FAVORITE_VIDEOS_FAILURE = 'SHOW_FAVORITE_VIDEOS_FAILURE';

export const RELOAD_FAVORITE_VIDEOS_SUCCESS = 'RELOAD_FAVORITE_VIDEOS_SUCCESS';
export const REPLACE_FAVORITE_VIDEOS_SUCCESS =
  'REPLACE_FAVORITE_VIDEOS_SUCCESS';

export const SELECTED_FAVORITE_VIDEO_SUCCESS =
  'SELECTED_FAVORITE_VIDEO_SUCCESS';
