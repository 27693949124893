import React from 'react';
import '../../styles/Campaign.scss';
import { useSelector } from 'react-redux';
import styles from '../../styles/Dashboard.module.scss';
import { Col, Row } from 'react-bootstrap';
import Logo from '../Logo';
import Sidebar from '../Sidebar';
import Header from '../Header';
import Module from '../Module';
import { useAuthContext } from '../../context/authContext';

const CampaignCourse = ({ courseModules = [] }) => {
  const {
    state: { user },
  } = useAuthContext();
  const { currentModule } = useSelector((state) => state.module);

  return (
    <>
      <div className={styles['dashboard-container']}>
        <Row>
          <Col className={`pl-sm-0`} md={3} xs={12}>
            <div className="d-none d-md-block logo mt-3 mt-sm-0">
              <Logo />
            </div>
            <Sidebar _courseModules={courseModules} />
            <div className="d-block d-md-none logo mt-3 mt-sm-0">
              <Logo />
            </div>
          </Col>

          <Col md={9} xs={12} className={styles['dashboard-main-content']}>
            <Header showSearch={false} user={user} />
            {currentModule && <Module />}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CampaignCourse;
