import React, { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import '../styles/Sidebar.scss';
import CustomSpinner from './CustomSpinner';
import Menu from './Menu';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCoursesMenu,
  fetchCoursesSuccess,
} from '../redux/actions/courseActions';
import useOutsideClick from '../hooks/useOutsideClick';

const Sidebar = ({
  onSelectMenu,
  _courseModules = null,
  _isHelpCenter = false,
}) => {
  const dispatch = useDispatch();
  const { courses, loadingCourses } = useSelector((state) => state.course);
  const { ref, isElementVisible, setIsElementVisible } = useOutsideClick(false);

  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    if (_isHelpCenter) return;

    if (!_courseModules) {
      dispatch(fetchCoursesMenu());
    }

    if (_courseModules) {
      dispatch(fetchCoursesSuccess(_courseModules || []));
    }

    // eslint-disable-next-line
  }, [_courseModules, _isHelpCenter]);

  return (
    <>
      {/*Desktop Nav*/}
      <Navbar
        expand="xl"
        className="col-md-12 d-none d-md-block sidebar p-0 mt-5 p-1"
      >
        {loadingCourses ? (
          <CustomSpinner />
        ) : (
          <Menu items={courses} onSelectMenu={onSelectMenu} />
        )}
      </Navbar>

      {/*Mobile Nav*/}
      <Navbar
        expand="lg"
        className="d-md-none col-md-12 d-block sidebar-mobile justify-content-start d-flex custom-scrollbar"
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setIsElementVisible(!isElementVisible);
            setIsCollapsed(!isCollapsed);
          }}
          type="button"
          aria-label="Toggle navigation"
          className="navbar-toggler"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          ref={ref}
          className={`bounce-navbar-nav ${
            !isElementVisible ? 'collapsed' : 'show'
          }`}
        >
          <section>
            <div className={`btn-toggle-wrapper`}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setIsElementVisible(!isElementVisible);
                  setIsCollapsed(!isCollapsed);
                }}
                type="button"
                aria-label="Toggle navigation"
                className="navbar-toggler open"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <Nav
              className={`${
                !isElementVisible ? 'collapsed' : ''
              } me-auto bounce-wrapper`}
            >
              {loadingCourses ? (
                <CustomSpinner />
              ) : (
                <Menu items={courses} onSelectMenu={onSelectMenu} />
              )}
            </Nav>
          </section>
        </div>

        <div className={`backdrop ${!isElementVisible ? '' : 'active'}`} />
      </Navbar>
    </>
  );
};

export default Sidebar;
