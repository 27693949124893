import React, { useEffect, useState } from 'react';
import '../../styles/Campaign.scss';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthContext } from '../../context/authContext';
import useCampaign from '../../hooks/useCampaign';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import AlertBounceDismissible from '../../components/AlertBounceDismissible';
import { setGlobalLoading } from '../../redux/actions/dashboardActions';

const CampaignPage = () => {
  const { ...location } = useLocation();
  const [showAlert, setShowAlert] = useState(false);

  const reduxDispatch = useDispatch();
  const { globalLoading } = useCampaign(); // Initialize campaign

  const { campaign, day, error } = useSelector((state) => state.campaign);

  const {
    state: { user },
  } = useAuthContext();

  const onCloseAlert = () => {
    setShowAlert((prevShowAlert) => !prevShowAlert);
  };

  useEffect(() => {
    if (error && globalLoading) {
      reduxDispatch(setGlobalLoading(false));
      setShowAlert(true);
    }

    // eslint-disable-next-line
  }, [error]);

  return (
    <>
      {campaign && user?.id && day ? (
        <Redirect
          to={{
            pathname: `${campaign.first_campaign_video_link}`,
            state: {
              from: location,
            },
          }}
        />
      ) : (
        // OR
        ''
      )}
      {/*Spinner or Blocking Modal*/}
      {globalLoading && (
        <div className="loading">
          <div className="loader"></div>
        </div>
      )}

      <AlertBounceDismissible
        cls={`alert-bounce alert-bounce-warning`}
        onCloseAlert={onCloseAlert}
        showAlert={showAlert}
        content={`Element is not available`}
        icon={faExclamationTriangle}
      />
    </>
  );
};

export default CampaignPage;
