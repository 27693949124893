import React, { useEffect, useState } from 'react';
import '../../styles/Campaign.scss';
import CampaignCourse from '../../components/CampaignCourse';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchModulesCampaign } from '../../redux/actions/campaignActions';
import { onCollapseMenu, onOpenMenu } from '../../redux/actions/menuActions';
import { LEVELS } from '../../components/Menu';
import useCampaign from '../../hooks/useCampaign';

const CampaignVideoPage = () => {
  const dispatch = useDispatch();
  const { ...location } = useLocation();
  const { campaign_id, video_id } = useParams();

  const { courseModules, campaign_content, error } = useSelector(
    (state) => state.campaign
  );

  const { globalLoading } = useCampaign({ campaign_id, video_id }); // Initialize campaign

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (courseModules?.length > 0) {
      setLoading(false);

      dispatch(onCollapseMenu(true));
      dispatch(
        onOpenMenu(LEVELS.VIDEOS, campaign_content.module_section_video.id)
      );
    }

    // eslint-disable-next-line
  }, [courseModules]);

  useEffect(() => {
    if (campaign_content) {
      dispatch(fetchModulesCampaign(campaign_content.campaign.id));
    }

    // eslint-disable-next-line
  }, [campaign_content]);

  useEffect(() => {
    if (error) {
      console.log(error);
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [error]);

  if (!loading && !campaign_content)
    return <Redirect to={{ pathname: '/signin', state: { from: location } }} />;

  return (
    <>
      {loading && (
        <div className="loading">
          <div className="loader"></div>
        </div>
      )}
      {courseModules?.length ? (
        <CampaignCourse courseModules={courseModules} />
      ) : (
        ''
      )}
    </>
  );
};

export default CampaignVideoPage;
