import React, { useEffect, useState } from 'react';
import { Form, Modal, Toast } from 'react-bootstrap';
import { useAuthContext } from '../context/authContext';
import { updateLoggedInUserPassword } from '../context/authContext/authActions';

import appStyle from '../styles/App.module.scss';
import CustomButton from './CustomButton';

const UserChangePassword = (props) => {
  const { dispatch } = useAuthContext();
  const [inputs, setInputs] = useState({ password: '', confirm_password: '' });
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleToggleToast = (e) => {
    setShowToast(!showToast);
  };

  useEffect(() => {
    const { password, confirm_password } = inputs;
    setIsPasswordValid(password.length >= 6 && password === confirm_password);
  }, [inputs]);

  const handleClose = (e) => {
    props.handleClose();
  };

  const handlePasswordSave = async (e) => {
    e.preventDefault();
    await updateLoggedInUserPassword(dispatch, {
      user_id: props.user.id,
      request_body: {
        user: {
          password: inputs['password'],
        },
      },
    });
    props.handleClose();
    setShowToast(true);
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='font-poppins'>Change password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handlePasswordSave}>
            <div className={appStyle['b-form-body']}>
              <Form.Group
                controlId="formBasicPassword"
                className={appStyle['custom-form-group']}
              >
                <Form.Label className={appStyle['form-custom-label']}>
                  New password
                </Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  required
                  value={inputs['password']}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group
                controlId="formBasicPasswordConfirmation"
                className={appStyle['custom-form-group']}
              >
                <Form.Label className={appStyle['form-custom-label']}>
                  Confirm new password
                </Form.Label>
                <Form.Control
                  type="password"
                  name="confirm_password"
                  required
                  value={inputs['confirm_password']}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </div>
            <CustomButton
              label="Submit"
              disabled={!isPasswordValid}
              className={
                'custom-button-default ' + (isPasswordValid ? '' : 'disabled')
              }
              onClick={handlePasswordSave}
            />
          </Form>
        </Modal.Body>
      </Modal>

      <Toast
        show={showToast}
        onClose={handleToggleToast}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <Toast.Header>
          <strong className="rounded mr-4">Password change</strong>
        </Toast.Header>
        <Toast.Body>Your password has been changed successfully!</Toast.Body>
      </Toast>
    </>
  );
};

export default UserChangePassword;
