import {
  FETCH_CAMPAIGN_COURSE_FAILURE,
  FETCH_CAMPAIGN_COURSE_REQUEST,
  FETCH_CAMPAIGN_COURSE_SUCCESS,
  FETCH_CAMPAIGN_DATA_FAILURE,
  FETCH_CAMPAIGN_DATA_REQUEST,
  FETCH_CAMPAIGN_DATA_SUCCESS,
  FETCH_CAMPAIGN_ENROLLMENT_FAILURE,
  FETCH_CAMPAIGN_ENROLLMENT_REQUEST,
  FETCH_CAMPAIGN_ENROLLMENT_SUCCESS,
} from '../types/campaignTypes';
import { BASE_API_URL } from '../../constants/constants';
import { setGlobalLoading } from './dashboardActions';
import { requestGetApi } from '../../services/api/api';

// Enrollment request

const fetchCampaignEnrollmentRequest = () => ({
  type: FETCH_CAMPAIGN_ENROLLMENT_REQUEST,
});

const fetchCampaignEnrollmentSuccess = (campaign) => ({
  type: FETCH_CAMPAIGN_ENROLLMENT_SUCCESS,
  payload: campaign,
});

const fetchCampaignEnrollmentFailure = (error) => ({
  type: FETCH_CAMPAIGN_ENROLLMENT_FAILURE,
  payload: error,
});

// Campaign DATA

const fetchCampaignDataRequest = () => ({
  type: FETCH_CAMPAIGN_DATA_REQUEST,
});

export const fetchCampaignDataSuccess = (campaign) => ({
  type: FETCH_CAMPAIGN_DATA_SUCCESS,
  payload: campaign,
});

const fetchCampaignDataFailure = (error) => ({
  type: FETCH_CAMPAIGN_DATA_FAILURE,
  payload: error,
});

// Campaign Course Modules data

const fetchCampaignCourseRequest = () => ({
  type: FETCH_CAMPAIGN_COURSE_REQUEST,
});

const fetchCampaignCourseSuccess = (course) => ({
  type: FETCH_CAMPAIGN_COURSE_SUCCESS,
  payload: course,
});

const fetchCampaignCourseFailure = (error) => ({
  type: FETCH_CAMPAIGN_COURSE_FAILURE,
  payload: error,
});

export const fetchCampaignEnrollment = (enrollmentID) => {
  return async (dispatch) => {
    const headers = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      dispatch(fetchCampaignEnrollmentRequest());
      dispatch(setGlobalLoading(true));

      const response = await fetch(
        `${BASE_API_URL}/campaigns/enroll?ceid=${enrollmentID}`,
        headers
      );

      if (!response.ok) throw new Error(response.status);

      let campaignResponse = await response.json();

      // If not enrolled yet
      if (campaignResponse.status === 'inactive') {
        // Let's enroll
        const headers = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({ ceid: enrollmentID }),
        };

        const enrollResponse = await fetch(
          `${BASE_API_URL}/campaigns/enroll`,
          headers
        );

        if (!enrollResponse.ok)
          dispatch(fetchCampaignEnrollmentFailure(enrollResponse.status));

        campaignResponse = await enrollResponse.json();
      }

      dispatch(setGlobalLoading(false));
      dispatch(fetchCampaignEnrollmentSuccess(campaignResponse));
    } catch (error) {
      dispatch(fetchCampaignEnrollmentFailure(error));
    }
  };
};

export const fetchCampaignByVideo = (videoID) => {
  return async (dispatch) => {
    const headers = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    try {
      dispatch(fetchCampaignDataRequest());
      dispatch(setGlobalLoading(true));

      const response = await fetch(
        `${BASE_API_URL}/campaign_user_videos/${videoID}`,
        headers
      );

      if (!response.ok) throw new Error(response.status);

      let campaignResponse = await response.json();

      dispatch(setGlobalLoading(false));
      dispatch(fetchCampaignDataSuccess(campaignResponse));
    } catch (error) {
      dispatch(setGlobalLoading(false));
      dispatch(fetchCampaignDataFailure(error));
    }
  };
};

export const fetchModulesCampaign = (campaign_id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCampaignCourseRequest());
      const response = await requestGetApi(
        `${BASE_API_URL}/courses/campaign/${campaign_id}/flat`
      );
      dispatch(fetchCampaignCourseSuccess([response]));
    } catch (error) {
      dispatch(fetchCampaignCourseFailure(error));
    }
  };
};
