import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import CustomButton from '../CustomButton';
import appStyle from '../../styles/App.module.scss';
import { Link, useHistory } from 'react-router-dom';
import { resetEmail } from '../../context/authContext/authActions';
import { useAuthContext } from '../../context/authContext';
import Logo from '../Logo';

import AlertDismissible from '../AlertDismissible';
import signInStyle from '../../styles/SignIn.module.scss';

const SignUpForm = () => {
  const {
    state: { error },
    dispatch
  } = useAuthContext();
  const history = useHistory();

  const [inputs, setInputs] = useState({ email_address: '' });
  const [showResults, setShowResults] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSignupWithEmail = async (e) => {
    e.preventDefault();
    const { email_address } = inputs;
    const payload = { user: { email: email_address } };
    const data = await resetEmail(dispatch, payload);
    if (!data?.errors) {
      setShowResults(true);
    } else {
      setShowAlert(true);
    }
  };
  const onCloseAlert = () => {
    setShowAlert((prevShowAlert) => !prevShowAlert);
  };
  const onCloseResults = () => {
    setShowResults((prevShowResults) => !prevShowResults);
  };
  const Errors = () => {
    return (
      <AlertDismissible
        content={'No account exists with this email address.'}
        showAlert={showAlert}
        onCloseAlert={onCloseAlert}
      />
    );
  };
  const Results = () => {
    return (
      <AlertDismissible
        content={'Reset password link has been sent.'}
        variant='success'
        showAlert={showResults}
        onCloseAlert={onCloseResults}
      />
    );
  };
  return (
    <>
      <div className={signInStyle['form-wrapper']}>
        <div className={appStyle['form-header-container']}>
          <Logo />
          <p className="mb-2 mt-4 font-18 text-color-primary font-poppins fw-500">
            Enter your email address,
          </p>
          <p className="mb-5 font-18 text-color-primary font-poppins fw-500">
            and we’ll send instructions to reset your password.
          </p>
        </div>

        {<Results />}
        {<Errors />}

        <Form onSubmit={handleSignupWithEmail}>
          <div className={signInStyle['b-form-body']}>
            <Form.Group className={signInStyle['custom-form-group']}>
              <Form.Control
                type="email"
                placeholder="Email"
                value={inputs['email_address']}
                onChange={onChangeHandler}
                required
                name="email_address"
                pattern={'[A-Za-z0-9\._\\%\\+\\-]+@[A-Za-z0-9\\.\\-]+.[A-Za-z]{2,}$'}
                title="Enter a valid email address"
              />
            </Form.Group>
          </div>

          <Row className={`justify-content-between mt-5`}>
            <Col lg="12" className="text-center position-relative">
              <button
                type="button"
                onClick={() => history.goBack()}
                className={`${signInStyle['clear-btn']} ${signInStyle['go-back-btn']} position-absolute fw-500 font-poppins text-color-primary`}
              >
                <i className="fa fa-arrow-left text-color-accent"> </i> Go back
              </button>

              <CustomButton
                label="Submit"
                className={`custom-button-default w-50 font-poppins ${
                  !inputs.email_address ? 'disabled' : ''
                }`}
                type="submit"
              />
            </Col>
          </Row>

          <div className="text-center pt-5">
            <p className="font-poppins font-18 fw-500 text-color-primary">
              <span>
                <Link
                  to="/signup"
                  className="font-poppins font-18 fw-500 text-color-accent"
                >
                  Don’t have an account? SIGN UP
                </Link>
              </span>
            </p>
          </div>
        </Form>
      </div>
    </>
  );
};

export default SignUpForm;
