import {
  LOGIN_WITH_EMAIL_ERROR,
  LOGIN_WITH_EMAIL_REQUEST,
  LOGIN_WITH_EMAIL_SUCCESS,
  SIGNUP_WITH_EMAIL_REQUEST,
  SIGNUP_WITH_EMAIL_SUCCESS,
  SIGNUP_WITH_EMAIL_ERROR,
  LOGIN_WITH_GOOGLE_ERROR,
  LOGIN_WITH_GOOGLE_REQUEST,
  LOGIN_WITH_GOOGLE_SUCCESS,
  RESET_PASSWORD_SUCCESS_EMAIL,
  RESET_PASSWORD_UPDATE_REQUEST,
  RESET_PASSWORD_UPDATE_SUCCESS_EMAIL,
  RESET_PASSWORD_UPDATE_ERROR_EMAIL,
  PASSWORD_UPDATE_REQUEST,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_ERROR,
  LOGOUT,
  REGISTRATION_CODE_UPDATE_REQUEST,
  REGISTRATION_CODE_UPDATE_SUCCESS,
  REGISTRATION_CODE_UPDATE_ERROR,
} from '../../constants/actionTypes';
import { BASE_API_URL } from '../../constants/constants';
import { getUserToken } from '../../services/localStorage';
import { requestGetApi } from '../../services/api/api';

export class UserModel {
  id = undefined;
  email = undefined;
  first_name = undefined;
  last_name = undefined;
  token = undefined;
  username = undefined;
  provider = undefined;
  image = undefined;
  can_view_videos = false;
  require_new_password = undefined;
  otp_required_for_login = undefined;
  schools = [];
  roles = [];
  main_school_graphic = undefined;
  has_licence = undefined;

  // roles = []

  constructor(props) {
    if (props) {
      this.id = props.id;
      this.email = props.email;
      this.first_name = props.first_name;
      this.last_name = props.last_name;
      this.token = props.token;
      this.can_view_videos = props.can_view_videos;
      this.username = props.username;
      this.image = props.image;
      this.provider = props.provider;
      this.has_licence = props.has_licence;
      // this.roles = props.roles;
      this.schools = props.schools || [];
      this.roles = props.roles || [];
      this.require_new_password = props.require_new_password;
      this.otp_required_for_login = props.otp_required_for_login;
      this.main_school_graphic = props.main_school_graphic;
      this.is_end_user = props.roles[0].name === 'USER';
    }
  }
}

let userModel = {
  id: '',
  email: '',
  first_name: '',
  last_name: '',
  token: '',
  username: '',
  image: '',
  provider: '',
  require_new_password: false,
  otp_required_for_login: false,
};

export const check2faEnabled = async (email) => {
  const headers = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({
      user: {
        email: email,
      },
    }),
  };
  const response = await fetch(`${BASE_API_URL}/users/check_2fa`, headers);
  const data = await response.json();
  if (typeof data.otp_required_for_login !== 'undefined') {
    return data.otp_required_for_login;
  }
  return false;
};

export const loginWithEmail = async (dispatch, payload) => {
  const headers = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(payload),
  };

  try {
    dispatch({ type: LOGIN_WITH_EMAIL_REQUEST });
    const response = await fetch(`${BASE_API_URL}/users/login`, headers);
    const data = await response.json();
    if (data && data.user) {
      userModel = new UserModel(data.user);

      // Log User Agent for analytics
      logUserAgent(userModel.token);

      dispatch({ type: LOGIN_WITH_EMAIL_SUCCESS, payload: userModel });

      return data;
    }
    dispatch({ type: LOGIN_WITH_EMAIL_ERROR, error: data?.errors });
  } catch (error) {
    console.log('Something went wrong - loginWithEmail error:', error);
  }
};
export const signupWithEmail = async (dispatch, payload) => {
  const headers = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(payload),
  };

  try {
    dispatch({ type: SIGNUP_WITH_EMAIL_REQUEST });
    const response = await fetch(`${BASE_API_URL}/users/signup`, headers);
    const data = await response.json();

    if (data?.user?.id) {
      userModel = new UserModel(data.user);
      dispatch({ type: SIGNUP_WITH_EMAIL_SUCCESS, payload: data.user });
      return data.user;
    } else {
      dispatch({ type: SIGNUP_WITH_EMAIL_ERROR, error: data?.errors });
    }
  } catch (error) {
    console.log('Something went wrong - signupWithEmail error:', error);
    dispatch({
      type: SIGNUP_WITH_EMAIL_ERROR,
      error: 'Something went wrong, try again, please!',
    });
  }
};

export const loginWithGoogle = async (dispatch, data) => {
  try {
    dispatch({ type: LOGIN_WITH_GOOGLE_REQUEST });
    if (data) {
      userModel = new UserModel(data);

      // Log User Agent for analytics
      logUserAgent(userModel.token);

      dispatch({ type: LOGIN_WITH_GOOGLE_SUCCESS, payload: userModel });

      return userModel;
    }
  } catch (error) {
    dispatch({ type: LOGIN_WITH_GOOGLE_ERROR, error });
  }
};

export const logout = (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const requestAuthenticityToken = async () => {
  const headers = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const response = await fetch(`${BASE_API_URL}/users/login`, headers);
    const data = await response.json();
    if (!data?.authenticity_token) return;
    return data;
  } catch (error) {
    console.log('Errors on getAuthenticityToken', error);
  }
};

export const resetEmail = async (dispatch, payload) => {
  const headers = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(payload),
  };

  try {
    const response = await fetch(`${BASE_API_URL}/users/password`, headers);
    const data = await response.json();
    if (data) {
      dispatch({ type: RESET_PASSWORD_SUCCESS_EMAIL });
      return data;
    }
    dispatch({ type: LOGIN_WITH_EMAIL_ERROR, error: data?.errors });
  } catch (error) {
    console.log('Something went wrong - resetEmail error:', error);
  }
};

export const updatePassword = async (dispatch, payload) => {
  const headers = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(payload),
  };
  dispatch({ type: RESET_PASSWORD_UPDATE_REQUEST });
  try {
    const response = await fetch(`${BASE_API_URL}/users/password`, headers);
    const data = await response.status;
    if (data >= 200 && data < 400) {
      dispatch({ type: RESET_PASSWORD_UPDATE_SUCCESS_EMAIL });
    }
    dispatch({ type: RESET_PASSWORD_UPDATE_ERROR_EMAIL, error: data?.errors });
  } catch (error) {
    console.log('Something went wrong - updatePassword error:', error);
  }
};

export const updateLoggedInUserPassword = async (dispatch, payload) => {
  const token = getUserToken();
  const headers = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.request_body),
  };
  dispatch({ type: PASSWORD_UPDATE_REQUEST });
  try {
    const response = await fetch(
      `${BASE_API_URL}/users/${payload.user_id}/update_password`,
      headers
    );
    const status = await response.status;
    const data = await response.json();
    if (status >= 200 && status < 400) {
      dispatch({ type: PASSWORD_UPDATE_SUCCESS, payload: data.user });
    }
    dispatch({ type: PASSWORD_UPDATE_ERROR, error: status?.errors });
  } catch (error) {
    console.log('Something went wrong - changePassword error:', error);
  }
};

export const logUserAgent = async (token) => {
  const platform =
    navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
  const isIosDevice =
    /iPad|iPhone|iPod/.test(platform) ||
    (platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  let inferredIosDevice = 'iOS device in desktop mode';
  if (/iPad|iPhone|iPod/.test(platform)) {
    inferredIosDevice = platform;
  } else if (platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
    const width = window.screen.width;
    const height = window.screen.height;
    const screenHyphotenuse = width * width + height * height;
    console.log('Checking iOS device screen hyphotenuse', screenHyphotenuse);
    if (screenHyphotenuse < 1024) {
      inferredIosDevice = 'iPhone';
    } else {
      inferredIosDevice = 'iPad';
    }
  }
  await fetch(`${BASE_API_URL}/users/log_ua`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      user: {
        is_ios_device: isIosDevice,
        inferred_ios_device: inferredIosDevice,
      },
    }),
  });
};

export const getUserInfo = async (id) => {
  return requestGetApi(`${BASE_API_URL}/users/${id}`);
};

export const getUserInfoByToken = async (token) => {
  return requestGetApi(`${BASE_API_URL}/users/show_by_token`, token);
};

export const updateUserRegistrationCode = async (dispatch, payload, token) => {
  const headers = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };

  dispatch({ type: REGISTRATION_CODE_UPDATE_REQUEST });

  try {
    const response = await fetch(
      `${BASE_API_URL}/users/registration_code`,
      headers
    );
    const data = await response.json();
    if (!data.errors) {
      dispatch({ type: REGISTRATION_CODE_UPDATE_SUCCESS });
      return data;
    }
    dispatch({ type: REGISTRATION_CODE_UPDATE_ERROR, error: data?.errors });
  } catch (error) {
    console.log(
      'Something went wrong - update customer by registration code error:',
      error
    );
  }
};
