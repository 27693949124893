import {
  FETCH_SECTION_FAILURE,
  FETCH_SECTION_REQUEST,
  FETCH_SECTION_SUCCESS,
  FETCH_SECTIONS_FAILURE,
  FETCH_SECTIONS_REQUEST,
  FETCH_SECTIONS_SUCCESS,
  FETCH_VIDEO_FAVORITE_FAILURE,
  FETCH_VIDEO_FAVORITE_REQUEST,
  FETCH_VIDEO_FAVORITE_SUCCESS,
  FETCH_VIDEO_PROGRESS_FAILURE,
  FETCH_VIDEO_PROGRESS_REQUEST,
  FETCH_VIDEO_PROGRESS_SUCCESS,
  FETCH_VIDEO_RATE_FAILURE,
  FETCH_VIDEO_RATE_REQUEST,
  FETCH_VIDEO_RATE_SUCCESS,
  UPDATE_FAVORITE_VIDEO_AFTER_RATE,
  UPDATE_SECTION_HISTORY,
  UPDATE_SECTIONS_HISTORY,
  UPDATE_HAS_SURVEY,
} from '../types/sectionTypes';

const initialState = {
  error: '',
  errorRate: '',
  errorProgress: '',
  errorFavorite: '',
  sections: [],
  loadingSections: false,
  currentSection: null,
  loadingCurrentSection: false,
  currentFavoriteVideoOff: 0,
  updateFavoriteVideoAfterRate: undefined,
  sectionsHistory: undefined,
  sectionHistory: undefined,
};

export const sectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SECTIONS_HISTORY:
      return {
        ...state,
        sectionsHistory: action.payload,
      };
    case UPDATE_SECTION_HISTORY:
      return {
        ...state,
        sectionHistory: action.payload,
      };
    case FETCH_SECTION_REQUEST:
      return {
        ...state,
        error: '',
        loadingCurrentSection: true,
      };
    case FETCH_SECTION_SUCCESS:
      return {
        ...state,
        loadingCurrentSection: false,
        currentSection: action.payload,
      };
    case FETCH_SECTION_FAILURE:
      return {
        ...state,
        loadingCurrentSection: false,
        error: action.payload,
      };
    case FETCH_SECTIONS_REQUEST:
      return {
        ...state,
        error: '',
        sections: [],
        loadingSections: true,
      };
    case FETCH_SECTIONS_SUCCESS:
      return {
        ...state,
        loadingSections: false,
        sections: [...action.payload],
      };
    case FETCH_SECTIONS_FAILURE:
      return {
        ...state,
        loadingSections: false,
        error: action.payload,
      };
    case FETCH_VIDEO_PROGRESS_REQUEST:
      return {
        ...state,
        errorProgress: '',
      };
    case FETCH_VIDEO_PROGRESS_SUCCESS:
      const _progress_sections = [...state.sections];
      const _progress_sections_history = { ...state.sectionsHistory };
      const _progress_module_id = _progress_sections[0].course_module_id;

      _progress_sections.forEach((section) => {
        section.items.forEach((video) => {
          if (video.id === action.payload.module_section_video_id) {
            video.progresses = [
              {
                ...((video.progresses.length && video.progresses[0]) || []),
                ...action.payload,
              },
            ];
          }
        });
      });

      // update state
      _progress_sections_history[_progress_module_id] = [..._progress_sections];

      return {
        ...state,
        currentSection: {
          ..._progress_sections.find(
            (section) => section.id === state.currentSection.id
          ),
        },
        sections: [..._progress_sections],
        sectionsHistory: { ..._progress_sections_history },
      };
    case FETCH_VIDEO_PROGRESS_FAILURE:
      return {
        ...state,
        errorProgress: action.payload,
      };
    case FETCH_VIDEO_RATE_REQUEST:
      return {
        ...state,
        errorRate: '',
      };
    case FETCH_VIDEO_RATE_SUCCESS:
      const _rating_videos = [...state.sections];
      const _rating_sections_history = { ...state.sectionsHistory };
      const _rating_module_id = action.payload.module_section.course_module_id;

      _rating_videos.forEach((section) => {
        section.items.forEach((video) => {
          if (video.id === action.payload.id) {
            video.user_rating = [
              {
                ...(video.user_rating.length && video.user_rating[0]),
                rating: action.payload.user_rating[0]?.rating || 0,
              },
            ];
          }
        });
      });

      if (
        _rating_sections_history &&
        _rating_sections_history[_rating_module_id]
      ) {
        _rating_sections_history[_rating_module_id].forEach((section) => {
          section.items.forEach((video) => {
            if (video.id === action.payload.id) {
              video.user_rating = [
                {
                  ...(video.user_rating.length && video.user_rating[0]),
                  rating: action.payload.user_rating[0]?.rating || 0,
                },
              ];
            }
          });
        });
      }

      return {
        ...state,
        updateFavoriteVideoAfterRate: action.payload,
        currentSection: {
          ..._rating_videos.find(
            (section) => section.id === state.currentSection.id
          ),
        },
        sections: [..._rating_videos],
        sectionsHistory: { ..._rating_sections_history },
      };
    case FETCH_VIDEO_RATE_FAILURE:
      return {
        ...state,
        errorRate: action.payload,
      };
    case FETCH_VIDEO_FAVORITE_REQUEST:
      return {
        ...state,
        errorFavorite: '',
        currentFavoriteVideoOff: action.payload,
      };
    case FETCH_VIDEO_FAVORITE_SUCCESS:
      const _favorite_sections = [...state.sections];
      const _favorite_sections_history = { ...state.sectionsHistory };
      const _favorite_course_module_id = action.payload.module_section
        ? action.payload.module_section.course_module_id
        : action.payload.course_module_id;

      // update state
      const _new_favorite_sections = _favorite_sections.map((section) => {
        return {
          ...section,
          items: section.items.map((video) => {
            if (video.id === action.payload.id) {
              return {
                ...video,
                user_favorite: !video.user_favorite,
              };
            }

            return video;
          }),
        };
      });

      // update history state
      if (
        _favorite_sections_history &&
        _favorite_sections_history[_favorite_course_module_id]
      ) {
        _favorite_sections_history[_favorite_course_module_id].forEach(
          (section) => {
            section.items.forEach((video) => {
              if (video.id === action.payload.id) {
                video.user_favorite = !video.user_favorite;
              }
            });
          }
        );
      }

      return {
        ...state,
        currentFavoriteVideoOff: 0,
        currentSection: {
          ..._favorite_sections.find(
            (section) => section.id === state.currentSection.id
          ),
        },
        sections: [..._new_favorite_sections],
        sectionsHistory: { ..._favorite_sections_history },
      };
    case UPDATE_HAS_SURVEY:
      const _sections = [...state.sections];
      const _currentSection = { ...state.currentSection };

      const hasSurveys = action.payload;
      _currentSection.has_pending_surveys = hasSurveys;
      _sections.find(
        (section) => section.id === _currentSection.id
      ).has_pending_surveys = hasSurveys;

      return {
        ...state,
        currentSection: _currentSection,
        sections: _sections,
      };
    case FETCH_VIDEO_FAVORITE_FAILURE:
      return {
        ...state,
        currentFavoriteVideoOff: 0,
        errorFavorite: action.payload,
      };
    case UPDATE_FAVORITE_VIDEO_AFTER_RATE:
      return {
        ...state,
        updateFavoriteVideoAfterRate: action.payload,
      };
    default:
      return state;
  }
};
