import {
  FETCH_SURVEY_FAILURE,
  FETCH_SURVEY_REQUEST,
  FETCH_SURVEY_SUCCESS,
  POST_SURVEY_FAILURE,
  POST_SURVEY_REQUEST,
  POST_SURVEY_SUCCESS,
  RESET_SURVEY_DATA,
} from '../types/surveyTypes';
import { getUserToken } from '../../services/localStorage';
import { BASE_API_URL } from '../../constants/constants';

const fetchSurveyRequest = () => ({
  type: FETCH_SURVEY_REQUEST,
});

export const resetSurveyData = () => ({
  type: RESET_SURVEY_DATA,
});

const fetchSurveySuccess = (Surveys) => ({
  type: FETCH_SURVEY_SUCCESS,
  payload: Surveys,
});

const fetchSurveyFailure = (error) => ({
  type: FETCH_SURVEY_FAILURE,
  payload: error,
});

export const postSurveyRequest = () => ({
  type: POST_SURVEY_REQUEST,
});

export const postSurveySuccess = (SurveyAnswers, answersList) => ({
  type: POST_SURVEY_SUCCESS,
  payload: { SurveyAnswers, answersList },
});

export const postSurveyFailure = (error) => ({
  type: POST_SURVEY_FAILURE,
  payload: error,
});

export const fetchSurvey = (module_section_id) => {
  return async (dispatch) => {
    try {
      const token = getUserToken();
      const headers = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };

      dispatch(fetchSurveyRequest());
      const response = await fetch(
        `${BASE_API_URL}/surveys?module_section_id=${module_section_id}`,
        headers
      );

      if (!response.ok) dispatch(fetchSurveyFailure(response.status));

      const SurveyResponse = await response.json();
      const Survey = SurveyResponse.surveys;
      dispatch(fetchSurveySuccess(Survey || []));
    } catch (error) {
      dispatch(fetchSurveyFailure(error));
    }
  };
};

export const submitSurvey = (surveyAnswer) => {
  return async (dispatch) => {
    const token = getUserToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(surveyAnswer),
    };
    try {
      dispatch(postSurveyRequest());
      const response = await fetch(
        `${BASE_API_URL}/survey_question_answers`,
        requestOptions
      );

      if (!response.ok) throw new Error(response.status);
      const survey_question_answers = await response.json();
      dispatch(postSurveySuccess(survey_question_answers || []));
    } catch (error) {
      dispatch(postSurveyFailure(error));
    }
  };
};

export const submitAttempt = (survey, answers) => {
  const token = getUserToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  const requestOptions = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      survey_attempts: {
        survey_id: survey.id,
        survey_question_answers_attributes: answers,
      },
    }),
  };

  return fetch(`${BASE_API_URL}/survey_attempts`, requestOptions);
};
