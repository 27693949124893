import {
  FETCH_COURSES_FAILURE,
  FETCH_COURSES_HELP_CENTER,
  FETCH_COURSES_REQUEST,
  FETCH_COURSES_SUCCESS,
} from '../types/courseTypes';

const initialState = {
  loadingCourses: false,
  isHelpCenter: false,
  errorCourses: '',
  courses: [],
};

export const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COURSES_REQUEST:
      return {
        ...state,
        isHelpCenter: false,
        loadingCourses: true,
        errorCourses: '',
      };
    case FETCH_COURSES_SUCCESS:
      return {
        ...state,
        loadingCourses: false,
        courses: action.payload,
      };
    case FETCH_COURSES_FAILURE:
      return {
        ...state,
        loadingCourses: false,
        errorCourses: action.payload,
      };
    case FETCH_COURSES_HELP_CENTER:
      return {
        ...state,
        isHelpCenter: true,
      };
    default:
      return state;
  }
};
