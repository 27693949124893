import React from 'react';
const useEventListener = (handler) => {
  React.useEffect(() => {
    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, [handler]);
};

export default useEventListener;
