import {
  OPEN_FAVORITE_SECTION,
  RELOAD_FAVORITE_VIDEOS_SUCCESS,
  REPLACE_FAVORITE_VIDEOS_SUCCESS,
  SELECTED_FAVORITE_VIDEO_SUCCESS,
  SHOW_FAVORITE_VIDEOS_FAILURE,
  SHOW_FAVORITE_VIDEOS_SUCCESS,
} from '../types/favoriteTypes';

const initialState = {
  selectedFavoriteVideo: undefined,
  openFavoriteSection: false,
  favoriteVideos: [],
  errorMessage: '',
};

export const favoriteReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_FAVORITE_SECTION:
      return {
        ...state,
        openFavoriteSection: action.payload,
      };
    case SHOW_FAVORITE_VIDEOS_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        favoriteVideos: [...action.payload],
      };
    case SHOW_FAVORITE_VIDEOS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case RELOAD_FAVORITE_VIDEOS_SUCCESS:
      return {
        ...state,
        favoriteVideos: [
          ...state.favoriteVideos
            .filter((e) => e.id !== action.payload)
            .map((e) => {
              return { ...e, user_favorite: true };
            }),
        ],
      };
    case REPLACE_FAVORITE_VIDEOS_SUCCESS:
      return {
        ...state,
        favoriteVideos: [
          ...state.favoriteVideos.map((video) => {
            if (video.id === action.payload.id) {
              return action.payload;
            } else {
              return video;
            }
          }),
        ],
      };
    case SELECTED_FAVORITE_VIDEO_SUCCESS:
      return {
        ...state,
        selectedFavoriteVideo: action.payload,
      };
    default:
      return state;
  }
};
