import {
  FETCH_CERTIFICATES_FAILURE,
  FETCH_CERTIFICATES_REQUEST,
  FETCH_CERTIFICATES_SUCCESS,
} from '../types/certificateTypes';

const initialState = {
  certificates: [],
  loading: false,
  error: '',
};

export const certificateReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CERTIFICATES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CERTIFICATES_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        certificates: action.payload,
      };
    case FETCH_CERTIFICATES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        certificates: [],
      };
    default:
      return state;
  }
};
