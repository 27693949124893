import { BASE_API_URL } from '../../constants/constants';
import { getUserToken } from '../../services/localStorage';
import {
  FIRST_LOAD_PAGE,
  SET_GLOBAL_LOADING,
  SET_2FA_QR_CODE,
  SET_2FA_ENABLED,
} from '../types/dashboardTypes';

export const setFirstLoadPage = () => ({
  type: FIRST_LOAD_PAGE,
});

export const setGlobalLoading = (loading) => ({
  type: SET_GLOBAL_LOADING,
  payload: loading,
});

export const set2faQrCode = (qrCode2faPayload) => ({
  type: SET_2FA_QR_CODE,
  payload: qrCode2faPayload,
});

export const set2faEnabled = (enabled2faPayload) => ({
  type: SET_2FA_ENABLED,
  payload: enabled2faPayload,
});

export const get2faQrCode = () => {
  return async (dispatch) => {
    const token = getUserToken();
    const headers = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
    };
    const response = await fetch(
      `${BASE_API_URL}/users/show_2fa_qr_code.svg`,
      headers
    );
    const svgQrCode = await response.text();
    dispatch(set2faQrCode(svgQrCode));
  };
};

export const enable2fa = (otpAttempt) => {
  return async (dispatch) => {
    const token = getUserToken();
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          otp_attempt: otpAttempt,
        },
      }),
    };
    const response = await fetch(
      `${BASE_API_URL}/users/enable_2fa`,
      requestOptions
    );
    dispatch(set2faEnabled(response.ok));
  };
};
