import Rate from 'rc-rate';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import 'rc-rate/assets/index.css';
import Favorite from './Favorite';
import { useDispatch, useSelector } from 'react-redux';
import {
  onUpdateFavoriteVideoAfterRate,
  updateModuleSectionVideoRate,
} from '../redux/actions/sectionActions';
import CustomSpinner from './CustomSpinner';
import { replaceFavoriteVideos } from '../redux/actions/favoriteActions';
import { Badge } from 'react-bootstrap';
import { getSectionByVideoFromMenu } from '../redux/actions/videoActions';
import { CLOUDFRONT_SECURE_PROXY } from '../constants/constants';
import RateControl from './RateControl';

const CustomVideo = ({
  video,
  url,
  onProgress,
  onEnded,
  playing,
  onPlay,
  canRate = true,
  canFav = true,
}) => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const dispatch = useDispatch();
  const { isHelpCenter } = useSelector((state) => state.course);
  const { openFavoriteSection } = useSelector((state) => state.favorite);
  const { updateFavoriteVideoAfterRate } = useSelector(
    (state) => state.section
  );

  const [rate, setRate] = useState(0);
  const [startPosition, setStartPosition] = useState(0);
  const [videoSubtitles, setVideoSubtitles] = useState(null);
  const [haveSubtitlesBeenParsed, setHaveSubtitlesBeenParsed] = useState(false);

  useEffect(() => {
    if (video && video.user_rating) {
      setRate(
        video?.user_rating && video?.user_rating.length
          ? video?.user_rating[0].rating
          : 0
      );
    }

    if (
      video &&
      video.progresses &&
      video.progresses.length &&
      video.progresses[0].progress < +video.duration
    ) {
      setStartPosition(video.progresses[0].progress);
    }

    let subtitleUrl = '';
    if (video.stream_url) {
      subtitleUrl =
        CLOUDFRONT_SECURE_PROXY +
        '0000_AmazonTranscribeSubtitles/video' +
        video.id +
        '.vtt';
    } else {
      subtitleUrl =
        CLOUDFRONT_SECURE_PROXY + '0000_AmazonTranscribeSubtitles/empty.vtt';
    }
    // console.log('Subtitles URL: ', subtitleUrl);
    fetch(subtitleUrl)
      .then((res) => res.blob())
      .then((blob) => {
        let objectURL = URL.createObjectURL(blob, { type: 'text/vtt' });
        setVideoSubtitles(objectURL);
        setHaveSubtitlesBeenParsed(true);
        const preview = document.getElementsByClassName(
          'react-player__preview'
        );
        if (preview[0]) {
          preview[0].ariaLabel = 'Play video';
          preview[0].role = 'button';
          preview[0].ariaPressed = false;
        }
      })
      .catch((err) => {
        setVideoSubtitles(
          URL.createObjectURL(new Blob([''], { type: 'text/vtt' }))
        );
        setHaveSubtitlesBeenParsed(true);
      });
  }, [video]);

  function onVideoTitleLinkClick() {
    const videoPreview = document
      .getElementById('reactplayercontrol' + video.id)
      .querySelector('.react-player__preview');
    if (videoPreview) videoPreview.click();
  }

  function translateCloudfrontUrl(rawS3Url) {
    const urlObj = new URL(rawS3Url);
    return CLOUDFRONT_SECURE_PROXY.replace(/\/$/, '') + urlObj.pathname;
  }

  useEffect(() => {
    if (updateFavoriteVideoAfterRate) {
      dispatch(replaceFavoriteVideos(updateFavoriteVideoAfterRate));
      dispatch(onUpdateFavoriteVideoAfterRate(undefined));
    }

    // eslint-disable-next-line
  }, [updateFavoriteVideoAfterRate]);

  const onChangeRating = (videoRate, videoId) => {
    // avoid scrolling effect
    dispatch(getSectionByVideoFromMenu(0));

    setRate(videoRate);
    dispatch(updateModuleSectionVideoRate(videoId, videoRate));
  };

  if (!video) {
    return <CustomSpinner />;
  }

  return (
    <>
      <div>
        <h3 className="text-color-gray font-24 font-poppins fw-300">
          <a
            href="#"
            onClick={onVideoTitleLinkClick}
            id={'videoheader' + (video ? video.id : '0')}
            className="text-color-gray font-24 font-poppins fw-300"
            style={{ textDecoration: 'none' }}
            aria-label={video ? video.title + ' video player' : 'Video Title'}
            aria-expanded={true}
            role="button"
          >
            {video?.title || 'Video Title'}
          </a>
        </h3>
      </div>
      {/*!!openFavoriteSection && (
        <div>
          <p className="m-0 font-weight-bolder">{video?.course_name}</p>
          <p className="m-0 font-16">{video?.course_module_name}</p>
          <p className="font-14 text-black-50">{video?.module_section_name}</p>
        </div>
      )*/}
      <div className="player-wrapper">
        {haveSubtitlesBeenParsed && (
          <ReactPlayer
            id={'reactplayercontrol' + video.id}
            onProgress={onProgress}
            onEnded={onEnded}
            url={
              isSafari
                ? video?.url
                : url
                  ? url
                  : video?.stream_url
                    ? video?.stream_url
                    : video?.url
            }
            width="100%"
            height="auto"
            light={
              video?.thumbnail_url
                ? translateCloudfrontUrl(video?.thumbnail_url)
                : ''
            }
            playsinline={!isSafari}
            playing={playing}
            onError={(err) => {
              console.log(err);
            }}
            config={
              video?.stream_url && videoSubtitles != null
                ? {
                  forceHLS: !isSafari,
                  file: {
                    forceHLS: !isSafari,
                    attributes: {
                      preload: 'metadata',
                      autoPlay: false,
                    },
                    tracks: [
                      {
                        kind: 'subtitles',
                        src: videoSubtitles,
                        srcLang: 'en',
                        default: localStorage.getItem('subtitlesToggle') == 1,
                      },
                    ],
                  },
                  hlsOptions: {
                    minAutoBitrate: 7000,
                    startLevel: 4,
                    startPosition: startPosition,
                  },
                }
                : {}
            }
            onClickPreview={() => {
              if (isSafari) {
                // Safari workaround
                setTimeout(() => {
                  try {
                    document
                      .getElementById(`video-${video?.id}`)
                      .querySelector('video')
                      .play();
                  } catch (e) {
                    console.log('video not loaded');
                  }
                }, 300);
              } else {
                onPlay(video?.id);
              }
            }}
            onPlay={() => {
              onPlay(video?.id);
            }}
            controls
          />
        )}
      </div>

      <div className="video-description-container">
        <div className="d-flex flex-wrap justify-content-between align-items-start align-items-sm-center">
          <div className="ml-2">
            {canFav && !isHelpCenter ? (
              <Favorite video={video} user_favorite={video?.user_favorite} />
            ) : (
              // OR
              ''
            )}
          </div>
          {canRate && !isHelpCenter ? (
            <div aria-hidden={true} className="like-section mr-2">
              {/* <RateControl
                onChange={(videoRate) => onChangeRating(videoRate, video?.id)}
                rating={rate}
              /> */}
            </div>
          ) : (
            // OR
            ''
          )}
        </div>

        {false ? (
          video.tags && video.tags !== '' ? (
            <div className="tags d-flex align-items-start justify-content-end mt-3">
              {video.tags.split(',').map((tag, i) => {
                return (
                  <div key={i}>
                    <Badge
                      className="bg-color-accent text-white font-weight-bolder mr-2"
                      bg="primary"
                    >
                      {tag}
                    </Badge>
                  </div>
                );
              })}
            </div>
          ) : (
            // OR
            ''
          )
        ) : (
          // OR
          ''
        )}
        <div className="video-description">
          <div className="description">{video?.description || 'No Title'}</div>
        </div>
      </div>
    </>
  );
};

export default React.memo(CustomVideo);
