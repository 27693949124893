import { OPEN_MENU, COLLAPSE_MENU } from '../types/menuTypes';

const initialState = {
  error: '',
  openMenuId: 0,
  openMenuLevel: 0,
  collapseAll: false,
};

export const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MENU:
      return {
        ...state,
        openMenuId: action.payload.id,
        openMenuLevel: action.payload.level,
      };
    case COLLAPSE_MENU:
      return {
        ...state,
        collapseAll: action.payload,
      };
    default:
      return state;
  }
};
