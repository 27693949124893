import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as solidHeart } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as regularHeart } from '@fortawesome/free-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { reloadFavoriteVideos } from '../redux/actions/favoriteActions';
import { updateModuleSectionVideoFavorite } from '../redux/actions/sectionActions';
import { getSectionByVideoFromMenu } from '../redux/actions/videoActions';

const Favorite = ({ video, user_favorite }) => {
  const dispatch = useDispatch();
  const { openFavoriteSection } = useSelector((state) => state.favorite);

  const [favorite, setFavorite] = useState(user_favorite);

  useEffect(() => {
    setFavorite(user_favorite);
  }, [user_favorite]);

  const makeFavorite = (event) => {
    // avoid scrolling effect
    dispatch(getSectionByVideoFromMenu(0));

    event.preventDefault();
    event.stopPropagation();

    if (!openFavoriteSection) {
      setFavorite(!favorite);
    }

    dispatch(updateModuleSectionVideoFavorite(video));

    if (openFavoriteSection) {
      setTimeout(() => {
        dispatch(reloadFavoriteVideos(video.id));
      }, 500);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-start">
        <div className="mr-3 cursor-pointer">
          <a
            href="#"
            style={{ textDecoration: 'none', color: '#696969' }}
            aria-label={(favorite ? 'Remove from' : 'Add to') + ' Bookmarks'}
            onClick={makeFavorite}
          >
            Bookmark&nbsp;&nbsp;
            {!!favorite && (
              <FontAwesomeIcon icon={solidHeart} color={'#1c8097'} />
            )}
            {!favorite && (
              <FontAwesomeIcon icon={regularHeart} color={'#1c8097'} />
            )}
          </a>
        </div>
      </div>
    </>
  );
};

export default Favorite;
