// eslint-disable-next-line import/prefer-default-export
export const downloadFile = (name, content) => {
  const url = window.URL.createObjectURL(new Blob([content]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name.replaceAll(' ', '_')}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
