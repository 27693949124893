import { applyMiddleware, compose, createStore } from 'redux';
import { reducers } from './reducers';
import thunkMiddleware from 'redux-thunk';

const loggerMiddleware = (store) => (next) => (action) => {
  //console.group(action.type);
  //console.info('dispatching', action);
  let result = next(action);
  //console.log('next state', store.getState());
  //console.groupEnd();
  return result;
};

const monitorReducersEnhancer =
  (createStore) => (reducer, initialState, enhancer) => {
    const monitoredReducer = (state, action) => reducer(state, action);
    return createStore(monitoredReducer, initialState, enhancer);
  };

export default function configureStore(preloadState) {
  const middlewares = [loggerMiddleware, thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
  const composedEnhancers = compose(...enhancers);

  return createStore(reducers, preloadState, composedEnhancers);
}
