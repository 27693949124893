import React, { useEffect, useState } from 'react';
import { fetchHelpCenter } from '../../redux/actions/helpCenterActions';
import '../../styles/HelpCenter.scss';
import { Col, Row } from 'react-bootstrap';
import Logo from '../../components/Logo';
import Header from '../../components/Header';
import { getUserInfo, UserModel } from '../../context/authContext/authActions';
import { useAuthContext } from '../../context/authContext';
import { useHistory } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Module from '../../components/Module';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCoursesHelpCenter,
  fetchCoursesSuccess,
} from '../../redux/actions/courseActions';
import SearchResults from '../../components/SearchResults';

const HelpCenter = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showSearchPage, setShowSearchPage] = useState();

  const {
    state: { user },
  } = useAuthContext();
  const { searchValue } = useSelector((state) => state.search);

  useEffect(() => {
    setShowSearchPage(searchValue !== '');
  }, [searchValue]);

  useEffect(() => {
    if (user) {
      getUserInfo(user.id).then(
        (response) => {
          let userModel = new UserModel(response.user);

          // Update user token in local storage
          localStorage.setItem('user', JSON.stringify(userModel));
        },
        () => {}
      );
    }
  }, [user]);

  useEffect(() => {
    fetchHelpCenter().then((data) => {
      dispatch(fetchCoursesSuccess(data.courses));
      dispatch(fetchCoursesHelpCenter());
    });
  }, []);

  return (
    <>
      <div className="help-container">
        <Row>
          <Col className={`pl-0 pl-md-3`} md={3} xs={12}>
            <div className="d-none d-md-block logo mt-3 mt-sm-0 cursor-pointer">
              <Logo
                onClick={() => {
                  history.push('/dashboard');
                }}
              />
            </div>
            <Sidebar _isHelpCenter={true} />
            <div className="d-block d-md-none logo mt-3 mt-sm-0 cursor-pointer">
              <Logo
                onClick={() => {
                  history.push('/dashboard');
                }}
              />
            </div>
          </Col>

          <Col md={9} xs={12}>
            <Header user={user} />
            <SearchResults display={showSearchPage} />
            {!showSearchPage && (
              <Module main_school_graphic={user.main_school_graphic} />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
export default HelpCenter;
