import { Form } from 'react-bootstrap';
import React from 'react';
import '../../styles/Survey.scss';

const SurveyQuestion = (props) => {
  const sortItem = (a, b) => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  };

  return (
    <>
      <div className="row border-top-2 pt-3 mt-3">
        <div className="col-12">
          {props.number && (
            <p className="m-0 font-14 fw-500 text-color-gray">
              Question #{props.number}
            </p>
          )}
          <p className="font-weight-bolder">{props.question?.name}</p>
        </div>

        <div className="col-12 survey-question-list">
          {props.question?.survey_question_options
            ?.sort(sortItem)
            .map((option, index) => {
              return (
                <div
                  key={`survey-option-${index}`}
                  className="survey-option d-flex align-items-center"
                >
                  <Form.Check
                    onChange={() => {
                      props.handleSelectOption(option);
                    }}
                    defaultChecked={option.selected}
                    id={`survey-option-${option.id}-${props.listCount}-${index}`}
                    name={`p${props.question?.id}`}
                    type="radio"
                    className="custom-radio-accent"
                  />
                  <label
                    htmlFor={`survey-option-${option.id}-${props.listCount}-${index}`}
                    className="font-poppins font-14 fw-500 text-color-primary mb-1 ml-3"
                  >
                    {option?.name}
                  </label>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default SurveyQuestion;
