import { requestGetApi, requestPostApi } from '../../services/api/api';
import { BASE_API_URL } from '../../constants/constants';
import { saveCurrentSectionOnStorage } from '../../services/localStorage';
import {
  FETCH_SECTION_FAILURE,
  FETCH_SECTION_REQUEST,
  FETCH_SECTION_SUCCESS,
  FETCH_SECTIONS_FAILURE,
  FETCH_SECTIONS_REQUEST,
  FETCH_SECTIONS_SUCCESS,
  FETCH_VIDEO_FAVORITE_FAILURE,
  FETCH_VIDEO_FAVORITE_REQUEST,
  FETCH_VIDEO_FAVORITE_SUCCESS,
  FETCH_VIDEO_PROGRESS_FAILURE,
  FETCH_VIDEO_PROGRESS_REQUEST,
  FETCH_VIDEO_PROGRESS_SUCCESS,
  FETCH_VIDEO_RATE_FAILURE,
  FETCH_VIDEO_RATE_REQUEST,
  FETCH_VIDEO_RATE_SUCCESS,
  UPDATE_FAVORITE_VIDEO_AFTER_RATE,
  UPDATE_SECTION_HISTORY,
  UPDATE_SECTIONS_HISTORY,
  UPDATE_HAS_SURVEY,
  CREATE_RATING_REQUEST,
  CREATE_RATING_SUCCESS,
  CREATE_RATING_FAILURE,
} from '../types/sectionTypes';
import { getModuleById } from './moduleActions';
import {
  getSectionByVideoFromMenu,
  onSelectCurrentVideo,
} from './videoActions';

const fetchSectionRequest = () => ({
  type: FETCH_SECTION_REQUEST,
});

const fetchSectionSuccess = (section) => ({
  type: FETCH_SECTION_SUCCESS,
  payload: section,
});

const fetchSectionFailure = (error) => ({
  type: FETCH_SECTION_FAILURE,
  payload: error,
});

const fetchSectionsRequest = () => ({
  type: FETCH_SECTIONS_REQUEST,
});

export const fetchSectionsSuccess = (sections) => ({
  type: FETCH_SECTIONS_SUCCESS,
  payload: sections,
});

const fetchSectionsFailure = (error) => ({
  type: FETCH_SECTIONS_FAILURE,
  payload: error,
});

export const updateSectionsHistory = (sectionsHistory) => ({
  type: UPDATE_SECTIONS_HISTORY,
  payload: sectionsHistory,
});

export const updateSectionHistory = (sectionHistory) => ({
  type: UPDATE_SECTION_HISTORY,
  payload: sectionHistory,
});

export const updateHasSurvey = (hasSurvey = false) => ({
  type: UPDATE_HAS_SURVEY,
  payload: hasSurvey,
});

export const getSectionById = (id = 0) => {
  return async (dispatch, getState) => {
    dispatch(getSectionByVideoFromMenu(id));

    // if section's id equal zero
    // set currentModule to undefined
    if (id === 0) {
      dispatch(onSelectCurrentSection(undefined));
      dispatch(onSelectCurrentVideo(undefined));
      return;
    }

    const { currentSection, sectionHistory } = getState().section;

    // ********************************
    // search section in state
    let history = sectionHistory;

    if (!history) {
      history = {};
    }

    // if section exists in history
    if (!!history && !!history[id]) {
      dispatch(onSelectCurrentSection({ ...history[id] }));
      return;
    }

    // if currentSection already searched
    // avoid searching again
    if (currentSection && currentSection.id === id) {
      dispatch(onSelectCurrentSection({ ...currentSection }));
      return;
    }

    // fetch currentSection
    try {
      dispatch(fetchSectionRequest());
      const section = await requestGetApi(
        `${BASE_API_URL}/module_sections/${id}`
      );
      dispatch(onSelectCurrentSection({ ...section }));

      // update state
      history[id] = section;
      dispatch(updateSectionHistory(history));
    } catch (ex) {
      dispatch(fetchSectionFailure(ex.toString()));
    }
  };
};

export const getSectionsByModule = (moduleId = 0) => {
  return async (dispatch, getState) => {
    dispatch(fetchSectionsRequest());

    // ********************
    // if module id is zero
    // send empty array
    if (moduleId === 0) {
      dispatch(fetchSectionsSuccess([]));
      return;
    }

    // ********************************
    // search list of sections in state
    const { sectionsHistory } = getState().section;
    let history = sectionsHistory;

    if (!history) {
      history = {};
    }

    // if history exists
    if (!!history && !!history[moduleId]) {
      dispatch(fetchSectionsSuccess([...history[moduleId]]));
      return;
    }

    try {
      const response = await requestGetApi(
        `${BASE_API_URL}/module_sections?course_module_id=${moduleId}`
      );
      dispatch(fetchSectionsSuccess(response.module_sections));

      // save in state array of sections by module
      history[moduleId] = [...response.module_sections];
      dispatch(updateSectionsHistory({ ...history }));
    } catch (ex) {
      dispatch(fetchSectionsFailure(ex.toString()));
    }
  };
};

export const onSelectCurrentSection = (section) => {
  return async (dispatch, getState) => {
    dispatch(fetchSectionSuccess(section));
    saveCurrentSectionOnStorage(section);

    // validate section belongs to currentModule
    if (section && section.course_module_id) {
      // get currentModule from state
      const { currentModule } = getState().module;

      // if currentModule do not exists, search it
      if (!currentModule) {
        dispatch(getModuleById(section.course_module_id));
      } else if (currentModule.id !== section.course_module_id) {
        // if currentModule exists but it is different from section's module, search it
        dispatch(getModuleById(section.course_module_id));
      }
    }
  };
};

const fetchVideoProgressRequest = () => ({
  type: FETCH_VIDEO_PROGRESS_REQUEST,
});

const FetchVideoProgressSuccess = (moduleSectionVideoProgress) => ({
  type: FETCH_VIDEO_PROGRESS_SUCCESS,
  payload: moduleSectionVideoProgress,
});

const fetchVideoProgressFailure = (error) => ({
  type: FETCH_VIDEO_PROGRESS_FAILURE,
  payload: error,
});

const fetchVideoRateRequest = () => ({
  type: FETCH_VIDEO_RATE_REQUEST,
});

const fetchVideoRateSuccess = (currentVideo) => ({
  type: FETCH_VIDEO_RATE_SUCCESS,
  payload: currentVideo,
});

const fetchVideoRateFailure = (error) => ({
  type: FETCH_VIDEO_RATE_FAILURE,
  payload: error,
});

const fetchVideoFavoriteRequest = (videoId) => ({
  type: FETCH_VIDEO_FAVORITE_REQUEST,
  payload: videoId,
});

const fetchVideoFavoriteSuccess = (video) => ({
  type: FETCH_VIDEO_FAVORITE_SUCCESS,
  payload: video,
});

const fetchVideoFavoriteFailure = (error) => ({
  type: FETCH_VIDEO_FAVORITE_FAILURE,
  payload: error,
});

export const updateModuleSectionVideoProgress = (
  module_section_videos_progress_params
) => {
  return async (dispatch) => {
    try {
      dispatch(fetchVideoProgressRequest());
      const response = await requestPostApi(
        `${BASE_API_URL}/module_section_videos_progresses`,
        { module_section_videos_progress_params }
      );
      dispatch(FetchVideoProgressSuccess(response));
    } catch (error) {
      dispatch(fetchVideoProgressFailure(error.toString()));
    }
  };
};

export const updateModuleSectionVideoFavorite = (video) => {
  return async (dispatch) => {
    const module_section_video = {};

    try {
      dispatch(fetchVideoFavoriteRequest(video.id));
      await requestPostApi(
        `${BASE_API_URL}/module_section_videos/${video.id}/favorite`,
        module_section_video
      );
      setTimeout(() => {
        dispatch(fetchVideoFavoriteSuccess(video));
      }, 100);
    } catch (error) {
      dispatch(fetchVideoFavoriteFailure(error));
    }
  };
};

export const updateModuleSectionVideoRate = (videoId, rating) => {
  return async (dispatch) => {
    const module_section_video = {
      rating,
    };

    try {
      dispatch(fetchVideoRateRequest());
      const response = await requestPostApi(
        `${BASE_API_URL}/module_section_videos/${videoId}/rate`,
        { module_section_video }
      );
      dispatch(fetchVideoRateSuccess(response));
    } catch (error) {
      dispatch(fetchVideoRateFailure(error));
    }
  };
};

export const onUpdateFavoriteVideoAfterRate = (video) => ({
  type: UPDATE_FAVORITE_VIDEO_AFTER_RATE,
  payload: video,
});

export const updateModuleSectionScore = (score, currentSection) => {
  return async (dispatch) => {
    let section = { ...currentSection };
    section.has_pending_checks_score = score;

    dispatch(onSelectCurrentSection({ ...section }));
  };
};

// module ratings

const createRatingRequest = () => ({
  type: CREATE_RATING_REQUEST,
});

const createRatingSuccess = (rating) => ({
  type: CREATE_RATING_SUCCESS,
  payload: rating,
});

const createRatingFailure = (error) => ({
  type: CREATE_RATING_FAILURE,
  payload: error,
});

export const createRating = (moduleId, sectionId, videoId, rating) => {
  return async (dispatch) => {
    try {
      dispatch(createRatingRequest());

      const response = await requestPostApi(
        `${BASE_API_URL}/module_ratings`,
        {
          course_module_id: moduleId,
          section_id: sectionId,
          video_id: videoId,
          rating: rating,
        }
      );

      dispatch(createRatingSuccess(response));
    } catch (error) {
      dispatch(createRatingFailure(error.toString()));
    }
  };
};