import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from '../Logo';
import signInStyle from '../../styles/SignIn.module.scss';
import appStyle from '../../styles/App.module.scss';
import { BASE_API_URL } from '../../constants/constants';
import { requestPostApi } from '../../services/api/api';

const Unsubscribe = () => {
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    useEffect(() => {
        handleUnsubscribe();
    }, [location.search]);

    const handleUnsubscribe = async () => {
        try {
            setLoading(true);

            const response = await requestPostApi(`${BASE_API_URL}/users/unsubscribe`, {});

            if (!response.success) {
                const errorMessage = response.statusText || 'HTTP error';
                throw new Error(`${errorMessage}! Status: ${response.status}`);
            }
            setMessage(response.message);

        } catch (error) {
            console.error('Error unsubscribing:', error);
            setMessage('An error occurred while unsubscribing. Please try again.');
        } finally {
            setLoading(false);
        }

    };


    return (
        <>
            <div className={signInStyle['form-wrapper']}>
                <div className={appStyle['form-header-container']}>
                    <Logo />
                    <p className="mb-2 mt-4 font-18 text-color-primary font-poppins fw-500">
                        {loading ? 'Unsubscribing...' : message}
                    </p>
                </div>
            </div>
        </>
    );
};

export default Unsubscribe;
