import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import appStyle from '../styles/App.module.scss';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { MAIN_DOMAIN } from '../constants/constants';
import {
  loginWithGoogle,
  updatePassword,
} from '../context/authContext/authActions';
import { useAuthContext } from '../context/authContext';
import useEventListener from '../hooks/useEventListener';
import AlertDismissible from './AlertDismissible';
import CustomButton from './CustomButton';
import Logo from './Logo';
import { LOGOUT } from '../constants/actionTypes';

const SignUpForm = () => {
  const {
    dispatch,
    state: { user, error },
  } = useAuthContext();

  const history = useHistory();
  const location = useLocation();

  const [token, setToken] = useState('');
  const [invalidForm, setInvalidForm] = useState(false);
  const [requireEmail, setRequireEmail] = useState(true);
  const [invalidFormMsg, setInvalidFormMsg] = useState('');

  const [inputs, setInputs] = useState({
    email_address: user ? user.email : '',
    password: '',
    confirm_password: '',
  });

  const [showAlert, setShowAlert] = useState(false);
  const newWindowRef = useRef(null);

  useEffect(() => {
    setRequireEmail(location.pathname !== '/reset_password');

    let params = location.search.split('=');
    let index = -1;

    params.forEach((param, i) => {
      if (param.indexOf('reset_password_token') !== -1) index = i;
    });

    let value = params[index + 1];
    setToken(value);
  }, [location.pathname, location.search]);

  const onMessageReceiveFromBackend = React.useCallback(
    async (event) => {
      if (event.origin.startsWith(MAIN_DOMAIN)) {
        if (event.data.user) {
          await loginWithGoogle(dispatch, event.data.user);
          if (newWindowRef.current) {
            newWindowRef.current.window.close();
          }
        }
      } else {
        console.log('Unauthorized event!', event);
      }
    },
    [dispatch]
  );

  useEventListener(onMessageReceiveFromBackend);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSignupWithEmail = async (e) => {
    e.preventDefault();
    setInvalidForm(false);

    const { email_address, password, confirm_password } = inputs;

    if (password === '' || confirm_password === '') {
      setInvalidFormMsg('Password and Confirm Password are required fields!');
      return;
    }

    if (requireEmail && user.email !== email_address) {
      setInvalidForm(true);
      setInvalidFormMsg('Use a valid email address');
      return;
    }

    const payload = {
      user: {
        email: email_address || '',
        password: password,
        require_new_password: (user && user.require_new_password) || false,
        password_confirmation: confirm_password,
        reset_password_token: token
          .toString()
          ?.replace('reset_password_token=', ''),
      },
    };
    await updatePassword(dispatch, payload);

    if (requireEmail && user.require_new_password) {
      await localStorage.removeItem('user');
      dispatch({ type: LOGOUT });
    }

    history.push('/signin');
  };
  const onCloseAlert = () => {
    setShowAlert((prevShowAlert) => !prevShowAlert);
  };
  const Errors = () => {
    let output = '';
    if (typeof error === 'object') {
      for (const key in error) {
        if (Object.hasOwnProperty.call(error, key)) {
          const sError = error[key];
          output += `${key}:${sError}\n`;
        }
      }
    } else {
      output = error || 'Unexpected error';
    }
    return (
      <AlertDismissible
        variant={'danger'}
        content={output}
        showAlert={showAlert}
        onCloseAlert={onCloseAlert}
      />
    );
  };

  const isDisabled = () => {
    let isPasswordsWrongs =
      !inputs.password ||
      !inputs.confirm_password ||
      inputs.confirm_password !== inputs.password;

    if (requireEmail) return !inputs.email_address || isPasswordsWrongs;
    else return isPasswordsWrongs;
  };

  return (
    <>
      <div className={appStyle['form-wrapper']}>
        <div className={appStyle['form-header-container']}>
          <Logo />
          {requireEmail && (
            <p className={appStyle.text}>
              Enter the Email Address you used when you joined and also the new
              password.
            </p>
          )}
          {!requireEmail && (
            <p className={appStyle.text}>Enter your new password.</p>
          )}
        </div>
        {error && <Errors />}
        {invalidForm && (
          <AlertDismissible
            variant={'danger'}
            content={invalidFormMsg}
            showAlert={invalidForm}
            onCloseAlert={() => {
              setInvalidForm(false);
              setInvalidFormMsg('');
            }}
          />
        )}
        <Form onSubmit={handleSignupWithEmail}>
          <div className={appStyle['b-form-body']}>
            {requireEmail && (
              <Form.Group
                className={`${user ? 'bg-gray' : 'bg-red'} ${
                  appStyle['custom-form-group']
                }`}
              >
                <div className={appStyle['form-custom-label']}>
                  Email Address
                </div>
                <Form.Control
                  className={`user-select-none`}
                  disabled={user ? 'true' : 'false'}
                  type="text"
                  placeholder="Email Address"
                  value={inputs['email_address']}
                  onChange={onChangeHandler}
                  name="email_address"
                  pattern={'[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$'}
                />
              </Form.Group>
            )}
            <Form.Group className={appStyle['custom-form-group']}>
              <div className={appStyle['form-custom-label']}>New password</div>
              <Form.Control
                type="password"
                autocomplete={'off'}
                value={inputs['password']}
                onChange={onChangeHandler}
                required
                name="password"
              />
            </Form.Group>
            <Form.Group className={appStyle['custom-form-group']}>
              <div className={appStyle['form-custom-label']}>
              Confirm new password
              </div>
              <Form.Control
                type="password"
                autocomplete={'off'}
                value={inputs['confirm_password']}
                onChange={onChangeHandler}
                required
                name="confirm_password"
              />
            </Form.Group>
          </div>
          <div class="text-center mb-4">
            <CustomButton
              className={`custom-button-default half-width ${
                isDisabled() ? 'disabled' : ''
              }`}
              type="submit"
              disabled={isDisabled()}
              label="Submit"
            ></CustomButton>
          </div>
        </Form>
      </div>
    </>
  );
};

export default SignUpForm;
