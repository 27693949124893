import {
  FIRST_LOAD_PAGE,
  SET_GLOBAL_LOADING,
  SET_2FA_QR_CODE,
  SET_2FA_ENABLED,
} from '../types/dashboardTypes';

const initialState = {
  firstLoadPage: true,
  globalLoading: false,
  qrCode2fa: '',
  enabled2fa: false,
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIRST_LOAD_PAGE:
      return {
        ...state,
        firstLoadPage: false,
      };
    case SET_GLOBAL_LOADING:
      return {
        ...state,
        globalLoading: action.payload,
      };
    case SET_2FA_QR_CODE:
      return {
        ...state,
        qrCode2fa: action.payload,
      };
    case SET_2FA_ENABLED:
      return {
        ...state,
        enabled2fa: action.payload,
      };
    default:
      return state;
  }
};
