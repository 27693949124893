import { useDispatch, useSelector } from 'react-redux';
import { onCollapseMenu, onOpenMenu } from '../../redux/actions/menuActions';
import React, { useState } from 'react';

const SearchResults = ({ display }) => {
  const dispatch = useDispatch();
  const { error, searchLoading, videos } = useSelector((state) => state.search);

  const [errorMessage, setErrorMessage] = useState('');

  const getItem = (key, item, type) => {
    let name = item.name;
    let description = item.description;

    if (item && item.title) name = item.title;
    return (
      <div key={key} className="mb-5">
        <p
          className="cursor-pointer m-0 fw-300 font-poppins font-18 text-color-accent search-video-title"
          onClick={() => handleOnClick(type, item)}
        >
          <a className="text-color-accent" href="#">
            {name}
          </a>
        </p>
        <p className="m-0 font-13 text-color-gray fw-400">
          {!!description && description}
        </p>
      </div>
    );
  };

  const handleOnClick = (type, item) => {
    try {
      setErrorMessage('');

      dispatch(onCollapseMenu(true));
      dispatch(onOpenMenu(type, item.id));
    } catch (ex) {
      setErrorMessage(
        'no videos found for this selected course/module/section'
      );
    }
  };

  return (
    <>
      <div
        className="row mt-3"
        style={{ visibility: display ? 'visible' : 'hidden' }}
      >
        <div className="col-12 mb-4">
          <h5
            className="fw-300 font-poppins text-color-primary font-25"
            tabIndex="0"
            aria-live="assertive"
          >
            {display && searchLoading && 'Searching...'}
          </h5>

          <h5
            className="search-title fw-300 font-poppins pb-3 mb-1 font-25 text-color-accent border-bottom-2"
            tabIndex="0"
            aria-live="assertive"
          >
            {display && !searchLoading && 'Search results:'}
          </h5>

          <p className="text-danger" tabIndex="0" aria-live="assertive">
            {error !== '' && error}
          </p>
          <p className="text-danger" tabIndex="0" aria-live="assertive">
            {errorMessage !== '' && errorMessage}
          </p>

          <p
            className="cursor-pointer m-0 fw-300 font-poppins font-18 text-color-primary"
            tabIndex="0"
            aria-live="assertive"
          >
            {display &&
              videos.length === 0 &&
              !searchLoading &&
              'No videos found'}
          </p>
        </div>
        {display && videos.length > 0 && (
          <div className="col-12">
            {videos.map((e, index) => getItem(index, e, 4))}
          </div>
        )}
      </div>
    </>
  );
};

export default SearchResults;
