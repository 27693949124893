import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

import appStyle from '../../styles/App.module.scss';
import { Link, useHistory } from 'react-router-dom';
import CustomModal from '../CustomModal';
import GoogleButton from '../GoogleButton';
import { requestGoogleForm } from '../../services/google/google';
import { MAIN_DOMAIN } from '../../constants/constants';
import {
  loginWithGoogle,
  signupWithEmail,
} from '../../context/authContext/authActions';
import { useAuthContext } from '../../context/authContext';
import useEventListener from '../../hooks/useEventListener';
import Logo from '../Logo';
import CustomButton from '../CustomButton';
import TermsAndConditions from '../TermsAndConditions';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import AlertBounceDismissible from '../AlertBounceDismissible';
import CustomSpinner from '../CustomSpinner';
import signInStyle from '../../styles/SignIn.module.scss';
import CleverButton from '../CleverButton';
import styles from '../../styles/Dashboard.module.scss';

const SignUpForm = () => {
  const {
    dispatch,
    state: { error },
  } = useAuthContext();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [inputs, setInputs] = useState({
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    tos_accepted: true,
  });
  const [isTCAccepted, setIsTCAccepted] = useState(false);
  const [checkTCAccepted, setCheckIsTCAccepted] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const _onAcceptTC = (isTermsAccepted) => {
    if (isTermsAccepted) {
      setIsTCAccepted(true);
    } else {
      setIsTCAccepted(false);
    }
  };

  const toggle = () => setModal(!modal);

  const newWindowRef = useRef(null);

  const onMessageReceiveFromBackend = React.useCallback(
    async (event) => {
      if (event.origin.startsWith(MAIN_DOMAIN)) {
        if (event.data.user) {
          const data = await loginWithGoogle(dispatch, event.data.user);
          if (newWindowRef.current) {
            newWindowRef.current.window.close();
          }

          if (!data) {
            setShowAlert(true);
          }

          history.push('/dashboard');
        }
      } else {
        // console.log('Unauthorized event!', event);
      }

      // eslint-disable-next-line
    },
    [dispatch]
  );

  useEffect(() => {
    if (!showAlert) {
      setShowAlert(true);
    }

    // eslint-disable-next-line
  }, [error]);

  useEventListener(onMessageReceiveFromBackend);

  const _requestGoogleSignupForm = () => {
    if (isTCAccepted) {
      setCheckIsTCAccepted('');
      newWindowRef.current = requestGoogleForm();
    } else {
      setCheckIsTCAccepted('Check Terms & Conditions first to continue');
    }
  };

  const SignUpWithGoogleButton = () => {
    return (
      <GoogleButton
        onClick={_requestGoogleSignupForm}
        text="Sign up with Google"
      />
    );
  };
  const SignUpWithCleverButton = () => {
    return <CleverButton onClick={cleaverLogin} text="Sign up with Clever" />;
  };
  const cleaverLogin = (ev) => {
    if (isTCAccepted) {
      setCheckIsTCAccepted('');
      window.location.href =
        'https://schools.clever.com/applications/add/bounceback-dev';
    } else {
      setCheckIsTCAccepted('Check Terms & Conditions first to continue');
    }
  };
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    const { password, confirmPassword } = inputs;
    if (confirmPassword.length !== 0 && password !== confirmPassword) {
      setPasswordError('Password and Confirm password are not the same!');
    } else if (confirmPassword.length !== 0 && confirmPassword.length < 6) {
      setPasswordError('Password cannot be less than 6 characters!');
    } else {
      setPasswordError(null);
    }
  }, [inputs]);

  const handleSignupWithEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setPasswordError('');
    setSuccessMessage('');

    const {
      first_name,
      last_name,
      tos_accepted,
      email,
      password,
      confirmPassword,
      registration_code,
    } = inputs;

    if (password !== confirmPassword) {
      setPasswordError('Password and Confirm password are not the same!');
      setIsLoading(false);
      return;
    }

    const username = email;
    const payload = {
      user: {
        first_name,
        last_name,
        username,
        tos_accepted,
        email,
        password,
        password_confirmation: confirmPassword,
        registration_code,
      },
    };
    const user = await signupWithEmail(dispatch, payload);
    setIsLoading(false);

    if (!user) return;
    setSuccessMessage('Registration successful, you can sign in now!');
    setTimeout(() => {
      history.goBack();
    }, 2500);
  };
  const onCloseAlert = () => {
    setShowAlert((prevShowAlert) => !prevShowAlert);
  };
  const Errors = () => {
    let output = '';
    if (typeof error === 'object') {
      for (const key in error) {
        if (Object.hasOwnProperty.call(error, key)) {
          const sError = error[key];
          output += `${sError}\n`;
        }
      }
    } else {
      output = error || 'Unexpected error';
    }

    return (
      <AlertBounceDismissible
        cls={`alert-bounce alert-bounce-warning`}
        onCloseAlert={onCloseAlert}
        showAlert={showAlert}
        content={output}
        icon={faExclamationTriangle}
      />
    );
  };

  const isFormValid =
    !inputs.email ||
    !inputs.password ||
    !inputs.confirmPassword ||
    !inputs.first_name ||
    !inputs.last_name ||
    !isTCAccepted ||
    inputs.password !== inputs.confirmPassword ||
    inputs.password.length < 6;
  return (
    <>
      <div className={signInStyle['form-wrapper']}>
        <div className={appStyle['form-header-container']}>
          <Logo />
        </div>

        {/* TODO: restore google and clever links */}
        <div className="text-center mt-4">
          {/*<div className="mb-3">
            <SignUpWithGoogleButton />
          </div>

          <SignUpWithCleverButton />*/}
        </div>

        <p className="font-poppins my-4 font-18 fw-500 text-center text-color-primary">
          {/* TODO: restore oauth buttons after text */}
          {/*or continue with your email*/}
        </p>

        {error && <Errors />}
        <Form onSubmit={handleSignupWithEmail}>
          <div className={signInStyle['b-form-body']}>
            <div className={signInStyle['form-names-container']}>
              <Form.Group className={signInStyle['custom-form-group']}>
                <Form.Control
                  type="text"
                  placeholder="First name"
                  value={inputs['first_name']}
                  onChange={onChangeHandler}
                  required
                  name="first_name"
                />
              </Form.Group>

              <Form.Group className={signInStyle['custom-form-group']}>
                <Form.Control
                  type="text"
                  placeholder="Last name"
                  required
                  name="last_name"
                  value={inputs['last_name']}
                  onChange={onChangeHandler}
                />
              </Form.Group>
            </div>
            <Form.Group className={signInStyle['custom-form-group']}>
              <Form.Control
                type="email"
                placeholder="Email"
                required
                name="email"
                value={inputs['email']}
                onChange={onChangeHandler}
                title="Enter a valid email address"
                pattern={'[A-Za-z0-9\._\\%\\+\\-]+@[A-Za-z0-9\\.\\-]+.[A-Za-z]{2,}$'}
              />
            </Form.Group>
            <Form.Group className={signInStyle['custom-form-group']}>
              <Form.Control
                type="password"
                placeholder="Password"
                value={inputs['password']}
                onChange={onChangeHandler}
                required
                name="password"
              />
            </Form.Group>
            <Form.Group className={signInStyle['custom-form-group']}>
              <Form.Control
                type="password"
                placeholder="Confirm password"
                value={inputs['confirmPassword']}
                onChange={onChangeHandler}
                required
                name="confirmPassword"
              />
            </Form.Group>
            <Form.Group className={signInStyle['custom-form-group']}>
              <Form.Control
                type="text"
                placeholder="Your registration code"
                required
                value={inputs['registration_code']}
                onChange={onChangeHandler}
                name="registration_code"
              />
            </Form.Group>
          </div>

          {passwordError !== '' && (
            <div className="text-center text-danger">{passwordError}</div>
          )}

          <div className={signInStyle['check-box-wrapper-center']}>
            <div className={signInStyle['check-box-check-container']}>
              <Form.Check
                id={`terms`}
                type="checkbox"
                required
                checked={isTCAccepted}
                onChange={() => {
                  if (!isTCAccepted) {
                    setCheckIsTCAccepted('');
                  }

                  setIsTCAccepted((isTermsAccepted) => !isTermsAccepted);
                }}
              />
              <label
                htmlFor={`terms`}
                className={`mb-0 font-14 fw-500 text-color-primary font-poppins`}
              >
                I agree with the{' '}
                <span onClick={toggle}>
                  <button
                    className={`font-poppins font-14 fw-500 text-color-accent ${signInStyle['clear-btn']}`}
                  >
                    Terms & Conditions
                  </button>
                </span>
              </label>
            </div>
          </div>

          {checkTCAccepted !== '' ? (
            <p className="text-danger text-center fw-500">{checkTCAccepted}</p>
          ) : (
            ''
          )}

          {isLoading && (
            <div className={`my-2`}>
              <CustomSpinner />
            </div>
          )}

          <CustomModal
            isOpen={modal}
            isTCAccepted={isTCAccepted}
            handleClose={toggle}
            onAcceptTC={_onAcceptTC}
            modalTitle={'Terms & Conditions'}
            modalContent={<TermsAndConditions />}
            showFooter={true}
            fullscreen={true}
          />
          <div className="text-center mb-4">
            <CustomButton
              disabled={isFormValid}
              label="Sign up"
              className={`font-poppins custom-button-default ${
                signInStyle['width-50']
              } ${isFormValid ? 'disabled' : ''}`}
              type="submit"
            />
            {successMessage !== '' && (
              <p className="m-0 fw-500 font-poppins mt-4 mb-3 text-color-accent">
                {successMessage}
              </p>
            )}
          </div>
          <div className="text-center pt-2">
            <p className="font-poppins font-18 fw-500 text-color-primary">
              <span>
                <Link
                  to="/signin"
                  className="font-poppins font-18 fw-500 text-color-accent"
                >
                  Already have an account? SIGN IN
                </Link>
              </span>
            </p>
          </div>
        </Form>
      </div>
      <div className={styles['dashboard-footer']}>
        <p className="font-13 font-poppins .text-color-tertiary m-0">
          All Rights Reserved © {new Date().getFullYear()} Bounceback LLC
          <span className="text-color-secondary font-weight-bolder mx-2">
            |
          </span>{' '}
          <a
            className={`font-16 font-poppins text-color-tertiary text-decoration-none`}
            target="_blank"
            href="https://www.mybounceback.com/privacy/"
          >
            Privacy Policy
          </a>
        </p>

        <p className="font-16 font-poppins text-color-tertiary m-0">
          Need Help?{' '}
          <span className="text-color-secondary font-weight-bolder mx-2">
            |
          </span>{' '}
          <a
            className={`font-16 font-poppins text-color-tertiary text-decoration-none`}
            href="mailto:support@mybounceback.com"
          >
            support@mybounceback.com
          </a>
        </p>
      </div>
    </>
  );
};

export default SignUpForm;
