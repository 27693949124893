import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import appStyle from '../../styles/App.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import CustomModal from '../CustomModal';
import {
  updateUserRegistrationCode,
  UserModel,
} from '../../context/authContext/authActions';
import { useAuthContext } from '../../context/authContext';
import Logo from '../Logo';
import CustomButton from '../CustomButton';
import TermsAndConditions from '../TermsAndConditions';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import AlertBounceDismissible from '../AlertBounceDismissible';
import CustomSpinner from '../CustomSpinner';
import { SIGNUP_WITH_TOKEN_SUCCESS } from '../../constants/actionTypes';
import styles from '../../styles/Dashboard.module.scss';

const SignUpOauthForm = () => {
  const {
    dispatch,
    state: { error },
  } = useAuthContext();
  const history = useHistory();
  const location = useLocation();
  const oAuthUser = location.state.user;

  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const [inputs, setInputs] = useState({
    first_name: oAuthUser.first_name,
    last_name: oAuthUser.last_name,
    email: oAuthUser.email,
    tos_accepted: false,
  });

  const [isTCAccepted, setIsTCAccepted] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const _onAcceptTC = (isTermsAccepted) => {
    if (isTermsAccepted) {
      setIsTCAccepted(true);
    } else {
      setIsTCAccepted(false);
    }
  };

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (!showAlert) {
      setShowAlert(true);
    }
    // eslint-disable-next-line
  }, [error]);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSignupWithEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const { registration_code } = inputs;

    const payload = {
      user: {
        registration_code,
      },
    };

    const data = await updateUserRegistrationCode(
      dispatch,
      payload,
      oAuthUser.token
    );
    setIsLoading(false);

    if (!data?.user) return;

    const userModel = new UserModel(data?.user);
    dispatch({ type: SIGNUP_WITH_TOKEN_SUCCESS, payload: userModel });

    history.push('/dashboard');
  };

  const onCloseAlert = () => {
    setShowAlert((prevShowAlert) => !prevShowAlert);
  };

  const Errors = () => {
    let output = '';
    if (typeof error === 'object') {
      for (const key in error) {
        if (Object.hasOwnProperty.call(error, key)) {
          const sError = error[key];
          output += `${sError}\n`;
        }
      }
    } else {
      output = error || 'Unexpected error';
    }
    return (
      <AlertBounceDismissible
        cls={`alert-bounce alert-bounce-warning`}
        onCloseAlert={onCloseAlert}
        showAlert={showAlert}
        content={output}
        icon={faExclamationTriangle}
      />
    );
  };

  const isFormValid =
    !inputs.email || !inputs.first_name || !inputs.last_name || !isTCAccepted;
  return (
    <>
      <div className={appStyle['form-wrapper']}>
        <div className={appStyle['form-header-container']}>
          <Logo />
          <div className={appStyle['form-header-title']}>Let’s get started</div>
          <p className={`text-info`}>Fill registration code to continue*</p>
        </div>
        {error && <Errors />}

        <Form onSubmit={handleSignupWithEmail}>
          <div className={appStyle['b-form-body']}>
            <div className={appStyle['form-names-container']}>
              <Form.Group
                className={`${appStyle['custom-form-group']} bg-light`}
              >
                <div className={appStyle['form-custom-label']}>First Name</div>
                <Form.Control
                  onChange={() => {}}
                  disabled={true}
                  type="text"
                  placeholder="First Name"
                  value={inputs['first_name']}
                  required
                  name="first_name"
                />
              </Form.Group>

              <Form.Group
                className={`${appStyle['custom-form-group']} bg-light`}
              >
                <div className={appStyle['form-custom-label']}>Last Name</div>
                <Form.Control
                  onChange={() => {}}
                  disabled={true}
                  type="text"
                  placeholder="Last Name"
                  required
                  name="last_name"
                  value={inputs['last_name']}
                />
              </Form.Group>
            </div>
            <Form.Group className={`${appStyle['custom-form-group']} bg-light`}>
              <div className={appStyle['form-custom-label']}>Email Address</div>
              <Form.Control
                onChange={() => {}}
                disabled={true}
                type="email"
                placeholder="Email"
                required
                name="email"
                value={inputs['email']}
                pattern={'[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$'}
              />
            </Form.Group>
            <Form.Group className={appStyle['custom-form-group']}>
              <div className={appStyle['form-custom-label']}>
                Registration Code
              </div>
              <Form.Control
                type="text"
                placeholder="Your registration code"
                required
                value={inputs['registration_code']}
                onChange={onChangeHandler}
                name="registration_code"
              />
            </Form.Group>
          </div>

          <div className={appStyle['terms-wrapper']}>
            <Form.Check
              id={`terms`}
              type="checkbox"
              required
              checked={isTCAccepted}
              onChange={() =>
                setIsTCAccepted((isTermsAccepted) => !isTermsAccepted)
              }
            />
            <label
              htmlFor={`terms`}
              className={`mb-0 ` + appStyle['terms-and-conditions']}
            >
              I agree with the{' '}
              <span onClick={toggle}>
                <button className={appStyle['btn-custom-link-blue']}>
                  Terms & Conditions
                </button>
              </span>
            </label>
          </div>
          {isLoading && (
            <div className={`my-2`}>
              <CustomSpinner />
            </div>
          )}
          <CustomModal
            isOpen={modal}
            isTCAccepted={isTCAccepted}
            handleClose={toggle}
            onAcceptTC={_onAcceptTC}
            modalTitle={'Terms & Conditions'}
            modalContent={<TermsAndConditions />}
            showFooter={true}
            fullscreen={true}
          />
          <CustomButton
            disabled={isFormValid}
            label="Sign up"
            className={`custom-button-default ${isFormValid ? 'disabled' : ''}`}
            type="submit"
          />
        </Form>
      </div>
      <div className={styles['dashboard-footer']}>
        <p className="font-13 font-poppins .text-color-tertiary m-0">
          All Rights Reserved © {new Date().getFullYear()} Bounceback LLC
          <span className="text-color-secondary font-weight-bolder mx-2">
            |
          </span>{' '}
          <a
            className={`font-16 font-poppins text-color-tertiary text-decoration-none`}
            href="/privacy-policy"
          >
            target="_blank" href="https://www.mybounceback.com/privacy/"
          </a>
        </p>

        <p className="font-16 font-poppins text-color-tertiary m-0">
          Need Help?{' '}
          <span className="text-color-secondary font-weight-bolder mx-2">
            |
          </span>{' '}
          <a
            className={`font-16 font-poppins text-color-tertiary text-decoration-none`}
            href="mailto:support@mybounceback.com"
          >
            support@mybounceback.com
          </a>
        </p>
      </div>
    </>
  );
};

export default SignUpOauthForm;
