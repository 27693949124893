import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import CustomSpinner from './CustomSpinner';
import CustomButton from './CustomButton';
import ReactWizard from 'react-bootstrap-wizard';

import '../App.scss';
import { useAuthContext } from '../context/authContext';
import CheckedMarkIcon from '../assets/icons/checked-mark.svg';
import UnCheckedMarkIcon from '../assets/icons/unchecked-mark.svg';
import AlertModalScore from './AlertModalScore';
import KnowledgeCheck from '../reducers/knowledgeCheck/model';
import {
  fetchKnowledgeCheck,
  submitKnowledgeCheck,
} from '../redux/actions/knowledgeCheckActions';
import { useDispatch, useSelector } from 'react-redux';
import { updateModuleSectionScore } from '../redux/actions/sectionActions';
import {
  BASE_API_URL,
  SCREENER_END,
  SCREENER_START,
  ACTIVITY_SCREENER_BASELINE,
} from '../constants/constants';
import { requestPostApi } from '../services/api/api';

const capitalizeFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const screenerBaseline = parseInt(ACTIVITY_SCREENER_BASELINE);

const RadioList = ({
  text,
  options,
  index,
  multiple,
  answers = [],
  pending,
  saveAnswers,
  setAnswers,
  knowledgeCheckID,
}) => {
  const [localOptions] = useState(options || []);

  const onSave = (answers, item) => {
    const resUpdate = answers.find(
      (element) =>
        element.knowledge_check_question_id === item.knowledge_check_question_id
    );
    if (resUpdate) {
      const updateAnswers = answers.map((ans) => {
        if (
          ans.knowledge_check_question_id === item.knowledge_check_question_id
        ) {
          ans = { ...item, ...{ kid: knowledgeCheckID } };
        }
        return ans;
      });
      setAnswers([...updateAnswers]);
      saveAnswers([...updateAnswers]);
      // return [...updateAnswers]
    } else {
      setAnswers([...answers, { ...item, ...{ kid: knowledgeCheckID } }]);
      saveAnswers([...answers, { ...item, ...{ kid: knowledgeCheckID } }]);
      // return [...answers, item]
    }
  };

  const onSaveCheckBox = (answers, item) => {
    const resUpdate = answers.find((element) => element.id === item.id);
    if (resUpdate) {
      const updateAnswers = answers.filter((element) => element.id !== item.id);
      setAnswers([...updateAnswers]);
      saveAnswers([...updateAnswers]);
      // return [...updateAnswers]
    } else {
      setAnswers([...answers, { ...item, ...{ kid: knowledgeCheckID } }]);
      saveAnswers([...answers, { ...item, ...{ kid: knowledgeCheckID } }]);
      // return [...answers, item]
    }
  };

  const isChecked = (item) => {
    let checked = answers.find((element) => element.id === item.id);
    return !!checked;
  };

  return (
    <Container>
      {localOptions.length > 0 &&
        typeof localOptions[0].score === 'undefined' ? (
        <Row>
          <Col sm className="custom-label-question-number">
            Question {index + 1}
          </Col>
        </Row>
      ) : (
        ''
      )}

      <Row>
        <Col
          sm
          className={
            localOptions.length > 0 &&
              typeof localOptions[0].score === 'undefined'
              ? 'custom-label-question'
              : 'custom-label-question-screener text-color-gray font-poppins'
          }
        >
          {text}
        </Col>
      </Row>
      <Row>
        {localOptions.map((item, idx) => (
          <Col
            className={
              typeof item.score !== 'undefined' ? 'screener-question' : ''
            }
          >
            <div key={`${item.name.replace(/\s/g, '')}-${idx * 10}`}>
              <div className="custom-question-container">
                <div
                  className="radio"
                  onClick={() =>
                    setAnswers((answers) =>
                      multiple
                        ? onSaveCheckBox(answers, item)
                        : onSave(answers, item)
                    )
                  }
                >
                  <label
                    id={`inline-check-${item.name.replace(/\s/g, '')}`}
                    className="custom-label-description text-color-gray font-poppins"
                  >
                    {item.name}
                  </label>
                  <div className="custom-label-button-radio">
                    <input
                      id={`inline-check-${item.name.replace(/\s/g, '')}`}
                      name={`${item.knowledge_check_question_id}`}
                      type={multiple ? 'checkbox' : 'radio'}
                      value={
                        typeof item.score !== 'undefined'
                          ? item.score
                          : item.name.replace(/\s/g, '')
                      }
                      disabled={!pending}
                      readOnly={!pending}
                      checked={isChecked(item)}
                      onChange={() => {
                        console.log('Selected score:', item.score);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const KnowledgeCheckList = ({ content, goBack, section, module }) => {
  const dispatch = useDispatch();

  const {
    state: { user },
  } = useAuthContext();
  const [submittingKCID, setSubmittingKCID] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [modal, setModal] = useState(false);
  const [completeKC, setCompleteKC] = useState(false);
  const [knowledgeChecks, setKnowledgeChecks] = useState([]);
  const [screenerScoreText, setScreenerScoreText] = useState(null);
  const [customLanguages, setCustomLanguages] = useState(new Map());

  const onErrorClose = () => {
    setMessage('');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  const screenersScoreModel = {
    stress: {
      id: screenerBaseline,
      calculateByGroups: false,
      answersScores: [
        {
          id: 1,
          label: 'Never',
          score: 0,
        },
        {
          id: 2,
          label: 'Almost Never',
          score: 1,
        },
        {
          id: 3,
          label: ' Sometimes',
          score: 2,
        },
        {
          id: 4,
          label: 'Fairly Often',
          score: 3,
        },
        {
          id: 5,
          label: 'Very Often',
          score: 4,
        },
      ],
      scoreCategories: [
        {
          id: 1,
          label: (
            <>
              <p>
                <center>Your answers indicate:</center>
              </p>
              <p>
                <strong>LOW stress</strong>. Keep up the great work! Explore
                stress-management strategies in this module.
              </p>
            </>
          ),
          literalLabel: 'Low Stress',
          min: 0,
          max: 13,
        },
        {
          id: 2,
          label: (
            <>
              <p>
                <center>Your answers indicate:</center>
              </p>
              <p>
                <strong>MODERATE</strong> stress. Take it easy and explore
                stress-management strategies in this module.
              </p>
            </>
          ),
          literalLabel: 'Moderate Stress',
          min: 14,
          max: 26,
        },
        {
          id: 3,
          label: (
            <>
              <p>
                <center>Your answers indicate:</center>
              </p>
              <p>
                <strong>HIGH</strong> stress. Slow down, take care of yourself,
                and consult with a healthcare professional.
              </p>
            </>
          ),
          literalLabel: 'High Stress',
          min: 27,
          max: 40,
        },
      ],
    },
    mindfulness: {
      id: screenerBaseline + 1,
      calculateByGroups: false,
      answersScores: [
        {
          id: 1,
          label: 'Rarely/Not at All',
          score: 1,
        },
        {
          id: 2,
          label: 'Sometimes',
          score: 2,
        },
        {
          id: 3,
          label: 'Often',
          score: 3,
        },
        {
          id: 4,
          label: 'Almost Always',
          score: 4,
        },
      ],
      scoreCategories: [
        {
          id: 1,
          label: (
            <>
              <p>
                <center>Your answers indicate:</center>
              </p>
              <p>
                <strong>LOW</strong> level of mindfulness. Slow down, take a
                deep breath, and explore mindful solutions in this module.
              </p>
            </>
          ),
          literalLabel: 'Low Mindfulness',
          min: 12,
          max: 23,
        },
        {
          id: 2,
          label: (
            <>
              <p>
                <center>Your answers indicate:</center>
              </p>
              <p>
                <strong>MODERATE</strong> level of mindfulness. Slow down and
                explore mindful solutions in this module.
              </p>
            </>
          ),
          literalLabel: 'Moderate Mindfulness',
          min: 24,
          max: 35,
        },
        {
          id: 3,
          label: (
            <>
              <p>
                <center>Your answers indicate:</center>
              </p>
              <p>
                <strong>HIGH</strong> level of mindfulness. What’s your secret?
                Compare notes with colleagues and keep up the great work!
              </p>
            </>
          ),
          literalLabel: 'High Mindfulness',
          min: 36,
          max: 48,
        },
      ],
    },
    anxiety: {
      id: screenerBaseline + 2,
      calculateByGroups: false,
      answersScores: [
        {
          id: 1,
          label: 'Not at All',
          score: 0,
        },
        {
          id: 2,
          label: 'Several Days',
          score: 1,
        },
        {
          id: 3,
          label: 'More than Half the Days',
          score: 2,
        },
        {
          id: 4,
          label: 'Nearly Every Day',
          score: 3,
        },
      ],
      scoreCategories: [
        {
          id: 1,
          label: (
            <>
              <p>
                <center>Your answers indicate:</center>
              </p>
              <p>
                <strong>MINIMAL</strong> anxiety. Keep up the great work!
                Explore focused-breathing strategies in this module.
              </p>
            </>
          ),
          literalLabel: 'Minimal Anxiety',
          min: 0,
          max: 4,
        },
        {
          id: 2,
          label: (
            <>
              <p>
                <center>Your answers indicate:</center>
              </p>
              <p>
                <strong>MILD</strong> anxiety. Take it easy and explore
                focused-breathing strategies in this module.
              </p>
            </>
          ),
          literalLabel: 'Mild Anxiety',
          min: 5,
          max: 9,
        },
        {
          id: 3,
          label: (
            <>
              <p>
                <center>Your answers indicate:</center>
              </p>
              <p>
                <strong>MODERATE</strong> anxiety. Slow down and explore
                focused-breathing strategies in this module.
              </p>
            </>
          ),
          literalLabel: 'Moderate Anxiety',
          min: 10,
          max: 14,
        },
        {
          id: 4,
          label: (
            <>
              <p>
                <center>Your answers indicate:</center>
              </p>
              <p>
                <strong>SEVERE</strong> anxiety. Slow
                down, take care of yourself, and consult with a healthcare
                professional.
              </p>
            </>
          ),
          literalLabel: 'Severe Anxiety',
          min: 15,
          max: 21,
        },
      ],
    },
    activity: {
      id: screenerBaseline + 3,
      calculateByGroups: true,
      answersScores: [
        {
          id: 1,
          label: 'Very Likely',
          score: 3,
        },
        {
          id: 2,
          label: 'Somewhat Likely',
          score: 2,
        },
        {
          id: 3,
          label: 'Somewhat Unlikely',
          score: 1,
        },
        {
          id: 4,
          label: 'Very Unlikely',
          score: 0,
        },
      ],
      scoreCategories: [
        {
          id: 1,
          label: (
            <>
              <p>
                <strong>LACK OF TIME</strong> is a barrier to being active.
                Think about simple activities you might be able to work into
                your daily schedule — like walking or stair-climbing.
              </p>
            </>
          ),
          literalLabel: 'LACK OF TIME',
          min: 1,
          max: 1,
          group: [1, 5, 9],
        },
        {
          id: 2,
          label: (
            <>
              <p>
                <strong>SOCIAL INFLUENCE</strong> is a barrier to being active.
                Invite your friends or family members to exercise with you.
              </p>
            </>
          ),
          literalLabel: 'SOCIAL INFLUENCE',
          min: 2,
          max: 2,
          group: [2, 6, 10],
        },
        {
          id: 3,
          label: (
            <>
              <p>
                <strong>LACK OF ENERGY</strong> is a barrier to being active.
                Engage in physical activity during times in the day when you
                have the most energy.
              </p>
            </>
          ),
          literalLabel: 'LACK OF ENERGY',
          min: 3,
          max: 3,
          group: [3, 7, 11],
        },
        {
          id: 4,
          label: (
            <>
              <p>
                <strong>LACK OF WILLPOWER</strong> is a barrier to being active.
                Make physical activity part of your weekly schedule — and put it
                on your calendar.
              </p>
            </>
          ),
          literalLabel: 'LACK OF WILLPOWER',
          min: 4,
          max: 4,
          group: [4, 8, 12],
        },
      ],
    },
    sel_check_in: {
      id: screenerBaseline + 8,
      calculateByGroups: false,
      answersScores: [
        {
          id: 1,
          label: 'Strongly Disagree',
          score: 1,
        },
        {
          id: 2,
          label: 'Disagree',
          score: 2,
        },
        {
          id: 3,
          label: 'Agree',
          score: 3,
        },
        {
          id: 4,
          label: 'Strongly Agree',
          score: 4,
        },
      ],
      scoreCategories: [
        {
          id: 1,
          label: (
            <>
              <p>
                <center>Your answers indicate:</center>
              </p>
              <p>
                <strong>LOW</strong> level of social and emotional awareness.
                Slow down, reflect on your answers, and explore the content in this module.
              </p>
            </>
          ),
          literalLabel: 'Low',
          min: 0,
          max: 29,
        },
        {
          id: 2,
          label: (
            <>
              <p>
                <center>Your answers indicate:</center>
              </p>
              <p>
                <strong>MODERATE</strong> level of social and emotional awareness.
                You’re on the right track. Reflect on your answers and explore the content in this module.
              </p>
            </>
          ),
          literalLabel: 'Moderate',
          min: 30,
          max: 45,
        },
        {
          id: 3,
          label: (
            <>
              <p>
                <center>Your answers indicate:</center>
              </p>
              <p>
                <strong>HIGH</strong> level of social and emotional awareness.
                What’s your secret? Compare notes with colleagues and keep up the great work!
              </p>
            </>
          ),
          literalLabel: 'High',
          min: 46,
          max: 60,
        },
      ],
    },
    sel_skills: {
      id: screenerBaseline + 9,
      calculateByGroups: false,
      answersScores: [
        {
          id: 1,
          label: 'Very Difficult',
          score: 1,
        },
        {
          id: 2,
          label: 'Difficult',
          score: 2,
        },
        {
          id: 3,
          label: 'Easy',
          score: 3,
        },
        {
          id: 4,
          label: 'Very Easy',
          score: 4,
        },
      ],
      scoreCategories: [
        {
          id: 1,
          label: (<span>&nbsp;<strong>Self-Awareness</strong>,&nbsp;</span>),
          literalLabel: 'Self-Awareness',
          min: 1,
          max: 1,
          group: [0, 1, 2, 3, 4, 5, 6, 7],
        },
        {
          id: 2,
          label: (<span>&nbsp;<strong>Self-Management</strong>,&nbsp;</span>),
          literalLabel: 'Self-Management',
          min: 2,
          max: 2,
          group: [8, 9, 10, 11, 12, 13, 14, 15],
        },
        {
          id: 3,
          label: (<span>&nbsp;<strong>Social Awareness</strong>,&nbsp;</span>),
          literalLabel: 'Social Awareness',
          min: 3,
          max: 3,
          group: [16, 17, 18, 19, 20, 21, 22, 23],
        },
        {
          id: 4,
          label: (<span>&nbsp;<strong>Relationship Skills</strong>,&nbsp;</span>),
          literalLabel: 'Relationship Skills',
          min: 4,
          max: 4,
          group: [24, 25, 26, 27, 28, 29, 30, 31],
        },
        {
          id: 5,
          label: (<span>&nbsp;<strong>Responsible Decision-Making</strong>,&nbsp;</span>),
          literalLabel: 'Responsible Decision-Making',
          min: 5,
          max: 5,
          group: [32, 33, 34, 35, 36, 37, 38, 39],
        },
        {
          id: 6,
          label: (<span>&nbsp;<strong>Self-Awareness</strong>,&nbsp;</span>),
          literalLabel: 'Self-Awareness',
          min: 6,
          max: 6,
          group: [0, 1, 2, 3, 4, 5, 6, 7],
        },
        {
          id: 7,
          label: (<span>&nbsp;<strong>Self-Management</strong>,&nbsp;</span>),
          literalLabel: 'Self-Management',
          min: 7,
          max: 7,
          group: [8, 9, 10, 11, 12, 13, 14, 15],
        },
        {
          id: 8,
          label: (<span>&nbsp;<strong>Social Awareness</strong>,&nbsp;</span>),
          literalLabel: 'Social Awareness',
          min: 8,
          max: 8,
          group: [16, 17, 18, 19, 20, 21, 22, 23],
        },
        {
          id: 9,
          label: (<span>&nbsp;<strong>Relationship Skills</strong>,&nbsp;</span>),
          literalLabel: 'Relationship Skills',
          min: 9,
          max: 9,
          group: [24, 25, 26, 27, 28, 29, 30, 31],
        },
        {
          id: 10,
          label: (<span>&nbsp;<strong>Responsible Decision-Making</strong>,&nbsp;</span>),
          literalLabel: 'Responsible Decision-Making',
          min: 10,
          max: 10,
          group: [32, 33, 34, 35, 36, 37, 38, 39],
        },
        {
          id: 11,
          label: (<span>&nbsp;<strong>Self-Awareness</strong>,&nbsp;</span>),
          literalLabel: 'Self-Awareness',
          min: 11,
          max: 11,
          group: [0, 1, 2, 3, 4, 5, 6, 7],
        },
        {
          id: 12,
          label: (<span>&nbsp;<strong>Self-Management</strong>,&nbsp;</span>),
          literalLabel: 'Self-Management',
          min: 12,
          max: 12,
          group: [8, 9, 10, 11, 12, 13, 14, 15],
        },
        {
          id: 13,
          label: (<span>&nbsp;<strong>Social Awareness</strong>,&nbsp;</span>),
          literalLabel: 'Social Awareness',
          min: 13,
          max: 13,
          group: [16, 17, 18, 19, 20, 21, 22, 23],
        },
        {
          id: 14,
          label: (<span>&nbsp;<strong>Relationship Skills</strong>,&nbsp;</span>),
          literalLabel: 'Relationship Skills',
          min: 14,
          max: 14,
          group: [24, 25, 26, 27, 28, 29, 30, 31],
        },
        {
          id: 15,
          label: (
            <span><strong>Responsible Decision-Making</strong>,&nbsp;</span>
          ),
          literalLabel: 'Responsible Decision-Making',
          min: 15,
          max: 15,
          group: [32, 33, 34, 35, 36, 37, 38, 39],
        },
      ],
    },
  };

  const { score, loading, fetchLoading, knowledgeCheckAnswers, screenerDone } =
    useSelector((state) => state.knowledgeCheck);

  const [message, setMessage] = useState('');
  const toggle = () => {
    setModal(!modal);
  };

  const submitScreenerResult = async (screenerResult) => {
    await requestPostApi(`${BASE_API_URL}/screener_results`, screenerResult);
  };

  useEffect(() => {
    let selectedModel = 0;
    if (knowledgeChecks.length === 0 || customLanguages.length > 0) {
      return;
    }
    const stressModel = screenerKcMin;
    const mindfulnessModel = screenerKcMin + 1;
    const anxietyModel = screenerKcMin + 2;
    const activityModel = screenerKcMin + 3;
    const selCheckInModel = screenerKcMax;
    switch (knowledgeChecks[0].id) {
      case stressModel:
        selectedModel = 1;
        break;
      case mindfulnessModel:
        selectedModel = 2;
        break;
      case anxietyModel:
        selectedModel = 3;
        break;
      case activityModel:
        selectedModel = 4;
        break;
      case selCheckInModel:
        selectedModel = 5;
        break;
    }

    if (selectedModel == 0) {
      return;
    }

    fetch(
      `${BASE_API_URL}/screener_client_customizations?screener_type=${selectedModel}&client_id=${user.schools[0].id}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const screenerLanguagesMap = new Map();
        for (const row of data.screener_client_customizations) {
          let levelNum = 1;
          switch (row.screener_level) {
            case 'low':
              levelNum = 1;
              break;
            case 'medium':
              levelNum = 2;
              break;
            case 'high':
              levelNum = 3;
              break;
            case 'severe':
              levelNum = 4;
              break;
          }
          screenerLanguagesMap.set(levelNum, row);
        }
        setCustomLanguages(screenerLanguagesMap);
      })
      .catch((e) => console.error('Screeners Custom Language Error', e));
  }, [knowledgeChecks]);

  useEffect(() => {
    // console.log("TOGGLE EFFECT", knowledgeCheckAnswers, screenerDone);
    if (
      (knowledgeCheckAnswers.length > 0 || screenerDone === true) &&
      completeKC
    ) {
      if (answers.length > 0 && screenerDone === true) {
        const smodel = answers[0].screenerModel;
        console.log('Model: ', smodel);
        setScreenerScoreText(
          <>
            <strong>Your score doesn't correspond to any category.</strong>
          </>
        );
        let finalScore = 0;
        let qaArr = new Map();
        let scoreCatArr = new Map();
        let qaCont = 1;
        let catAnsCount = smodel == 'activity' ? 1 : 0;
        for (let ans of answers) {
          if (ans) {
            if (smodel == 'activity' || smodel == 'sel_skills') {
              for (let category of screenersScoreModel[smodel]
                .scoreCategories) {
                if (category.group && category.group.includes(catAnsCount)) {
                  scoreCatArr.set(
                    category.id,
                    scoreCatArr.has(category.id)
                      ? scoreCatArr.get(category.id) + ans.score
                      : ans.score
                  );
                }
              }
              catAnsCount += 1;
              qaArr.set(qaCont, ans.score);
              qaCont += 1;
            } else {
              finalScore += ans.score;
              qaArr.set(qaCont, ans.score);
              qaCont += 1;
            }
          }
        }
        if (smodel == 'activity' || smodel == 'sel_skills') {
          for (let category of screenersScoreModel[smodel].scoreCategories) {
            if (
              scoreCatArr.has(category.id) &&
              scoreCatArr.get(category.id) >= 5
            ) {
              finalScore = category.id;
              break;
            }
          }
        }
        for (let category of screenersScoreModel[smodel].scoreCategories) {
          console.log('Category: ', category);
          if (finalScore >= category.min && finalScore <= category.max) {
            // Submit Screener Result
            submitScreenerResult({
              screener_result: {
                attempt_id: parseInt(new Date().getTime() / 1000),
                attempt_hash: user.email,
                attempt_date: new Date().toISOString(),
                client_id: user.schools[0].id,
                client_name: user.schools[0].name,
                screener_id: screenersScoreModel[smodel].id,
                screener_name: `${capitalizeFirst(smodel)} Screener`,
                score:
                  smodel == 'activity'
                    ? scoreCatArr.get(finalScore)
                    : finalScore,
                score_label: category.literalLabel,
                qa_1: qaArr.get(1),
                qa_2: qaArr.get(2),
                qa_3: qaArr.get(3),
                qa_4: qaArr.get(4),
                qa_5: qaArr.get(5),
                qa_6: qaArr.get(6),
                qa_7: qaArr.get(7),
                qa_8: qaArr.get(8),
                qa_9: qaArr.get(9),
                qa_10: qaArr.get(10),
                qa_11: qaArr.get(11),
                qa_12: qaArr.get(12),
                qa_13: qaArr.get(13),
                qa_14: qaArr.get(14),
                qa_15: qaArr.get(15),
                qa_16: qaArr.get(16),
                qa_17: qaArr.get(17),
                qa_18: qaArr.get(18),
                qa_19: qaArr.get(19),
                qa_20: qaArr.get(20),
                qa_21: qaArr.get(21),
                qa_22: qaArr.get(22),
                qa_23: qaArr.get(23),
                qa_24: qaArr.get(24),
                qa_25: qaArr.get(25),
                qa_26: qaArr.get(26),
                qa_27: qaArr.get(27),
                qa_28: qaArr.get(28),
                qa_29: qaArr.get(29),
                qa_30: qaArr.get(30),
                qa_31: qaArr.get(31),
                qa_32: qaArr.get(32),
                qa_33: qaArr.get(33),
                qa_34: qaArr.get(34),
                qa_35: qaArr.get(35),
                qa_36: qaArr.get(36),
                qa_37: qaArr.get(37),
                qa_38: qaArr.get(38),
                qa_39: qaArr.get(39),
                qa_40: qaArr.get(40),
                score_cat1: scoreCatArr.get(1),
                score_cat2: scoreCatArr.get(2),
                score_cat3: scoreCatArr.get(3),
                score_cat4: scoreCatArr.get(4),
                score_cat5: scoreCatArr.get(5),
                score_cat6: scoreCatArr.get(6),
                score_cat7: scoreCatArr.get(7),
                score_cat8: scoreCatArr.get(8),
                score_cat9: scoreCatArr.get(9),
                score_cat10: scoreCatArr.get(10),
                score_cat11: scoreCatArr.get(11),
                score_cat12: scoreCatArr.get(12),
                score_cat13: scoreCatArr.get(13),
                score_cat14: scoreCatArr.get(14),
                score_cat15: scoreCatArr.get(15),
              },
            });

            if (customLanguages.has(category.id)) {
              if (smodel == 'activity') {
                setScreenerScoreText(
                  <>
                    <p>
                      <center>Your answers indicate: </center>
                    </p>
                    {scoreCatArr.has(1) && scoreCatArr.get(1) >= 5 && (
                      <p dangerouslySetInnerHTML={{ __html: customLanguages.get(1).text }}></p>
                    )}
                    {scoreCatArr.has(2) && scoreCatArr.get(2) >= 5 && (
                      <p dangerouslySetInnerHTML={{ __html: customLanguages.get(2).text }}></p>
                    )}
                    {scoreCatArr.has(3) && scoreCatArr.get(3) >= 5 && (
                      <p dangerouslySetInnerHTML={{ __html: customLanguages.get(3).text }}></p>
                    )}
                    {scoreCatArr.has(4) && scoreCatArr.get(4) >= 5 && (
                      <p dangerouslySetInnerHTML={{ __html: customLanguages.get(4).text }}></p>
                    )}
                  </>
                );
              } else {
                setScreenerScoreText(
                  <>
                    <strong>{category.literalLabel}</strong>
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: customLanguages.get(category.id).text }}></span>
                  </>
                );
              }
            } else {
              if (smodel == 'activity') {
                setScreenerScoreText(
                  <>
                    <p>
                      <center>Your answers indicate: </center>
                    </p>
                    {scoreCatArr.has(1) &&
                      scoreCatArr.get(1) >= 5 &&
                      screenersScoreModel[smodel].scoreCategories[0].label}
                    {scoreCatArr.has(2) &&
                      scoreCatArr.get(2) >= 5 &&
                      screenersScoreModel[smodel].scoreCategories[1].label}
                    {scoreCatArr.has(3) &&
                      scoreCatArr.get(3) >= 5 &&
                      screenersScoreModel[smodel].scoreCategories[2].label}
                    {scoreCatArr.has(4) &&
                      scoreCatArr.get(4) >= 5 &&
                      screenersScoreModel[smodel].scoreCategories[3].label}
                  </>
                );
              } else if (smodel == 'sel_skills') {
                setScreenerScoreText(
                  <>
                    {((scoreCatArr.has(1) && scoreCatArr.get(1) >= 1 && scoreCatArr.get(1) <= 15)
                      || (scoreCatArr.has(2) && scoreCatArr.get(2) >= 1 && scoreCatArr.get(2) <= 15)
                      || (scoreCatArr.has(3) && scoreCatArr.get(3) >= 1 && scoreCatArr.get(3) <= 15)
                      || (scoreCatArr.has(4) && scoreCatArr.get(4) >= 1 && scoreCatArr.get(4) <= 15)
                      || (scoreCatArr.has(5) && scoreCatArr.get(5) >= 1 && scoreCatArr.get(5) <= 15)) &&
                      <span>
                        Your answers indicate that you need to work on:
                      </span>}
                    {scoreCatArr.has(1) &&
                      scoreCatArr.get(1) >= 1 && scoreCatArr.get(1) <= 15 &&
                      screenersScoreModel[smodel].scoreCategories[0].label}
                    {scoreCatArr.has(2) &&
                      scoreCatArr.get(2) >= 1 && scoreCatArr.get(2) <= 15 &&
                      screenersScoreModel[smodel].scoreCategories[1].label}
                    {scoreCatArr.has(3) &&
                      scoreCatArr.get(3) >= 1 && scoreCatArr.get(3) <= 15 &&
                      screenersScoreModel[smodel].scoreCategories[2].label}
                    {scoreCatArr.has(4) &&
                      scoreCatArr.get(4) >= 1 && scoreCatArr.get(4) <= 15 &&
                      screenersScoreModel[smodel].scoreCategories[3].label}
                    {scoreCatArr.has(5) &&
                      scoreCatArr.get(5) >= 1 && scoreCatArr.get(5) <= 15 &&
                      screenersScoreModel[smodel].scoreCategories[4].label}
                    {((scoreCatArr.has(1) && scoreCatArr.get(1) >= 1 && scoreCatArr.get(1) <= 15)
                      || (scoreCatArr.has(2) && scoreCatArr.get(2) >= 1 && scoreCatArr.get(2) <= 15)
                      || (scoreCatArr.has(3) && scoreCatArr.get(3) >= 1 && scoreCatArr.get(3) <= 15)
                      || (scoreCatArr.has(4) && scoreCatArr.get(4) >= 1 && scoreCatArr.get(4) <= 15)
                      || (scoreCatArr.has(5) && scoreCatArr.get(5) >= 1 && scoreCatArr.get(5) <= 15)) &&
                      <span>
                        slow down. Take it easy on yourself, and explore the content in this module.<br /><br />
                      </span>}
                    {((scoreCatArr.has(6) && scoreCatArr.get(6) >= 16 && scoreCatArr.get(6) <= 24)
                      || (scoreCatArr.has(7) && scoreCatArr.get(7) >= 16 && scoreCatArr.get(7) <= 24)
                      || (scoreCatArr.has(8) && scoreCatArr.get(8) >= 16 && scoreCatArr.get(8) <= 24)
                      || (scoreCatArr.has(9) && scoreCatArr.get(9) >= 16 && scoreCatArr.get(9) <= 24)
                      || (scoreCatArr.has(10) && scoreCatArr.get(10) >= 16 && scoreCatArr.get(10) <= 24)) &&
                      <span>
                        Your answers indicate that you’re doing OK with:
                      </span>}
                    {scoreCatArr.has(6) &&
                      scoreCatArr.get(6) >= 16 && scoreCatArr.get(6) <= 24 &&
                      screenersScoreModel[smodel].scoreCategories[5].label}
                    {scoreCatArr.has(7) &&
                      scoreCatArr.get(7) >= 16 && scoreCatArr.get(7) <= 24 &&
                      screenersScoreModel[smodel].scoreCategories[6].label}
                    {scoreCatArr.has(8) &&
                      scoreCatArr.get(8) >= 16 && scoreCatArr.get(8) <= 24 &&
                      screenersScoreModel[smodel].scoreCategories[7].label}
                    {scoreCatArr.has(9) &&
                      scoreCatArr.get(9) >= 16 && scoreCatArr.get(9) <= 24 &&
                      screenersScoreModel[smodel].scoreCategories[8].label}
                    {scoreCatArr.has(10) &&
                      scoreCatArr.get(10) >= 16 && scoreCatArr.get(10) <= 24 &&
                      screenersScoreModel[smodel].scoreCategories[9].label}
                    {((scoreCatArr.has(6) && scoreCatArr.get(6) >= 16 && scoreCatArr.get(6) <= 24)
                      || (scoreCatArr.has(7) && scoreCatArr.get(7) >= 16 && scoreCatArr.get(7) <= 24)
                      || (scoreCatArr.has(8) && scoreCatArr.get(8) >= 16 && scoreCatArr.get(8) <= 24)
                      || (scoreCatArr.has(9) && scoreCatArr.get(9) >= 16 && scoreCatArr.get(9) <= 24)
                      || (scoreCatArr.has(10) && scoreCatArr.get(10) >= 16 && scoreCatArr.get(10) <= 24)) &&
                      <span>
                        you’re on the right track. Reflect on your answers
                        and think about opportunities for improvement.<br /><br />
                      </span>}
                    {((scoreCatArr.has(11) && scoreCatArr.get(11) >= 25 && scoreCatArr.get(11) <= 32)
                      || (scoreCatArr.has(12) && scoreCatArr.get(12) >= 25 && scoreCatArr.get(12) <= 32)
                      || (scoreCatArr.has(13) && scoreCatArr.get(13) >= 25 && scoreCatArr.get(13) <= 32)
                      || (scoreCatArr.has(14) && scoreCatArr.get(14) >= 25 && scoreCatArr.get(14) <= 32)
                      || (scoreCatArr.has(15) && scoreCatArr.get(15) >= 25 && scoreCatArr.get(15) <= 32)) &&
                      <span>
                        Your answers indicate that you excel at:
                      </span>}
                    {scoreCatArr.has(11) &&
                      scoreCatArr.get(11) >= 25 && scoreCatArr.get(11) <= 32 &&
                      screenersScoreModel[smodel].scoreCategories[10].label}
                    {scoreCatArr.has(12) &&
                      scoreCatArr.get(12) >= 25 && scoreCatArr.get(11) <= 32 &&
                      screenersScoreModel[smodel].scoreCategories[11].label}
                    {scoreCatArr.has(13) &&
                      scoreCatArr.get(13) >= 25 && scoreCatArr.get(11) <= 32 &&
                      screenersScoreModel[smodel].scoreCategories[12].label}
                    {scoreCatArr.has(14) &&
                      scoreCatArr.get(14) >= 25 && scoreCatArr.get(11) <= 32 &&
                      screenersScoreModel[smodel].scoreCategories[13].label}
                    {scoreCatArr.has(15) &&
                      scoreCatArr.get(15) >= 25 && scoreCatArr.get(11) <= 32 &&
                      screenersScoreModel[smodel].scoreCategories[14].label}
                    {((scoreCatArr.has(11) && scoreCatArr.get(11) >= 25 && scoreCatArr.get(11) <= 32)
                      || (scoreCatArr.has(12) && scoreCatArr.get(12) >= 25 && scoreCatArr.get(12) <= 32)
                      || (scoreCatArr.has(13) && scoreCatArr.get(13) >= 25 && scoreCatArr.get(13) <= 32)
                      || (scoreCatArr.has(14) && scoreCatArr.get(14) >= 25 && scoreCatArr.get(14) <= 32)
                      || (scoreCatArr.has(15) && scoreCatArr.get(15) >= 25 && scoreCatArr.get(15) <= 32)) &&
                      <span>
                        what’s your secret? Compare notes with colleagues and keep up the great work!<br /><br />
                      </span>}
                  </>
                );
              } else {
                setScreenerScoreText(category.label);
              }
            }
            break;
          }
        }
        if (smodel == 'activity' && finalScore == 0) {

          // Submit Screener Result
          // If activity screener and finalScore = 0 submit anymay
          submitScreenerResult({
            screener_result: {
              attempt_id: parseInt(new Date().getTime() / 1000),
              attempt_hash: user.email,
              attempt_date: new Date().toISOString(),
              client_id: user.schools[0].id,
              client_name: user.schools[0].name,
              screener_id: screenersScoreModel[smodel].id,
              screener_name: `${capitalizeFirst(smodel)} Screener`,
              score:
                smodel == 'activity'
                  ? scoreCatArr.get(finalScore)
                  : finalScore,
              score_label: 'Minimal Barriers',
              qa_1: qaArr.get(1),
              qa_2: qaArr.get(2),
              qa_3: qaArr.get(3),
              qa_4: qaArr.get(4),
              qa_5: qaArr.get(5),
              qa_6: qaArr.get(6),
              qa_7: qaArr.get(7),
              qa_8: qaArr.get(8),
              qa_9: qaArr.get(9),
              qa_10: qaArr.get(10),
              qa_11: qaArr.get(11),
              qa_12: qaArr.get(12),
              qa_13: qaArr.get(13),
              qa_14: qaArr.get(14),
              qa_15: qaArr.get(15),
              qa_16: qaArr.get(16),
              qa_17: qaArr.get(17),
              qa_18: qaArr.get(18),
              qa_19: qaArr.get(19),
              qa_20: qaArr.get(20),
              qa_21: qaArr.get(21),
              qa_22: qaArr.get(22),
              qa_23: qaArr.get(23),
              qa_24: qaArr.get(24),
              qa_25: qaArr.get(25),
              qa_26: qaArr.get(26),
              qa_27: qaArr.get(27),
              qa_28: qaArr.get(28),
              qa_29: qaArr.get(29),
              qa_30: qaArr.get(30),
              qa_31: qaArr.get(31),
              qa_32: qaArr.get(32),
              qa_33: qaArr.get(33),
              qa_34: qaArr.get(34),
              qa_35: qaArr.get(35),
              qa_36: qaArr.get(36),
              qa_37: qaArr.get(37),
              qa_38: qaArr.get(38),
              qa_39: qaArr.get(39),
              qa_40: qaArr.get(40),
              score_cat1: scoreCatArr.get(1),
              score_cat2: scoreCatArr.get(2),
              score_cat3: scoreCatArr.get(3),
              score_cat4: scoreCatArr.get(4),
              score_cat5: scoreCatArr.get(5),
              score_cat6: scoreCatArr.get(6),
              score_cat7: scoreCatArr.get(7),
              score_cat8: scoreCatArr.get(8),
              score_cat9: scoreCatArr.get(9),
              score_cat10: scoreCatArr.get(10),
              score_cat11: scoreCatArr.get(11),
              score_cat12: scoreCatArr.get(12),
              score_cat13: scoreCatArr.get(13),
              score_cat14: scoreCatArr.get(14),
              score_cat15: scoreCatArr.get(15),
            },
          });

          setScreenerScoreText(
            <>
              <p>
                Your answers indicate minimal barriers to physical activity.
                What's your secret? Compare notes with colleagues and keep up
                the great work!
              </p>
            </>
          );
        }
        console.log('Final score:', finalScore);
      }

      toggle();
      setCompleteKC(false);

      if (screenerDone !== true) {
        dispatch(fetchKnowledgeCheck(section?.id));
        dispatch(updateModuleSectionScore(score, section));
      }
    }

    // eslint-disable-next-line
  }, [knowledgeCheckAnswers, screenerDone, screenerScoreText]);

  useEffect(() => {
    const { _state, _globalAnswers } = getLocalAnswers(
      content.map((x) => new KnowledgeCheck(x))
    );
    setAnswers([..._globalAnswers]);

    setKnowledgeChecks([..._state]);
  }, [content]);

  const onSubmit = (knowledge_check) => {
    setMessage('');
    setCompleteKC(true);

    if (knowledge_check.answers && !knowledge_check.answers.length) {
      setMessage('Please, choose one option.');
      return;
    }

    const answersMap = answers
      .filter((x) => x.kid === knowledge_check.id)
      .map((item) => {
        return {
          name: item?.name,
          knowledge_check_question_id: item?.knowledge_check_question_id,
          knowledge_check_option_id: item?.id,
          user_id: user.id,
        };
      });
    let answersList = knowledge_check.knowledge_check_questions_options_answers;
    setSubmittingKCID(knowledge_check.id);
    dispatch(submitKnowledgeCheck(answersMap, answersList));
  };

  const onSubmitScreener = (knowledge_check) => {
    setMessage('');
    if (
      (knowledge_check.answers && !knowledge_check.answers.length) ||
      knowledge_check.answers.length <
      knowledgeChecks[0].knowledge_check_questions_options_answers.length
    ) {
      setMessage('Please answer all questions.');
      return;
    }

    setCompleteKC(true);
    setSubmittingKCID(knowledge_check.id);

    // console.log("DISPATCH ANSWERS" answers);
    dispatch(
      submitKnowledgeCheck(
        [],
        knowledge_check.knowledge_check_questions_options_answers,
        true
      )
    );
  };

  /**
   * Auto fill local answers when !has_pending_check to show previous answers
   * @param knowledge_check_list
   * @param kc_id 'KC answers will be ignored for this id if it is present.
   * @returns {{_globalAnswers: *[], _state}}
   */
  const getLocalAnswers = (knowledge_check_list, kc_id = 0) => {
    let _globalAnswers = [];
    let _answers = [];

    const _state = knowledge_check_list.map((knowledge_check_answer) => {
      if (
        !knowledge_check_answer.has_pending_checks &&
        (!kc_id || knowledge_check_answer.id !== kc_id)
      ) {
        knowledge_check_answer?.knowledge_check_questions_options_answers.forEach(
          (item) => {
            item.knowledge_check_options.forEach((option) => {
              // is option inside answers?
              const answersList = item.knowledge_check_answers.filter(
                (answer) => {
                  return answer.knowledge_check_option_id === option.id;
                }
              );

              if (answersList.length) {
                _answers.push(option);
              }
            });
          }
        );
      }

      _globalAnswers = [..._globalAnswers, ..._answers];
      knowledge_check_answer.answers = [..._answers];
      _answers = [];
      return knowledge_check_answer;
    });

    return { _state, _globalAnswers };
  };

  const onRetake = (knowledge_check_answer) => {
    const _state = [...knowledgeChecks];
    const index = _state.findIndex((x) => x.id === knowledge_check_answer.id);
    _state[index] = {
      ...knowledge_check_answer,
      ...{ retake: true, answers: [] },
    };

    const { _state: new_state, _globalAnswers } = getLocalAnswers(
      _state,
      knowledge_check_answer.id
    );

    new_state[index] = {
      ...knowledge_check_answer,
      ...{ retake: true, answers: [], has_pending_checks: true },
    };

    setAnswers([..._globalAnswers]);
    setKnowledgeChecks([...new_state]);
  };

  const showAnswers = (answer_id) => {
    const elements = document.getElementsByClassName(
      `answers-cnt-${answer_id}`
    );

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].classList.contains('open')) {
        elements[i].classList.remove('open');
      } else {
        elements[i].classList.add('open');
      }
    }
  };

  const saveAnswers = (_answers, id) => {
    const filteredAnswers = _answers.filter((x) => x.kid === id);

    let temp = [...knowledgeChecks];

    temp.find((e) => e.id === id).answers = [...filteredAnswers];
    setKnowledgeChecks([...temp]);
  };

  const transformKnowledgeCheckOptions = (kcOptions, questionIndex) => {
    let screenerOptions = [];
    let selectedModel = '';
    const inverseStressQuestions = [4, 5, 7, 8];
    const inverseMindfulnessQuestions = [2, 6, 7];

    if (kcOptions.length === 1) {
      switch (kcOptions[0].name) {
        case 'Never':
          selectedModel = 'stress';
          break;
        case 'Rarely/Not at All':
          selectedModel = 'mindfulness';
          break;
        case 'Not at All':
          selectedModel = 'anxiety';
          break;
        case 'Very likely':
          selectedModel = 'activity';
          break;
        case 'Strongly Disagree':
          selectedModel = 'sel_check_in';
          break;
        case 'Very Difficult':
          selectedModel = 'sel_skills';
          break;
      }
    }

    if (selectedModel.length > 0) {
      const questionNumber = questionIndex + 1;
      for (const answer of screenersScoreModel[selectedModel].answersScores) {
        const screenerOption = JSON.parse(JSON.stringify(kcOptions[0]));
        screenerOption.id = answer.id + questionIndex * 100;
        screenerOption.name = answer.label;
        if (selectedModel === 'stress') {
          screenerOption.score = inverseStressQuestions.includes(questionNumber)
            ? Math.abs(answer.score - 4)
            : answer.score;
        } else if (selectedModel === 'mindfulness') {
          screenerOption.score = inverseMindfulnessQuestions.includes(
            questionNumber
          )
            ? Math.abs(answer.score - 5)
            : answer.score;
        } else {
          screenerOption.score = answer.score;
        }
        screenerOption.screenerModel = selectedModel;
        screenerOption.correct = false;
        screenerOptions.push(screenerOption);
      }

      return screenerOptions;
    }

    return kcOptions;
  };

  const screenerKcMin = parseInt(SCREENER_START);
  const screenerKcMax = parseInt(SCREENER_END) + 3;

  if (fetchLoading) return <CustomSpinner />;

  if (!content || content.length === 0) return <div>No data</div>;

  return (
    <>
      {knowledgeChecks[0] &&
        (knowledgeChecks[0].id < screenerKcMin ||
          knowledgeChecks[0].id > screenerKcMax) && (
          <Col className="text-left">
            <CustomButton
              className="custom-button-back"
              label="Back"
              onClick={goBack}
              icon={faAngleLeft}
            />
          </Col>
        )}

      {knowledgeChecks.map((knowledge_check_answer, key) => {
        return (
          <div key={key}>
            <Container fluid={true} className="knowledge-check-list-container">
              <Row className={`mt-3`}>
                <Col sm={8} lg={8} className={`d-flex align-items-center`}>
                  {knowledge_check_answer.id < screenerKcMin &&
                    knowledge_check_answer.id > screenerKcMax && (
                      <img
                        src={
                          knowledge_check_answer.has_pending_checks
                            ? UnCheckedMarkIcon
                            : CheckedMarkIcon
                        }
                        alt="checked mark"
                        className={`mr-2`}
                      />
                    )}
                  <h2 className="fw-300 font-poppins mb-1 font-25 text-color-primary">
                    {knowledge_check_answer?.name}
                  </h2>
                </Col>
                {!knowledge_check_answer.has_pending_checks ? (
                  <Col
                    sm={4}
                    lg={4}
                    className={`d-flex align-items-center justify-content-end text-dark`}
                  >
                    <div className={`justify-content-center d-flex my-2`}>
                      <button
                        className="btn btn-sm btn-warning text-center"
                        onClick={() => showAnswers(knowledge_check_answer.id)}
                      >
                        Show answers
                      </button>
                    </div>
                  </Col>
                ) : (
                  // OR
                  ''
                )}
              </Row>

              {knowledge_check_answer.id == screenerKcMin && (
                <Row>
                  <Col>
                    <p className="mt-3 text-color-primary">
                      The below questions ask about your feelings and thoughts.
                      In each case, indicate how often you felt or thought a
                      certain way.
                    </p>
                  </Col>
                </Row>
              )}
              {knowledge_check_answer.id == (screenerKcMin + 1) && (
                <Row>
                  <Col>
                    <p className="mt-3 text-color-primary">
                      People have a variety of ways of relating to their
                      thoughts and feelings. For each of the items below, rate
                      how much each of these ways applies to you.
                    </p>
                  </Col>
                </Row>
              )}
              {knowledge_check_answer.id == (screenerKcMin + 2) && (
                <Row>
                  <Col>
                    <p className="mt-3 text-color-primary">
                      Over the last 2 weeks, how often have you been bothered by
                      the following problems?
                    </p>
                  </Col>
                </Row>
              )}
              {knowledge_check_answer.id == (screenerKcMin + 3) && (
                <Row>
                  <Col>
                    <p className="mt-3 text-color-primary">
                      Listed below are reasons that people give to describe why
                      they do not get as much physical activity as they think
                      they should. Read each statement and indicate how likely
                      you are to say each of the following statements.
                    </p>
                  </Col>
                </Row>
              )}
              {knowledge_check_answer.id == (screenerKcMin + 5) && (
                <Row>
                  <Col>
                    <p className="mt-3 text-color-primary">
                      Reach each statement below and rate yourself. Read each statement below, and
                      indicate the extent to which you agree or disagree.
                    </p>
                  </Col>
                </Row>
              )}
              {knowledge_check_answer.id == screenerKcMax && (
                <Row>
                  <Col>
                    <p className="mt-3 text-color-primary">
                      Read each statement below and rate yourself.
                    </p>
                  </Col>
                </Row>
              )}

              {!knowledge_check_answer.has_pending_checks ? (
                <Row
                  className={`mt-2 d-flex align-items-center justify-content-center`}
                >
                  <div className="col-6">
                    <h5 className={`m-0 text-nowrap`}>
                      Score:{' '}
                      <span
                        className={`text-success`}
                      >{`${knowledge_check_answer?.has_pending_checks_score}`}</span>
                    </h5>
                  </div>
                  <div className="col-6 text-right">
                    <button
                      onClick={() => {
                        onRetake(knowledge_check_answer);
                      }}
                      className="btn btn-sm btn-danger text-center"
                    >
                      <span>Retake</span>
                    </button>
                  </div>
                </Row>
              ) : (
                // OR
                ''
              )}

              {(knowledge_check_answer.id == screenerKcMax)
                ? (<ReactWizard
                  steps={[
                    {
                      stepName: "First", component: class FirstStep extends React.Component {
                        constructor(props) {
                          super(props);
                          this.state = {
                            firstStep: "1"
                          };
                        }
                        isValidated() {
                          setMessage('');
                          if ((knowledge_check_answer.answers && !knowledge_check_answer.answers.length) || knowledge_check_answer.answers.length < 8) {
                            setMessage('Please answer all questions.');
                            return false;
                          }
                          window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                          });
                          return true;
                        }
                        render() {
                          return <div>{(knowledge_check_answer?.knowledge_check_questions_options_answers
                            .filter((item, index) => index >= 0 && index < 8)
                            .map((item, index) => (
                              <Row
                                id={`answers-cnt-${knowledge_check_answer.id}-${index}`}
                                className={`mb-5 custom-question-answers answers-cnt-${knowledge_check_answer.id
                                  } ${knowledge_check_answer.has_pending_checks
                                    ? ''
                                    : 'completed'
                                  }`}
                                key={`${item.name}-${item.knowledge_check_question_id + index * 5
                                  }`}
                              >
                                <Col lg={12}>
                                  <br />
                                  <RadioList
                                    text={item.name}
                                    options={transformKnowledgeCheckOptions(
                                      item.knowledge_check_options,
                                      index
                                    )}
                                    key={knowledge_check_answer.id}
                                    index={index}
                                    multiple={item.multiple}
                                    setAnswers={setAnswers}
                                    knowledgeCheckID={knowledge_check_answer.id}
                                    saveAnswers={(_answers) => {
                                      saveAnswers(_answers, knowledge_check_answer.id);
                                    }}
                                    pending={knowledge_check_answer.has_pending_checks}
                                    answers={answers}
                                  />
                                </Col>
                              </Row>
                            )
                            ))}
                            <Row>
                              <Col className="wizard-footnote" lg={12}><div className="al">Page 1 of 5</div></Col>
                            </Row>
                          </div>
                        }
                      }
                    },
                    {
                      stepName: "Second", component: class SecondStep extends React.Component {
                        constructor(props) {
                          super(props);
                          this.state = {
                            secondStep: "2"
                          };
                        }
                        isValidated() {
                          setMessage('');
                          if ((knowledge_check_answer.answers && !knowledge_check_answer.answers.length)
                            || knowledge_check_answer.answers.length < 16) {
                            setMessage('Please answer all questions.');
                            return false;
                          }
                          window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                          });
                          return true;
                        }
                        render() {
                          return <div>{(knowledge_check_answer?.knowledge_check_questions_options_answers
                            .filter((item, index) => index >= 8 && index < 16)
                            .map((item, index) => (
                              <Row
                                id={`answers-cnt-${knowledge_check_answer.id}-${index + 8}`}
                                className={`mb-5 custom-question-answers answers-cnt-${knowledge_check_answer.id
                                  } ${knowledge_check_answer.has_pending_checks
                                    ? ''
                                    : 'completed'
                                  }`}
                                key={`${item.name}-${item.knowledge_check_question_id + (index + 8) * 5
                                  }`}
                              >
                                <Col lg={12}>
                                  <br />
                                  <RadioList
                                    text={item.name}
                                    options={transformKnowledgeCheckOptions(
                                      item.knowledge_check_options,
                                      index + 8
                                    )}
                                    index={index + 8}
                                    multiple={item.multiple}
                                    setAnswers={setAnswers}
                                    knowledgeCheckID={knowledge_check_answer.id}
                                    saveAnswers={(_answers) => {
                                      saveAnswers(_answers, knowledge_check_answer.id);
                                    }}
                                    pending={knowledge_check_answer.has_pending_checks}
                                    answers={answers}
                                  />
                                </Col>
                              </Row>
                            )
                            ))}
                            <Row>
                              <Col className="wizard-footnote" lg={12}><div className="al">Page 2 of 5</div></Col>
                            </Row>
                          </div>
                        }
                      }
                    },
                    {
                      stepName: "Third", component: class ThirdStep extends React.Component {
                        constructor(props) {
                          super(props);
                          this.state = {
                            thirdStep: "3"
                          };
                        }
                        isValidated() {
                          setMessage('');
                          if ((knowledge_check_answer.answers && !knowledge_check_answer.answers.length)
                            || knowledge_check_answer.answers.length < 24) {
                            setMessage('Please answer all questions.');
                            return false;
                          }
                          window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                          });
                          return true;
                        }
                        render() {
                          return <div>{(knowledge_check_answer?.knowledge_check_questions_options_answers
                            .filter((item, index) => index >= 16 && index < 24)
                            .map((item, index) => (
                              <Row
                                id={`answers-cnt-${knowledge_check_answer.id}-${index + 16}`}
                                className={`mb-5 custom-question-answers answers-cnt-${knowledge_check_answer.id
                                  } ${knowledge_check_answer.has_pending_checks
                                    ? ''
                                    : 'completed'
                                  }`}
                                key={`${item.name}-${item.knowledge_check_question_id + (index + 16) * 5
                                  }`}
                              >
                                <Col lg={12}>
                                  <br />
                                  <RadioList
                                    text={item.name}
                                    options={transformKnowledgeCheckOptions(
                                      item.knowledge_check_options,
                                      index + 16
                                    )}
                                    index={index + 16}
                                    multiple={item.multiple}
                                    setAnswers={setAnswers}
                                    knowledgeCheckID={knowledge_check_answer.id}
                                    saveAnswers={(_answers) => {
                                      saveAnswers(_answers, knowledge_check_answer.id);
                                    }}
                                    pending={knowledge_check_answer.has_pending_checks}
                                    answers={answers}
                                  />
                                </Col>
                              </Row>
                            )
                            ))}
                            <Row>
                              <Col className="wizard-footnote" lg={12}><div className="al">Page 3 of 5</div></Col>
                            </Row>
                          </div>
                        }
                      }
                    },
                    {
                      stepName: "Fourth", component: class FourthStep extends React.Component {
                        constructor(props) {
                          super(props);
                          this.state = {
                            fourthStep: "4"
                          };
                        }
                        isValidated() {
                          setMessage('');
                          if ((knowledge_check_answer.answers && !knowledge_check_answer.answers.length)
                            || knowledge_check_answer.answers.length < 32) {
                            setMessage('Please answer all questions.');
                            return false;
                          }
                          window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                          });
                          return true;
                        }
                        render() {
                          return <div>{(knowledge_check_answer?.knowledge_check_questions_options_answers
                            .filter((item, index) => index >= 24 && index < 32)
                            .map((item, index) => (
                              <Row
                                id={`answers-cnt-${knowledge_check_answer.id}-${index + 24}`}
                                className={`mb-5 custom-question-answers answers-cnt-${knowledge_check_answer.id
                                  } ${knowledge_check_answer.has_pending_checks
                                    ? ''
                                    : 'completed'
                                  }`}
                                key={`${item.name}-${item.knowledge_check_question_id + (index + 24) * 5
                                  }`}
                              >
                                <Col lg={12}>
                                  <br />
                                  <RadioList
                                    text={item.name}
                                    options={transformKnowledgeCheckOptions(
                                      item.knowledge_check_options,
                                      index + 24
                                    )}
                                    index={index + 24}
                                    multiple={item.multiple}
                                    setAnswers={setAnswers}
                                    knowledgeCheckID={knowledge_check_answer.id}
                                    saveAnswers={(_answers) => {
                                      saveAnswers(_answers, knowledge_check_answer.id);
                                    }}
                                    pending={knowledge_check_answer.has_pending_checks}
                                    answers={answers}
                                  />
                                </Col>
                              </Row>
                            )
                            ))}
                            <Row>
                              <Col className="wizard-footnote" lg={12}><div className="al">Page 4 of 5</div></Col>
                            </Row>
                          </div>
                        }
                      }
                    },
                    {
                      stepName: "Fifth", component: class FifthStep extends React.Component {
                        constructor(props) {
                          super(props);
                          this.state = {
                            fifthStep: "5"
                          };
                        }
                        render() {
                          return <div>{(knowledge_check_answer?.knowledge_check_questions_options_answers
                            .filter((item, index) => index >= 32 && index < 40)
                            .map((item, index) => (
                              <Row
                                id={`answers-cnt-${knowledge_check_answer.id}-${index + 32}`}
                                className={`mb-5 custom-question-answers answers-cnt-${knowledge_check_answer.id
                                  } ${knowledge_check_answer.has_pending_checks
                                    ? ''
                                    : 'completed'
                                  }`}
                                key={`${item.name}-${item.knowledge_check_question_id + (index + 32) * 5
                                  }`}
                              >
                                <Col lg={12}>
                                  <br />
                                  <RadioList
                                    text={item.name}
                                    options={transformKnowledgeCheckOptions(
                                      item.knowledge_check_options,
                                      index + 32
                                    )}
                                    index={index + 32}
                                    multiple={item.multiple}
                                    setAnswers={setAnswers}
                                    knowledgeCheckID={knowledge_check_answer.id}
                                    saveAnswers={(_answers) => {
                                      saveAnswers(_answers, knowledge_check_answer.id);
                                    }}
                                    pending={knowledge_check_answer.has_pending_checks}
                                    answers={answers}
                                  />
                                </Col>
                              </Row>
                            )
                            ))}
                            <Row>
                              <Col className="wizard-footnote" lg={12}><div className="al">Page 5 of 5</div></Col>
                            </Row>
                          </div>
                        }
                      }
                    },
                  ]}
                  color="primary"
                  previousButtonClasses="custom-button-default font-poppins border-radius-12 font-18"
                  nextButtonClasses="custom-button-default font-poppins border-radius-12 font-18"
                  finishButtonClasses="custom-button-default font-poppins border-radius-12 font-18"
                  progressbar={false}
                  finishButtonClick={() => onSubmitScreener(knowledge_check_answer)}
                  validate
                />)
                : (knowledge_check_answer?.knowledge_check_questions_options_answers.map(
                  (item, index) => (
                    <Row
                      id={`answers-cnt-${knowledge_check_answer.id}-${index}`}
                      className={`mb-5 custom-question-answers answers-cnt-${knowledge_check_answer.id
                        } ${knowledge_check_answer.has_pending_checks
                          ? ''
                          : 'completed'
                        }`}
                      key={`${item.name}-${item.knowledge_check_question_id + index * 5
                        }`}
                    >
                      <Col lg={12}>
                        <br />
                        <RadioList
                          text={item.name}
                          options={transformKnowledgeCheckOptions(
                            item.knowledge_check_options,
                            index
                          )}
                          index={index}
                          multiple={item.multiple}
                          setAnswers={setAnswers}
                          knowledgeCheckID={knowledge_check_answer.id}
                          saveAnswers={(_answers) => {
                            saveAnswers(_answers, knowledge_check_answer.id);
                          }}
                          pending={knowledge_check_answer.has_pending_checks}
                          answers={answers}
                        />
                      </Col>
                    </Row>
                  )
                ))
              }

              <br></br>

              <Modal show={message !== ''} fullscreen={'true'}>
                <Modal.Body>
                  <div>
                    <p className="text-danger text-center">
                      {message}
                    </p>
                    <button onClick={onErrorClose} className="custom-button-default w-100">
                      OK
                    </button>
                  </div>
                </Modal.Body>
              </Modal>

              {knowledge_check_answer.has_pending_checks ? (
                <div className="custom-submit-container">
                  {knowledge_check_answer.id != screenerKcMax && <button
                    disabled={loading}
                    className={`${loading && submittingKCID === knowledge_check_answer.id
                      ? 'disabled'
                      : ''
                      } custom-button-default font-poppins border-radius-12 font-18 d-flex justify-content-center`}
                    onClick={() => {
                      if (
                        knowledge_check_answer.id >= screenerKcMin &&
                        knowledge_check_answer.id <= screenerKcMax
                      ) {
                        onSubmitScreener(knowledge_check_answer);
                      } else {
                        onSubmit(knowledge_check_answer);
                      }
                    }}
                  >
                    {loading && submittingKCID === knowledge_check_answer.id ? (
                      <span className={`mr-2`}>
                        <CustomSpinner />
                      </span>
                    ) : (
                      ''
                    )}
                    <label className="custom-submit-button-text">
                      {loading && submittingKCID === knowledge_check_answer.id
                        ? 'Submitting...'
                        : 'Submit'}
                    </label>
                  </button>}
                  <div className="flex-break"></div>

                  <>
                    <a href="#" className="gray-link" onClick={goBack}>
                      Skip
                    </a>
                  </>

                  <div className="flex-break"></div>

                  <div className="max-width">
                    {knowledge_check_answer.id == screenerKcMin && (
                      <span className="font-poppins font-13 text-color-gray mt-4">
                        Source: Cohen, S., & Williamson, G. (1988). Perceived
                        stress in a probability sample of the United States. In
                        S. Spacapam & S. Oskamp (Eds.), The social psychology of
                        health: Claremont Symposium on applied social psychology
                        Newbury Park, CA: Sage.
                      </span>
                    )}
                    {knowledge_check_answer.id == (screenerKcMin + 1) && (
                      <span className="font-poppins font-13 text-color-gray mt-4">
                        Source: Feldman, G., Hayes, A., Kumar, S. et al.
                        Mindfulness and Emotion Regulation: The Development and
                        Initial Validation of the Cognitive and Affective
                        Mindfulness Scale-Revised (CAMS-R). J Psychopathol Behav
                        Assess 29, 177–190 (2007).
                      </span>
                    )}
                    {knowledge_check_answer.id == (screenerKcMin + 2) && (
                      <span className="font-poppins font-13 text-color-gray mt-4">
                        Source: Spitzer RL, Kroenke K, Williams JB, Löwe B. A
                        brief measure for assessing generalized anxiety
                        disorder: the GAD-7. Arch Intern Med. 2006 May
                        22;166(10):1092-7. doi: 10.1001/archinte.166.10.1092.
                        PMID: 16717171.
                      </span>
                    )}
                    {knowledge_check_answer.id == (screenerKcMin + 3) && (
                      <span className="font-poppins font-13 text-color-gray mt-4">
                        Source: U.S. Centers for Disease Control and Prevention
                      </span>
                    )}
                    {knowledge_check_answer.id == (screenerKcMin + 5) && (
                      <span className="font-poppins font-13 text-color-gray mt-4">
                        Source: American Institutes for Research — Center on Great Teachers and Leaders
                      </span>
                    )}
                    {knowledge_check_answer.id == screenerKcMax && (
                      <span className="font-poppins font-13 text-color-gray mt-4">
                        Source: Collaborative for Academic, Social, and Emotional Learning
                      </span>
                    )}
                  </div>

                  <div className="flex-break"></div>

                  {knowledge_check_answer.id >= screenerKcMin &&
                    knowledge_check_answer.id <= screenerKcMax && (
                      <>
                        <p className="font-poppins font-13 text-color-gray mt-4">
                          <i>
                            DISCLAIMER: Bounceback is not a substitute for medical
                            advice, diagnosis, or treatment. Consult with a
                            healthcare professional if you are concerned about
                            your health and well-being. Bounceback utilizes
                            interactive screeners (“Screeners”) to promote
                            improved health and well-being among users. Screeners
                            are optional. Screeners do not provide medical
                            services. Screeners are not intended to diagnose,
                            cure, treat, or prevent any health condition.
                            Screeners are not intended to determine or assess
                            health status. Individual user responses tabulated by
                            Screeners are anonymized. Individual user responses are
                            not shared with any employer or third party.
                            Participation in Bounceback is voluntary and does not
                            affect users’ participation in other health and
                            well-being programs. If you are in you are in crisis
                            and need support, call or text 988. If you need
                            emergency assistance, call 911.
                          </i>
                        </p>
                      </>
                    )}

                </div>
              ) : (
                // OR
                ''
              )}

              <Modal show={modal} fullscreen={'true'}>
                <Modal.Body>
                  <AlertModalScore
                    minScore={75}
                    onClose={goBack}
                    score={Number(score).toFixed(1)}
                    screenerTitle={screenerScoreText}
                  />
                </Modal.Body>
              </Modal>
            </Container>
          </div>
        );
      })}
    </>
  );
};

export default KnowledgeCheckList;
