import React from 'react';
import { Alert } from 'react-bootstrap';

const AlertDismissible = ({
  showAlert,
  variant = 'danger',
  content,
  onCloseAlert,
}) => {
  if (showAlert) {
    return (
      <Alert variant={variant} onClose={onCloseAlert} dismissible>
        <p className={`mb-0`}>{content}</p>
      </Alert>
    );
  }
  return <></>;
};

export default AlertDismissible;
