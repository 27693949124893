import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import appStyle from '../styles/App.module.scss';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { onSearchElement } from '../redux/actions/searchActions';
import { getVideoById } from '../redux/actions/videoActions';
import { getModuleById } from '../redux/actions/moduleActions';
import { getSectionById } from '../redux/actions/sectionActions';

function Search() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const { searchValue } = useSelector((state) => state.search);
  const { isHelpCenter } = useSelector((state) => state.course);
  const { openFavoriteSection } = useSelector((state) => state.favorite);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(onSearchElement(search, openFavoriteSection, isHelpCenter));
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [dispatch, search, openFavoriteSection]);

  useEffect(() => {
    if (searchValue === '') setSearch('');
  }, [searchValue]);

  const handleOnSearch = (event) => {
    // clear elements
    dispatch(getVideoById());
    dispatch(getModuleById());
    dispatch(getSectionById());

    // do search
    setSearch(event.target.value);
  };

  return (
    <div className={appStyle['search-container']}>
      <div className={appStyle['filler']} />
      <label htmlFor="mainSiteSearch" className="sr-only">
        Search in all Bounceback
      </label>
      <input
        id="mainSiteSearch"
        type="text"
        value={search}
        placeholder={'Search'}
        className={appStyle['rounded-input']}
        onChange={handleOnSearch}
      />
      <FontAwesomeIcon
        className={appStyle['search-icon']}
        icon={faSearch}
        title="Search"
        size="lg"
      />
    </div>
  );
}

export default Search;
