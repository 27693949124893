// eslint-disable-next-line import/no-cycle
import { getUserToken } from '../localStorage';

export const requestGetApi = async (endpoint, token = null) => {
  // eslint-disable-next-line no-param-reassign
  token = token || getUserToken();
  const headers = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  };

  // eslint-disable-next-line no-async-promise-executor,no-return-await
  return await new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(endpoint, headers);
      if (!response.ok) reject(response.status);

      const result = await response.json();
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const requestPostApi = async (endpoint, body, token = null) => {
  token = token ? token : getUserToken();
  const headers = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body),
  };

  return await new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(endpoint, headers);
      if (!response.ok) reject(response.status);

      const result = await response.json();
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};
