import React from 'react';
import '../styles/Module.scss';
import CourseSyllabus from './CourseSyllabus';

const ModuleHeader = ({ moduleId, moduleName, description }) => {
  return (
    <div className="module-header-container">
      <div className="row">
        <div className="col-12 d-sm-block d-md-flex align-items-center justify-content-between mb-3">
          <h1 className="m-0 text-color-primary font-24 fw-300 font-poppins">
            <a
              id={'module' + moduleId}
              href="#"
              className="m-0 text-color-primary font-24 fw-400 font-poppins"
              style={{ textDecoration: 'none' }}
            >
              {moduleName}
            </a>
          </h1>
          <CourseSyllabus />
        </div>

        <div className="col-12 ml-2">
          <div className="font-13 font-poppins text-color-gray">
            <span>{description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuleHeader;
