import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from './redux';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import LocalServiceWorkerRegister from './sw-register';

const store = configureStore();

// let installPromptShown = false;

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

// let deferredPrompt;

// window.addEventListener('beforeinstallprompt', (event) => {
//   // Prevent the default browser prompt
//   event.preventDefault();
//   // Stash the event so it can be triggered later
//   deferredPrompt = event;
//   // Show the install promotion when user clicks or taps on the screen
//   if (!installPromptShown)
//     window.addEventListener('mousedown', showInstallPromotion);
// });

// function showInstallPromotion() {
//   installPromptShown = true;

//   console.log('Install promotion triggered');

//   // Remove the event listener to prevent multiple installations
//   window.removeEventListener('mousedown', showInstallPromotion);
//   // Prompt the user to install the app using the deferredPrompt
//   if (deferredPrompt) {
//     deferredPrompt.prompt();
//     // Wait for the user to respond to the prompt
//     deferredPrompt.userChoice.then((choiceResult) => {
//       if (choiceResult.outcome === 'accepted') {
//         console.log('User accepted the install prompt');
//       } else {
//         console.log('User dismissed the install prompt');
//       }
//       // Clear the deferredPrompt variable, as it can only be used once
//       deferredPrompt = null;
//     });
//   }
// }

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

LocalServiceWorkerRegister();
