import {
  FETCH_KNOWLEDGE_CHECK_FAILURE,
  FETCH_KNOWLEDGE_CHECK_REQUEST,
  FETCH_KNOWLEDGE_CHECK_SUCCESS,
  HIDE_KNOWLEDGE_CHECKS,
  POST_KNOWLEDGE_CHECK_FAILURE,
  POST_KNOWLEDGE_CHECK_REQUEST,
  POST_KNOWLEDGE_CHECK_SUCCESS,
} from '../types/knowledgeCheckTypes';

const initialState = {
  showKnowledgeChecks: false,
  knowledgeChecks: [],
  knowledgeCheckAnswers: [],
  score: 0,
  error: '',
  loading: false,
  fetchLoading: false,
  screenerDone: false,
};

export const knowledgeCheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_KNOWLEDGE_CHECK_REQUEST:
      return {
        ...state,
        fetchLoading: true,
        error: '',
      };
    case FETCH_KNOWLEDGE_CHECK_SUCCESS:
      return {
        ...state,
        fetchLoading: false,
        showKnowledgeChecks: true,
        knowledgeChecks: action.payload,
      };
    case FETCH_KNOWLEDGE_CHECK_FAILURE:
      return {
        ...state,
        fetchLoading: false,
        error: action.payload,
      };
    case HIDE_KNOWLEDGE_CHECKS:
      return {
        ...state,
        showKnowledgeChecks: false,
      };
    case POST_KNOWLEDGE_CHECK_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case POST_KNOWLEDGE_CHECK_SUCCESS:
      const answersList = action.payload.answersList;

      let correctAnswersCounter = {};
      answersList.forEach((x) => {
        if (!(x.id in correctAnswersCounter)) {
          correctAnswersCounter[x.id] = {};
        }
        correctAnswersCounter[x.id] = x.knowledge_check_options.filter(
          (y) => y.correct
        ).length;
      });

      const answers = action.payload.knowledgeCheckAnswers;
      const grouped_answers = groupItemBy(
        answers,
        'knowledge_check_question.id'
      );
      let correct = [];
      Object.keys(grouped_answers).forEach((key) => {
        correct.push(
          grouped_answers[key].every((item) => item.correct) &&
            correctAnswersCounter[key] === grouped_answers[key].length
        );
      });

      const totalCorrect = correct.filter((x) => x === true).length;

      return {
        ...state,
        score: (totalCorrect / answersList.length) * 100,
        loading: false,
        knowledgeCheckAnswers: action.payload.knowledgeCheckAnswers,
        screenerDone: action.payload.screenerDone,
      };
    case POST_KNOWLEDGE_CHECK_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

function groupItemBy(array, property) {
  let hash = {},
    props = property.split('.');
  for (let i = 0; i < array.length; i++) {
    let key = props.reduce(function (acc, prop) {
      return acc && acc[prop];
    }, array[i]);
    if (!hash[key]) hash[key] = [];
    hash[key].push(array[i]);
  }
  return hash;
}
