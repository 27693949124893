export default class Survey {
  id = null;
  name = '';
  average = 0;
  description = '';
  module_section = null;
  survey_questions = [];
  user_survey_attempts = [];
  created_at = '';
  updated_at = '';
  url = '';

  constructor(props) {
    if (props) {
      this.id = props.id;
      this.name = props.name;
      this.description = props.description;
      this.average = props.average;
      this.module_section = new ModuleSection(props.module_section);
      this.survey_questions = props.survey_questions.map(
        (sq) => new SurveyQuestion(sq)
      );
      this.user_survey_attempts = props.user_survey_attempts.map(
        (att) => new SurveyAttempt(att)
      );
      this.created_at = props.created_at;
      this.updated_at = props.updated_at;
      this.url = props.url;
    }
  }
}

export class ModuleSection {
  id = null;
  course_module_id = null;
  name = '';
  description = '';
  created_at = '';
  updated_at = '';
  knowledge_check_url = '';
  status = null;
  order = null;

  constructor(props) {
    if (props) {
      this.id = props.id;
      this.course_module_id = props.course_module_id;
      this.name = props.name;
      this.description = props.description;
      this.created_at = props.created_at;
      this.updated_at = props.updated_at;
      this.knowledge_check_url = props.knowledge_check_url;
      this.status = props.status;
      this.order = props.order;
    }
  }
}

export class SurveyQuestion {
  id = null;
  name = '';
  order = 0;
  survey_id = null;
  created_at = '';
  updated_at = '';
  survey_question_options = [];

  constructor(props) {
    if (props) {
      this.id = props.id;
      this.name = props.name;
      this.order = props.order;
      this.survey_id = props.survey_id;
      this.created_at = props.created_at;
      this.updated_at = props.updated_at;
      this.survey_question_options = props.survey_question_options
        .map((sqo) => new SurveyQuestionOption(sqo))
        .sort((a, b) => a.order - b.order);
    }
  }
}

export class SurveyQuestionOption {
  id = null;
  name = '';
  score = null;
  order = 0;
  selected = false; // virtual prop
  survey_question_id = null;
  created_at = '';
  updated_at = '';

  constructor(props) {
    if (props) {
      this.id = props.id;
      this.name = props.name;
      this.score = props.score;
      this.order = props.order;
      this.survey_question_id = props.survey_question_id;
      this.created_at = props.created_at;
      this.updated_at = props.updated_at;
    }
  }
}

export class SurveyAttempt {
  created_at = '';
  id = null;
  score = 0;
  survey_id = null;
  updated_at = '';
  user_id = null;

  constructor(props) {
    if (props) {
      this.id = props.id;
      this.score = props.score;
      this.survey_id = props.survey_id;
      this.user_id = props.user_id;
      this.created_at = props.created_at;
      this.updated_at = props.updated_at;
    }
  }
}
