import React, { useState } from 'react';
import { logout, useAuthContext } from '../../context/authContext';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom'
import UserIconSVG from '../../assets/icons/user.svg';
import '../../styles/User.scss';
import useOutsideClick from '../../hooks/useOutsideClick';
import CustomButton from '../CustomButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import {
  downloadCertificates,
  getUserCertificates,
} from '../../redux/actions/certificateActions';
import CustomSpinner from '../CustomSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalLoading } from '../../redux/actions/dashboardActions';
import UserChangePassword from '../UserChangePassword';
import { downloadFile } from '../../utils/Common';
import User2FA from '../User2FA';
import { Form, Modal, Toast } from 'react-bootstrap';
import { BASE_API_URL } from '../../constants/constants';
import { getUserToken } from '../../services/localStorage';
import {
  onOpenFavoriteSection,
  onSelectedFavoriteVideosSuccess,
} from '../../redux/actions/favoriteActions';
import { fetchCampaignDataSuccess } from '../../redux/actions/campaignActions';
import {
  getModuleById,
  updateModuleHistory,
} from '../../redux/actions/moduleActions';
import {
  fetchSectionsSuccess,
  getSectionById,
  updateSectionHistory,
  updateSectionsHistory,
} from '../../redux/actions/sectionActions';
import appStyle from '../../styles/App.module.scss';

import { requestPostApi } from '../../services/api/api';

const UserSettings = (props) => {
  const reduxDispatch = useDispatch();

  const {
    state: { user },
    dispatch,
  } = useAuthContext();
  const { loading, certificates } = useSelector((state) => state.certificate);
  const { ref, isElementVisible, setIsElementVisible } = useOutsideClick(false);
  const [showCertificates, setShowCertificates] = useState(false);
  const [showUserChangePassword, setUserChangePassword] = useState(false);
  const [showUser2FA, setUser2FA] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [subtitlesModalShow, setSubtitlesModalShow] = useState(false);
  const [showSubtitleToast, setShowSubtitleToast] = useState(false);
  const [subtitlesToggled, setSubtitlesToggled] = useState(
    localStorage.getItem('subtitlesToggle') == 1
  );

  const [showUnsubscribeToast, setShowUnsubscribeToast] = useState(false);
  const [unsubscribemessage, setUnsubscribeMessage] = useState('');

  const history = useHistory();
  const handleLogOut = async () => {
    // avoid favorite section show when logout and login again
    reduxDispatch(onOpenFavoriteSection(false));
    reduxDispatch(fetchCampaignDataSuccess(undefined));
    reduxDispatch(onSelectedFavoriteVideosSuccess(undefined));

    // reset states
    reduxDispatch(updateSectionHistory(undefined));
    reduxDispatch(updateSectionsHistory([]));
    reduxDispatch(updateModuleHistory(undefined));
    reduxDispatch(fetchSectionsSuccess([]));

    // initialize course/module/section/video
    reduxDispatch(getModuleById());
    reduxDispatch(getSectionById());
    history.push('/signin');
    await logout(dispatch);
  };

  const handleToggleToast = (e) => {
    setShowToast(!showToast);
  };

  const handleToggleSubtitleToast = (e) => {
    setShowSubtitleToast(!showSubtitleToast);
  };

  const handleSubmitSubtitle = (e) => {
    localStorage.setItem('subtitlesToggle', subtitlesToggled ? 1 : 0);
    setSubtitlesModalShow(false);
    setShowSubtitleToast(true);
  };

  const handleCloseSubtitle = (e) => {
    setSubtitlesModalShow(false);
  };

  const handleSubtitleModal = (e) => {
    setSubtitlesModalShow(true);
  };

  const fetchUserCertificates = () => {
    if (!showCertificates) {
      reduxDispatch(getUserCertificates());
    }

    setShowCertificates(!showCertificates);
  };

  const downloadCertificate = (user_certificate) => {
    reduxDispatch(setGlobalLoading(true));
    downloadCertificates(user_certificate.course.id)
      .then((response) => response.blob())
      .then((blob) => {
        downloadFile('certificate.pdf', blob);
        reduxDispatch(setGlobalLoading(false));
      })
      .catch((err) => {
        reduxDispatch(setGlobalLoading(true));
      });
  };

  const handleFavorite = () => {
    reduxDispatch(getModuleById());
    reduxDispatch(getSectionById());
    reduxDispatch(onOpenFavoriteSection(true));
  };

  const UserMenu = () => {
    return (
      <>
        <UserCertificates />
      </>
    );
  };

  const UserCertificates = () => {
    return (
      <>
        <ul
          className={`menu isFlatMenu user-select-none ${!showCertificates && !loading ? 'no-certificate' : ''
            }`}
        >
          <li
            onClick={(e) => {
              e.preventDefault();
              fetchUserCertificates();
            }}
            className={`menu-item ${showCertificates ? 'expanded' : ''
              } hasSubmenu isFlatMenu`}
          >
            <div className="menu-item-title">
              <a
                href="#"
                id="certificatesLink"
                style={{ textDecoration: 'none', color: '#757575' }}
              >
                My certificates
              </a>
              <div className="course-arrow"></div>
            </div>
          </li>

          <li>
            {loading && (
              <div className={`my-2`}>
                <CustomSpinner />
              </div>
            )}
          </li>

          <ul
            className={`menu px-3 m-0 ${showCertificates ? 'd-block' : 'd-none'
              }`}
          >
            {certificates.map((item, key) => {
              return (
                <li key={key} className="user-certificate-setting-card">
                  <div className="custom-button-blue sky mb-3">
                    <div className="d-flex justify-content-between">
                      <span>{item?.course_modules ? item?.course_modules.name : item?.course.name}</span>
                      <span>
                        <FontAwesomeIcon
                          className={`text-success`}
                          icon={faCheckCircle}
                        />
                      </span>
                    </div>
                    <div className="w-100 text-center">
                      <button
                        onClick={() => {
                          downloadCertificate(item);
                        }}
                        className="custom-button-default fw-500 btn-view-certificate w-75 my-2"
                      >
                        Get Certificate
                      </button>
                    </div>
                  </div>
                </li>
              );
            })}

            {!loading && !certificates.length && (
              <div className="custom-button-blue text-center px-2">
                No certificates yet
              </div>
            )}
          </ul>
        </ul>
      </>
    );
  };

  const handleManageAccount = () => {
    setUserChangePassword(true);
  };

  const disable2fa = async () => {
    const token = getUserToken();
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
    };
    const response = await fetch(
      `${BASE_API_URL}/users/disable_2fa`,
      requestOptions
    );
    if (response.ok) {
      if (user) {
        user.otp_required_for_login = false;
        localStorage.setItem('user', JSON.stringify(user));
      }
    }
  };

  const handle2FA = () => {
    if (user?.otp_required_for_login) {
      disable2fa();
      setIsElementVisible(false);
      setShowToast(true);
    } else {
      setUser2FA(true);
    }
  };

  const handleUnsubscribe2 = (e) => {

    // Hide the UserSettings component
    setIsElementVisible(false);
    // Show the unsubscribe toast
    setShowUnsubscribeToast(true);

    setTimeout(() => setShowUnsubscribeToast(false), 3000);

    history.push('/dashboard');
  };

  const handleToggleUnsubscribeToast = (e) => {
    setShowUnsubscribeToast(false);
  }

  const handleUnsubscribe = async (e) => {

    setIsElementVisible(false);

    try {
      const response = await requestPostApi(`${BASE_API_URL}/users/unsubscribe`, {});

      if (!response.success) {
        const errorMessage = response.statusText || 'HTTP error';
        throw new Error(`${errorMessage}! Status: ${response.status}`);
      }

      setUnsubscribeMessage('Unsubscribed successfully!');
      setShowUnsubscribeToast(true);

      history.push('/dashboard');

    } catch (error) {
      console.error('Error unsubscribing:', error);
      setUnsubscribeMessage('Error unsubscribing.');
      setShowUnsubscribeToast(true);
    } finally {
      setTimeout(() => setShowUnsubscribeToast(false), 3000);
    }

  };

  return (
    <>
      <div className={props.className} ref={ref}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setIsElementVisible(!isElementVisible);
          }}
          role="button"
          aria-pressed={isElementVisible}
        >
          <img
            src={UserIconSVG}
            title="User profile menu"
            className={'cursor-pointer rounded-circle'}
            alt="User profile menu"
            width={40}
            height={40}
          />
        </a>
        {isElementVisible && (
          <div className="user-setting-menu-container">
            <div className="user-menu-header">
              <div className="user-name">
                {user?.first_name + ' ' + user?.last_name}
              </div>
              <div className="user-email">{user?.email}</div>
            </div>
            <button className="custom-button-blue" onClick={handleFavorite}>
              Bookmarks
            </button>
            <button
              className="custom-button-blue mt-2"
              onClick={handleManageAccount}
            >
              Change password
            </button>
            {/* !user.is_end_user && (
              <button className="custom-button-blue mt-2" onClick={handle2FA}>
                {user?.otp_required_for_login ? 'Disable 2FA' : 'Enable 2FA'}
              </button>
            ) */}

            {/* <button
              className="custom-button-blue mt-2"
              onClick={handleSubtitleModal}
            >
              Toggle Subtitles
            </button> */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div className={`user-menu-content`}>
                <UserMenu />
              </div>

              <div className={`text-center mt-4`}>
                <CustomButton
                  className="custom-button-default font-poppins border-radius-12 font-18 fw-500 w-50"
                  label="Sign out"
                  onClick={handleLogOut}
                />
              </div>
              <div className={`text-center mt-4`}>
                <Link
                  to='/dashboard'
                  className="font-poppins font-14 fw-500 text-color-accent"
                  onClick={(e) => handleUnsubscribe(e, history)}
                >
                  Unsubscribe from Emails
                </Link>
              </div>
            </div>
          </div>
        )}

        {
          <UserChangePassword
            user={user ?? {}}
            show={showUserChangePassword}
            handleClose={() => setUserChangePassword(false)}
          />
        }
        {
          <User2FA
            user={user ?? {}}
            show={showUser2FA}
            handleClose={() => setUser2FA(false)}
          />
        }

        <Toast
          show={showToast}
          onClose={handleToggleToast}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <Toast.Header>
            <strong className="rounded mr-4">2FA Disabled</strong>
          </Toast.Header>
          <Toast.Body>2FA has been disabled for your account!</Toast.Body>
        </Toast>
      </div>

      <Modal show={subtitlesModalShow} onHide={handleCloseSubtitle}>
        <Modal.Header closeButton>
          <Modal.Title>Toggle Videos Subtitles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitSubtitle}>
            <div className={appStyle['b-form-body']}>
              <Form.Group
                controlId="subtitlesToggle"
                className={appStyle['custom-form-group']}
              >
                <div
                  className={appStyle['form-custom-label']}
                  style={{ paddingBottom: '0.5rem' }}
                >
                  Subtitles ON by default
                </div>
                <Form.Check
                  type="radio"
                  id={`subtitlesYes`}
                  label={`Yes`}
                  onChange={() => setSubtitlesToggled(!subtitlesToggled)}
                  checked={subtitlesToggled}
                />
                <Form.Check
                  type="radio"
                  label={`No`}
                  id={`subtitlesNo`}
                  onChange={() => setSubtitlesToggled(!subtitlesToggled)}
                  checked={!subtitlesToggled}
                />
              </Form.Group>
            </div>
            <CustomButton
              label="Toggle subtitles"
              className="custom-button-default"
              onClick={handleSubmitSubtitle}
            />
          </Form>
        </Modal.Body>
      </Modal>

      <Toast
        show={showSubtitleToast}
        onClose={handleToggleSubtitleToast}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <Toast.Header>
          <strong className="rounded mr-4">Subtitles ON toggled!</strong>
        </Toast.Header>
        <Toast.Body>Subtitles ON by default has been toggled.</Toast.Body>
      </Toast>

      <Toast
        show={showUnsubscribeToast}
        onClose={handleToggleUnsubscribeToast}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1000,
        }}
      >
        <Toast.Header>
          <strong className="rounded mr-4">Email Notifications</strong>
        </Toast.Header>
        <Toast.Body>{unsubscribemessage}</Toast.Body>
      </Toast>

    </>
  );
};

export default UserSettings;
