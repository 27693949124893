import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  AccordionContext,
  Badge,
  Card,
  useAccordionToggle,
} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import '../../styles/Course.scss';
import CheckedMarkIcon from '../../assets/icons/checked-mark.svg';
import UnCheckedMarkIcon from '../../assets/icons/unchecked-mark.svg';
import { ProgressBar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getSectionById } from '../../redux/actions/sectionActions';
import { getSectionByVideoFromMenu } from '../../redux/actions/videoActions';

const SectionItem = ({
  children,
  eventKey,
  section,
  name,
  onAccordionClick = () => {},
}) => {
  const dispatch = useDispatch();
  const { showKnowledgeChecks } = useSelector((state) => state.knowledgeCheck);
  const { currentVideo, sectionByVideoFromMenu } = useSelector(
    (state) => state.video
  );
  const { currentSection, loadingSections } = useSelector(
    (state) => state.section
  );
  const { isHelpCenter } = useSelector((state) => state.course);

  const [sectionTotal, setSectionTotal] = useState(1);
  const [icon, setIcon] = useState(UnCheckedMarkIcon);

  const [videos, setVideos] = useState([]);
  const [videoScrolled, setVideoScrolled] = useState(false);

  const ref = useRef();

  useEffect(() => {
    // loadingSections is useful to know if the list of sections were render correctly
    setTimeout(() => {
      if (!loadingSections) {
        // click when close section on menu
        if (sectionByVideoFromMenu === 0 && !currentSection) {
          if (
            ref &&
            ref.current &&
            ref.current.className.indexOf('section-accordion-open') !== -1
          ) {
            ref.current.click();
          }
        }

        if (
          currentSection &&
          section.id === currentSection.id &&
          sectionByVideoFromMenu !== 0
        ) {
          // only click when accordion closed
          if (
            ref &&
            ref.current &&
            ref.current.className.indexOf('section-accordion-open') === -1
          ) {
            ref.current.click();

            // wait for ref.current.click();
            setTimeout(() => {
              scrollToVideo(currentVideo);
            }, 200);
          } else {
            setTimeout(() => {
              scrollToVideo(currentVideo);
            }, 200);
          }
        }
      }
    }, 200);

    // eslint-disable-next-line
  }, [currentSection, currentVideo, sectionByVideoFromMenu]);

  useEffect(() => {
    if (section && section.items && section.items.length) {
      setVideos([...section.items]);
    }
  }, [section]);

  const scrollToVideo = (video) => {
    if (!video) return;

    const element = document.getElementById(`video-${video.id}`);
    if (!element) return;

    if (videoScrolled) return;
    setTimeout(() => {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
      const videoTitle = document.getElementById(
        'videoheader' + currentVideo.id
      );
      if (videoTitle) {
        videoTitle.focus();
      } else {
        const sectionTitle = document.getElementById(
          'section' + (section ? section.id : '0')
        );
        if (sectionTitle) sectionTitle.focus();
      }
      setVideoScrolled(true);
    }, 200);
  };

  const section_progress_class = (value) => {
    if (value <= 50) return 'section-progress';
    else if (value > 50 && value <= 80) return 'section-progress-50';
    else if (value > 80 && value <= 90) return 'section-progress-80';
    else if (value > 90 && value <= 99) return 'section-progress-90';
    else if (value === 100) return 'section-progress-100';
  };

  let isCurrentActiveKey = false;

  const onAccordionCollapse = () => {
    onAccordionClick();
    dispatch(getSectionById(section.id));
    dispatch(getSectionByVideoFromMenu(0));
  };

  const ContextAwareToggle = ({ _, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );

    isCurrentActiveKey = currentEventKey === eventKey;

    const _onClick = () => {
      decoratedOnClick(() => {});
      setTimeout(() => {
        if (!isCurrentActiveKey) {
          const videoTitle = document.getElementById(
            'videoheader' + videos[0]?.id
          );
          if (videoTitle) {
            videoTitle.focus();
          } else {
            const sectionTitle = document.getElementById(
              'section' + (section ? section.id : '0')
            );
            if (sectionTitle) sectionTitle.focus();
          }
        }
      }, 500);
    };

    const getTotal = () => {
      return videos.reduce((a, b) => {
        return a + +b.duration;
      }, 0);
    };

    const getTotalProgress = () => {
      return videos
        .map((e) => e.progresses[0])
        .reduce((a, b) => {
          return +a + ((b && +b.progress) || 0);
        }, 0);
    };

    useEffect(() => {
      const total = (getTotalProgress() / getTotal()) * 100;
      setSectionTotal(
        !isNaN(total) ? (total > 100 ? 100 : Math.ceil(total)) : 0
      );

      if (sectionTotal !== total) {
        setIcon(total >= 100 ? CheckedMarkIcon : UnCheckedMarkIcon);
      }

      // eslint-disable-next-line
    }, [videos]);

    return (
      <>
        {!showKnowledgeChecks && (
          <div
            id={`sec-${section?.id}`}
            ref={ref}
            className={`section-header-container mb-2 ${
              isCurrentActiveKey ? 'section-accordion-open' : ''
            }`}
            onClick={_onClick}
          >
            <div
              id={`collapseSectionHeader${section?.id}`}
              className="section-header"
            >
              <img src={icon} alt="checked mark" aria-hidden={true} />
              <div className="section-name">
                <h2>
                  <button
                    className="btn-no-border"
                    role="button"
                    id={'section' + (section ? section.id : '0')}
                    aria-expanded={isCurrentActiveKey}
                    aria-controls={`collapseSection${section?.id}`}
                  >
                    {name ? name : 'Section Name'}
                  </button>
                </h2>
              </div>
            </div>
            {false ? (
              section.tags && section.tags !== '' ? (
                <div className="tags d-flex align-items-start justify-content-start">
                  {section.tags.split(',').map((tag, i) => {
                    return (
                      <div key={i}>
                        <Badge
                          className="bg-color-accent text-white font-weight-bolder mr-2"
                          bg="primary"
                        >
                          {tag}
                        </Badge>
                      </div>
                    );
                  })}
                </div>
              ) : (
                // OR
                ''
              )
            ) : (
              // OR
              ''
            )}
            {videos && videos.length && videos.length > 0 && !isHelpCenter ? (
              <div className="section-progress-container px-2">
                <ProgressBar
                  className={section_progress_class(sectionTotal)}
                  now={sectionTotal}
                />
                <div
                  className={`section-score ${
                    isCurrentActiveKey ? 'active' : ''
                  }`}
                >
                  Progress{' '}
                  <span className="score">{sectionTotal.toFixed(0)}%</span>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <section
      id={`sectionContainer${section?.id}`}
      aria-label={name + ' sub module'}
    >
      <Card className={`mt-5`}>
        <ContextAwareToggle
          eventKey={eventKey?.toString()}
          callback={onAccordionCollapse}
          transition={false}
        />
        <Accordion.Collapse
          className={`section-item-body`}
          eventKey={eventKey?.toString()}
        >
          <div
            className={`card-body py-0 px-2`}
            id={`collapseSection${section?.id}`}
            aria-labelledby={'section' + (section ? section.id : '0')}
            role="region"
          >
            {children && children}
          </div>
        </Accordion.Collapse>
      </Card>
    </section>
  );
};

export default React.memo(SectionItem);
