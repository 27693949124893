import { BASE_API_URL } from '../../constants/constants';
import { getUserToken } from '../../services/localStorage';
import {
  FETCH_CERTIFICATES_FAILURE,
  FETCH_CERTIFICATES_REQUEST,
  FETCH_CERTIFICATES_SUCCESS,
} from '../types/certificateTypes';

const fetchCertificatesRequest = () => ({
  type: FETCH_CERTIFICATES_REQUEST,
});

const fetchCertificatesSuccess = (certificates) => ({
  type: FETCH_CERTIFICATES_SUCCESS,
  payload: certificates,
});

const fetchCertificatesFailure = (error) => ({
  type: FETCH_CERTIFICATES_FAILURE,
  payload: error,
});

export const getUserCertificates = () => {
  return async (dispatch) => {
    const token = getUserToken();
    const headers = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      dispatch(fetchCertificatesRequest());
      const response = await fetch(
        `${BASE_API_URL}/user_certificates`,
        headers
      );
      if (!response.ok) throw new Error(response.status);

      const jsonResponse = await response.json();
      const certificates = jsonResponse.user_certificates;
      dispatch(fetchCertificatesSuccess(certificates));
    } catch (error) {
      dispatch(fetchCertificatesFailure(error));
    }
  };
};

export const downloadCertificates = async (course_id) => {
  const token = getUserToken();
  const headers = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  return fetch(
    `${BASE_API_URL}/users/course/${course_id}/certificate`,
    headers
  );
};
