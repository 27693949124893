export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const MAIN_DOMAIN = process.env.REACT_APP_MAIN_DOMAIN;
export const CLOUDFRONT_SECURE_PROXY =
  process.env.REACT_APP_CLOUDFRONT_SECURE_PROXY;
export const SUBTITLES_SUBDOMAIN = process.env.REACT_APP_SUBTITLES_SUBDOMAIN;
export const SCREENER_PARENT_SECTION = 135;
export const SCREENER_START = 38;
export const SCREENER_END = 41;
export const ACTIVITY_SCREENER_BASELINE = 23;
export const SCREENER_FAVORITE_BASELINE = 230;
export const SENTRY_TRACE_RATE = process.env.REACT_APP_SENTRY_TRACE_RATE;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const courseStatuses = {
  NOT_STARTED: 'not_started',
  COMPLETED: 'completed',
  IN_PROGRESS: 'in_progress',
};
