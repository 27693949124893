import { COLLAPSE_MENU, OPEN_MENU } from '../types/menuTypes';
import { getModuleById } from './moduleActions';
import { getSectionById } from './sectionActions';
import { LEVELS } from '../../components/Menu';
import { openVideoFromMenu } from './videoActions';

export const openMenu = (level, id) => ({
  type: OPEN_MENU,
  payload: { level, id },
});

export const onCollapseMenu = (collapseAll) => ({
  type: COLLAPSE_MENU,
  payload: collapseAll,
});

export const onOpenMenu = (level, id) => {
  return async (dispatch, getState) => {
    const { courses } = getState().course;

    let course_id = 0;
    let module_id = 0;
    let section_id = 0;

    courses.forEach((course) => {
      if (level === LEVELS.COURSES && course.id === id) {
        course_id = course.id;
      }

      course.module_items.forEach((module) => {
        if (level === LEVELS.MODULES && module.id === id) {
          course_id = course.id;
          module_id = module.id;
        }

        module.section_items.forEach((section) => {
          if (level === LEVELS.SECTIONS && section.id === id) {
            course_id = course.id;
            module_id = module.id;
            section_id = section.id;
          }

          section.videos_items.forEach((video) => {
            if (level === LEVELS.VIDEOS && video.id === id) {
              course_id = course.id;
              module_id = module.id;
              section_id = section.id;
            }
          });
        });
      });
    });

    if (level === LEVELS.VIDEOS) {
      setTimeout(() => dispatch(openMenu(LEVELS.COURSES, course_id)), 100);
      setTimeout(() => dispatch(openMenu(LEVELS.MODULES, module_id)), 110);
      setTimeout(() => dispatch(openMenu(LEVELS.SECTIONS, section_id)), 120);
      setTimeout(() => dispatch(openMenu(LEVELS.VIDEOS, id)), 130);

      setTimeout(
        () => dispatch(openVideoFromMenu(id, section_id ? section_id : 0)),
        140
      );
    }

    if (level === LEVELS.SECTIONS) {
      setTimeout(() => dispatch(openMenu(LEVELS.COURSES, course_id)), 100);
      setTimeout(() => dispatch(openMenu(LEVELS.MODULES, module_id)), 110);
      setTimeout(() => dispatch(openMenu(LEVELS.SECTIONS, section_id)), 120);

      setTimeout(() => dispatch(getSectionById(id)), 130);
    }

    if (level === LEVELS.MODULES) {
      setTimeout(() => dispatch(openMenu(LEVELS.COURSES, course_id)), 100);
      setTimeout(() => dispatch(openMenu(LEVELS.MODULES, module_id)), 110);

      setTimeout(() => dispatch(getModuleById(id)), 120);
    }
  };
};
