import {
  FETCH_MODULE_FAILURE,
  FETCH_MODULE_REQUEST,
  FETCH_MODULE_SUCCESS,
  UPDATE_MODULE_HISTORY,
} from '../types/moduleTypes';
import { requestGetApi } from '../../services/api/api';
import { BASE_API_URL } from '../../constants/constants';
import {
  saveCurrentModuleOnStorage,
  saveCurrentSectionOnStorage,
} from '../../services/localStorage';
import { getSectionsByModule } from './sectionActions';
import { onSelectCurrentVideo } from './videoActions';

const fetchModuleRequest = () => ({
  type: FETCH_MODULE_REQUEST,
});

const fetchModuleSuccess = (module) => ({
  type: FETCH_MODULE_SUCCESS,
  payload: module,
});

const fetchModuleFailure = (error) => ({
  type: FETCH_MODULE_FAILURE,
  payload: error,
});

export const updateModuleHistory = (moduleHistory) => ({
  type: UPDATE_MODULE_HISTORY,
  payload: moduleHistory,
});

export const getModuleById = (id = 0) => {
  return async (dispatch, getState) => {
    // *************************************
    // search sections related to the module
    dispatch(getSectionsByModule(id));

    // if module's id equal zero
    // set currentModule to undefined
    if (id === 0) {
      dispatch(onSelectCurrentModule(undefined));
      dispatch(onSelectCurrentVideo(undefined));
      return;
    }

    // ************************
    // get module from courses
    const { courses } = getState().course;
    if (courses && courses.length) {
      let findModule;
      courses.forEach((course) => {
        course.module_items.forEach((module) => {
          if (module.id === id) {
            findModule = module;
          }
        });
      });

      if (findModule) {
        dispatch(onSelectCurrentModule({ ...findModule }));
        return;
      }
    }

    // **********************
    // get modules from state
    const { currentModule, moduleHistory } = getState().module;
    let history = moduleHistory;

    // search module in state
    if (!history) {
      history = {};
    }

    // if module exists in history
    if (!!history && !!history[id]) {
      dispatch(onSelectCurrentModule({ ...history[id] }));
      return;
    }

    // if currentModule already searched
    // avoid searching again
    if (currentModule && currentModule.id === id) {
      dispatch(onSelectCurrentModule({ ...currentModule }));
      return;
    }

    // fetch currentModule
    try {
      dispatch(fetchModuleRequest());
      const module = await requestGetApi(
        `${BASE_API_URL}/course_modules/${id}`
      );
      dispatch(onSelectCurrentModule({ ...module }));

      // save on history
      history[id] = module;
      dispatch(updateModuleHistory(history));
    } catch (ex) {
      dispatch(fetchModuleFailure(ex.toString()));
    }
  };
};

export const onSelectCurrentModule = (module) => {
  return async (dispatch) => {
    dispatch(fetchModuleSuccess(module));

    if (!module) {
      saveCurrentSectionOnStorage();
      saveCurrentModuleOnStorage(0, 0);
    } else {
      const courseId = module.course ? module.course.id : 0;
      saveCurrentModuleOnStorage(module.id, courseId);
    }
  };
};
