import SurveyQuestion from '../SurveyQuestion';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomSpinner from '../CustomSpinner';
import {
  fetchSurvey,
  resetSurveyData,
  submitAttempt,
} from '../../redux/actions/surveyActions';
import { setGlobalLoading } from '../../redux/actions/dashboardActions';
import { Col, Jumbotron, Modal, Toast } from 'react-bootstrap';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import CustomButton from '../CustomButton';
import { updateHasSurvey } from '../../redux/actions/sectionActions';

const Survey = ({ goBack = () => {} }) => {
  const dispatch = useDispatch();

  const { currentSection } = useSelector((state) => state.section);
  const { surveys, fetchLoading } = useSelector((state) => state.survey);
  const [submittingSurveyID, setSubmittingSurveyID] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messsage, setMessage] = useState('');
  const [modal, setModal] = useState(false);

  const onCloseAlert = () => {
    setShowAlert((prevShowAlert) => !prevShowAlert);
  };

  useEffect(() => {
    return () => {
      dispatch(resetSurveyData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (survey) => {
    // Validate at least one option for question
    // selectedAnswers[survey.id]
    const selectedOptionsCount = selectedAnswers[survey.id];
    const selectedOptionsCountBySurvey = selectedOptionsCount
      ? Object.keys(selectedAnswers[survey.id]).length
      : 0;

    if (selectedOptionsCountBySurvey < survey.survey_questions.length) {
      setShowAlert(true);
      setMessage('Please select an option for each question.');
      return;
    }

    setSubmittingSurveyID(survey.id);
    setIsLoading(true);
    dispatch(setGlobalLoading(true));

    // Map answers
    // let's send selected options for survey
    let localSelectedAnswers = [];
    survey.survey_questions
      .filter((item) => item.id in selectedAnswers[survey.id])
      .forEach((question, index) => {
        localSelectedAnswers.push({
          survey_question_id: question.id,
          survey_question_option_id: selectedAnswers[survey.id][question.id].id,
        });
        // dispatch(submitSurvey(surveyAnswer));
      });

    // let's create a new attempt
    submitAttempt(survey, localSelectedAnswers).then(
      async (response) => {
        setSubmittingSurveyID(0);
        setIsLoading(false);
        dispatch(setGlobalLoading(false));

        if (!response.ok) throw new Error(response.status);

        // const parsed_response = await response.json();

        setModal(true);
      },
      (err) => {
        setSubmittingSurveyID(0);
        setIsLoading(false);
        dispatch(setGlobalLoading(false));

        console.log(err);
      }
    );
  };

  const closeModal = () => {
    setModal(!modal);
    if (surveys?.length <= 1) {
      dispatch(updateHasSurvey());
      goBack();
    } else {
      dispatch(fetchSurvey(currentSection?.id));
    }
  };

  const handleSelectOption = (survey, question, option) => {
    let _selectedAnswers = { ...selectedAnswers };

    // Survey exist in obj
    if (!(survey.id in _selectedAnswers)) {
      _selectedAnswers[survey.id] = {};
    }
    // Question exist in survey
    if (!(question.id in _selectedAnswers[survey.id])) {
      _selectedAnswers[survey.id][question.id] = {};
    }
    // Assign option to question
    _selectedAnswers[survey.id][question.id] = option;

    setSelectedAnswers(_selectedAnswers);
  };

  const sortItem = (a, b) => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  };

  return (
    <>
      <Col className="text-left">
        <CustomButton
          className="custom-button-back"
          label="Back"
          onClick={() => goBack(false)}
          icon={faAngleLeft}
        />
      </Col>

      {fetchLoading ? (
        <CustomSpinner />
      ) : (
        // OR
        ''
      )}

      {surveys.map((survey, index) => {
        return (
          <div key={`survey-${survey?.id}-${index}`} className="row p-3">
            <div className="col-12">
              <h3 className="fw-500 text-color-primary">{survey?.name}</h3>
              {/*<p className="fw-500 border rounded p-3">{survey?.description}</p>*/}
            </div>
            <div className="col-12">
              {survey.survey_questions
                ?.sort(sortItem)
                .map((question, index) => {
                  return (
                    <SurveyQuestion
                      listCount={index}
                      key={index}
                      number={index + 1}
                      question={question}
                      handleSelectOption={(option) => {
                        handleSelectOption(survey, question, option);
                      }}
                    />
                  );
                })}
            </div>

            {surveys?.length ? (
              <div className="col-12 mt-5">
                <div className="custom-submit-container">
                  <button
                    disabled={isLoading}
                    className={`${
                      isLoading && submittingSurveyID === survey.id
                        ? 'disabled'
                        : ''
                    } custom-submit-button w-auto d-flex justify-content-center`}
                    onClick={() => {
                      handleSubmit(survey);
                    }}
                  >
                    {isLoading && submittingSurveyID === survey.id ? (
                      <span className={`mr-2`}>
                        <CustomSpinner />
                      </span>
                    ) : (
                      ''
                    )}
                    <label className="custom-submit-button-text">
                      {isLoading && submittingSurveyID === survey.id
                        ? 'Submitting...'
                        : 'Submit'}
                    </label>
                  </button>
                </div>
              </div>
            ) : (
              // OR
              ''
            )}
          </div>
        );
      })}

      <Modal show={modal} fullscreen={'true'}>
        <Modal.Body>
          <Jumbotron>
            <h1 className={`text-center`}>Thank you!</h1>
            <p className={`text-center`}>
              This is a simple hero unit, a simple jumbotron-style component for
              calling extra attention to featured content or information.
            </p>
          </Jumbotron>
          <button
            className={`custom-submit-button w-100 d-flex justify-content-center`}
            onClick={closeModal}
          >
            <label className="custom-submit-button-text">Close</label>
          </button>
        </Modal.Body>
      </Modal>

      {showAlert ? (
        <Toast
          onClose={onCloseAlert}
          style={{
            position: 'fixed',
            top: 20,
            right: 20,
            backgroundColor: '#fff',
          }}
        >
          <Toast.Header className={`justify-content-between`}>
            <strong className="me-auto">Error</strong>
          </Toast.Header>
          <Toast.Body>{messsage}</Toast.Body>
        </Toast>
      ) : (
        // OR
        ''
      )}
    </>
  );
};

export default Survey;
