// eslint-disable-next-line import/no-cycle
import { LEVELS } from '../../components/Menu';

export const getUser = () => {
  const user = localStorage.getItem('user');
  if (!user) return;
  // eslint-disable-next-line consistent-return
  return JSON.parse(user);
};
export const getUserToken = () => {
  const user = localStorage.getItem('user');
  if (!user) return;
  const userData = JSON.parse(user);
  // eslint-disable-next-line consistent-return
  return userData.token;
};
export const saveCurrentVideoOnStorage = (videoId) => {
  // eslint-disable-next-line no-use-before-define
  let data = getCurrentMenuFromStorage();

  if (!data) data = {};
  data.currentVideo = videoId;

  // save in storage
  localStorage.setItem('current-menu', JSON.stringify(data));
};
export const saveCurrentSectionOnStorage = (section = undefined) => {
  // eslint-disable-next-line no-use-before-define
  let data = getCurrentMenuFromStorage();

  if (!data) data = {};
  data.currentSection = section ? section.id : 0;

  if (section && section.course_module) {
    data.currentModule = section.course_module.id;
    data.currentCourse = section.course_module.course_id;
  }

  // save in storage
  localStorage.setItem('current-menu', JSON.stringify(data));
};
export const saveCurrentModuleOnStorage = (moduleId, courseId) => {
  // eslint-disable-next-line no-use-before-define
  let data = getCurrentMenuFromStorage();

  if (!data) data = {};
  data.currentModule = moduleId;
  data.currentCourse = courseId;

  // save in storage
  localStorage.setItem('current-menu', JSON.stringify(data));
};
export const saveCurrentMenuOnStorage = (level, expanded, id) => {
  // eslint-disable-next-line no-use-before-define
  let data = getCurrentMenuFromStorage();

  // create data if is null
  if (!data) data = {};
  // eslint-disable-next-line no-prototype-builtins
  if (!data.hasOwnProperty(level)) data[level] = [];

  // save current course/module/section if was expanded or not
  if (expanded) data[level].push(id);
  else {
    data[level] = data[level].filter((e) => e !== id);

    // close child
    if (level === LEVELS.COURSES) {
      data[LEVELS.MODULES] = data[level].filter((e) => e !== id);
      data[LEVELS.SECTIONS] = data[level].filter((e) => e !== id);
    }

    // close child
    if (level === LEVELS.MODULES) {
      data[LEVELS.SECTIONS] = data[level].filter((e) => e !== id);
    }
  }

  // save current course
  if (level === LEVELS.COURSES) {
    data.currentCourse = id;
  }

  // save in storage
  data.currentVideo = 0;
  localStorage.setItem('current-menu', JSON.stringify(data));
};
export const getCurrentMenuFromStorage = () => {
  const data = JSON.parse(localStorage.getItem('current-menu'));
  return data || {};
};
