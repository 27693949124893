import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Form, Modal } from 'react-bootstrap';
import appStyle from '../../styles/App.module.scss';
import styles from '../../styles/Dashboard.module.scss';
import signInStyle from '../../styles/SignIn.module.scss';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams
} from 'react-router-dom';
import {
  loginWithEmail,
  logout,
  useAuthContext,
} from '../../context/authContext';
import {
  UserModel,
  check2faEnabled,
  getUserInfoByToken,
  loginWithGoogle,
} from '../../context/authContext/authActions';
import { MAIN_DOMAIN } from '../../constants/constants';
import AlertDismissible from '../AlertDismissible';
import GoogleButton from '../GoogleButton';
import { requestGoogleForm } from '../../services/google/google';
import useEventListener from '../../hooks/useEventListener';
import Logo from '../Logo';
import CustomButton from '../CustomButton';
import CustomSpinner from '../CustomSpinner';
import { BASE_API_URL } from '../../constants/constants';
import CleverButton from '../CleverButton';
import useOauth from '../../hooks/useOauth';
import { SIGNUP_WITH_TOKEN_SUCCESS } from '../../constants/actionTypes';

// ===========================|| SIGN - IN - FORM ||=========================== //

const SingInForm = () => {
  const { no_auth } = useParams();
  const {
    state: { isLoading, error },
    dispatch,
  } = useAuthContext();
  const newWindowRef = useRef(null);
  const history = useHistory();
  const [inputs, setInputs] = useState({ email: '', password: '' });
  const [showAlert, setShowAlert] = useState(false);
  const [show2fa, setShow2fa] = useState(false);
  const [is2faRequested, setIs2faRequested] = useState(false);
  const [isOTPAttemptValid, setIsOtpAttemptValid] = useState(false);
  const [googleUser, setGoogleUser] = useState({});
  const [otpError, setOtpError] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);

  const { globalLoading, user } = useOauth();

  const location = useLocation();

  useEffect(() => {
    const token = location.state ? location.state.token : null;
    if (token) {
      window.history.replaceState(null, '', '/signin');
      getUserInfoByToken(token).then(
        (response) => {
          let userModel = new UserModel(response.user);
          if (
            userModel.schools.length
          ) {
            dispatch({
              type: SIGNUP_WITH_TOKEN_SUCCESS,
              payload: userModel,
            });
            history.push(history.push(location.state && location.state.from ? location.state.from : '/dashboard'));
          }
        },
        (err) => {
          console.error(err);
          setShowAlert(true);
        }
      );
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    try {
      const params = location.state.from.search.split('=');
      params.forEach((param, i) => {
        if (param.indexOf('confirmed') !== -1) setEmailConfirmed(true);
      });
      window.history.replaceState(null, '');
    } catch (e) { }
    // eslint-disable-next-line
  }, [location.search]);

  const onMessageReceiveFromBackend = React.useCallback(
    async (event) => {
      if (event.origin.startsWith(MAIN_DOMAIN)) {
        if (event.data.user) {
          setGoogleUser(event.data.user);
          const enabled2fa = event.data.user.otp_required_for_login;
          if (newWindowRef?.current?.window) {
            newWindowRef.current.window.close();
          }

          // Show form if 2FA is required and OTP has not been entered yet.
          if (enabled2fa && is2faRequested) {
            setShow2fa(true);
            return;
          }

          // Only login to google if OTP is not required
          setIs2faRequested(enabled2fa);
          setShow2fa(false);

          const data = await loginWithGoogle(dispatch, event.data.user);

          if (!data) {
            setShowAlert(true);
          }

          window.scrollTo(0, 0);
          history.push(history.push(location.state && location.state.from ? location.state.from : '/dashboard'));
        }
      } else {
        // console.log(`Event with unrecognized domain!`, event);
      }
    },
    [dispatch, history, is2faRequested]
  );

  useEventListener(onMessageReceiveFromBackend);

  const _requestGoogleSigninForm = async () => {
    newWindowRef.current = requestGoogleForm();
    setIs2faRequested(true);
  };

  const handleLoginWithEmail = useCallback(
    async (e) => {
      if (typeof e !== 'undefined') {
        e.preventDefault();
      }

      if (is2faRequested) {
        setShow2fa(true);
        return;
      }

      const { email, password, otp_attempt } = inputs;
      const payload = { user: { email, password, otp_attempt } };
      setShow2fa(false);
      setIs2faRequested(await check2faEnabled(email));
      const data = await loginWithEmail(dispatch, payload);
      inputs['otp_attempt'] = '';
      setIsOtpAttemptValid(false);

      if (!data) {
        setShowAlert(true);
      }

      if (!data?.user) return;

      window.scrollTo(0, 0);
      history.push(history.push(location.state && location.state.from ? location.state.from : '/dashboard'));
    },
    [dispatch, history, inputs, is2faRequested]
  );

  const onChangeHandler = async (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
    if (name === 'email') {
      setIs2faRequested(await check2faEnabled(value));
    }
    if (name === 'otp_attempt') {
      setIsOtpAttemptValid(value.length >= 6);
    }
  };

  useEffect(() => {
    if (no_auth) {
      setShowAlert(true);
      window.history.replaceState(null, '', '/signin');
    }
  }, [no_auth]);

  const onCloseAlert = () => {
    setShowAlert(!showAlert);
  };
  const ErrorMessage = () => {
    return (
      <AlertDismissible
        onCloseAlert={onCloseAlert}
        showAlert={showAlert}
        content={`${error
          ? error?.email
            ? 'Invalid email'
            : error?.password
              ? 'Invalid password'
              : 'Invalid credentials'
          : 'Unexpected error'
          }`}
      />
    );
  };

  const hide2fa = () => {
    setShow2fa(false);
  };

  const handleCheck2fa = async (e) => {
    e.preventDefault();
    setIs2faRequested(false);

    if (googleUser.email) {
      const headers = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          user: {
            email: googleUser.email,
            otp_attempt: inputs['otp_attempt'],
          },
        }),
      };
      const otpResponse = await fetch(
        `${BASE_API_URL}/users/check_otp`,
        headers
      );
      const otpResult = await otpResponse.json();
      if (otpResult && otpResult.valid) {
        setIs2faRequested(true);
        setShow2fa(false);
        inputs['otp_attempt'] = '';
        await loginWithGoogle(dispatch, googleUser);
        window.scrollTo(0, 0);
        history.push('/dashboard');
      } else {
        hide2fa();
        setOtpError(true);
        setShowAlert(true);
        setIs2faRequested(true);
        setShow2fa(false);
        inputs['otp_attempt'] = '';
      }
    }
  };

  useEffect(() => {
    if (!is2faRequested && isOTPAttemptValid && !googleUser.email) {
      handleLoginWithEmail();
    }
  }, [
    is2faRequested,
    isOTPAttemptValid,
    googleUser,
    dispatch,
    handleLoginWithEmail,
  ]);

  // remove old session
  useEffect(() => {
    logout(dispatch);

    // eslint-disable-next-line
  }, []);

  const LoginWithGoogleButton = () => {
    return (
      <GoogleButton
        onClick={_requestGoogleSigninForm}
        text="Sign in with Google"
      />
    );
  };

  const LoginWithCleverButton = () => {
    return <CleverButton onClick={cleaverLogin} text="Sign in with Clever" />;
  };

  const cleaverLogin = (ev) => {
    window.location.href =
      'https://schools.clever.com/applications/add/bounceback-dev';
  };

  // Define deferredPrompt state variable using useState
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  // Function to handle showing the install prompt
  const showInstallPrompt = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  // Effect hook to listen for beforeinstallprompt event
  useEffect(() => {
    const beforeInstallPromptHandler = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    };
  }, []);

  function checkIfSafariOriOS() {
    const userAgent = navigator.userAgent;
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
    const isiOS = /iPad|iPhone|iPod/.test(userAgent);

    if (isSafari || isiOS) {
      return true;
    } else {
      return false;
    }
  }


  return (
    <>
      {user && !user.schools?.length ? (
        user.provider === 'clever' ? (
          <Redirect
            to={{ pathname: '/signup/clever', state: { user: user } }}
          />
        ) : (
          <Redirect
            to={{ pathname: '/signup/google', state: { user: user } }}
          />
        )
      ) : (
        <div>
          <div className={signInStyle['form-wrapper']}>
            <div className={appStyle['form-header-container']}>
              <Logo />
            </div>

            {/* TODO: restore google and clever links */}
            <div className="text-center mt-4">
              {/*<div className="mb-3">
                <LoginWithGoogleButton />
              </div>
              <LoginWithCleverButton />*/}
            </div>

            <p className="font-poppins my-4 font-18 fw-500 text-center text-color-primary">
              {/* TODO: restore oauth buttons after text */}
              {/*or continue with your email*/}
            </p>

            {(error || otpError || no_auth) && <ErrorMessage />}
            <Form onSubmit={handleLoginWithEmail}>
              <div className={signInStyle['b-form-body']}>
                <Form.Group
                  controlId="formBasicEmail"
                  className={signInStyle['custom-form-group']}
                >
                  <Form.Label srOnly={true}>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    required
                    name="email"
                    htmlFor="email"
                    pattern={'[A-Za-z0-9\._\\%\\+\\-]+@[A-Za-z0-9\\.\\-]+.[A-Za-z]{2,}$'}
                    value={inputs['email']}
                    onChange={onChangeHandler}
                    title="Enter a valid email address"
                    placeholder="Email"
                  />
                </Form.Group>
                <Form.Group
                  controlId="formBasicPassword"
                  className={signInStyle['custom-form-group']}
                >
                  <Form.Label srOnly={true}>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    htmlFor="password"
                    required
                    value={inputs['password']}
                    onChange={onChangeHandler}
                    placeholder="Password"
                  />
                </Form.Group>
              </div>
              <div className={signInStyle['check-box-wrapper']}>
                <div className={signInStyle['check-box-check-container']}>
                  <Form.Check
                    id={`remember`}
                    type="checkbox"
                    tabIndex={0}
                    style={{ visibility: 'visible' }}
                    className={signInStyle['custom-checkbox']}
                    label="Remember me"
                  />
                </div>
                <Link
                  to="/reset-password"
                  className="font-poppins font-14 fw-500 text-color-accent"
                >
                  Forgot password?
                </Link>
              </div>

              {isLoading && (
                <div className={`my-2`}>
                  <CustomSpinner />
                </div>
              )}

              <div className="text-center mb-4">
                <CustomButton
                  disabled={!inputs.email || !inputs.password}
                  label="Sign in"
                  className={`custom-button-default ${signInStyle['width-50']
                    } font-poppins ${signInStyle['border-radius-12']} ${!inputs.email || !inputs.password ? 'disabled' : ''
                    }`}
                  type="submit"
                />
              </div>

              <div className="text-center pt-2">
                <p className="font-poppins font-18 fw-500 text-color-accent">
                  Don’t have an account?{' '}
                  <span>
                    <Link
                      to="/signup"
                      className="font-poppins font-18 fw-500 text-color-accent"
                    >
                      SIGN UP
                    </Link>
                  </span>
                </p>
              </div>

              <div className="text-center mt-4">
                <Link
                  to="#"
                  onClick={() => {
                    if (checkIfSafariOriOS()) {
                      alert('To install the app on iOS, please share or add it to your home screen.');
                    } else {
                      showInstallPrompt();
                    }
                  }}
                  className="font-poppins font-18 fw-500 text-color-accent"
                >
                  Install App
                </Link>
              </div>

              <div className="text-center">
                {emailConfirmed ? (
                  <div className={`alert alert-info p-1`}>
                    <p className="m-0 fw-500 font-poppins text-color-accent">
                      Your email address has been confirmed. You can now login
                      to Bounceback
                    </p>
                  </div>
                ) : (
                  // OR
                  ''
                )}
              </div>
            </Form>

            <Modal id="modal2fa" show={show2fa} onHide={hide2fa}>
              <Modal.Header closeButton>
                <Modal.Title>Two Factor Authentication</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleCheck2fa}>
                  <div className={appStyle['b-form-body']}>
                    <Form.Group
                      controlId="form_otp_attempt"
                      className={appStyle['custom-form-group']}
                    >
                      <div className={appStyle['form-custom-label']}>
                        Please enter your OTP code
                      </div>
                      <Form.Control
                        type="text"
                        name="otp_attempt"
                        required
                        value={inputs['otp_attempt']}
                        onChange={onChangeHandler}
                      />
                    </Form.Group>
                  </div>
                  <CustomButton
                    label="Sign In"
                    disabled={!isOTPAttemptValid}
                    className={
                      'custom-button-default ' +
                      (isOTPAttemptValid ? '' : 'disabled')
                    }
                    onClick={handleCheck2fa}
                  />
                </Form>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      )}

      {/*Spinner or Blocking Modal*/}
      {globalLoading ? (
        <div className="loading">
          <div className="loader"></div>
        </div>
      ) : (
        ''
      )}

      <div className={styles['dashboard-footer']}>
        <p className="font-13 font-poppins .text-color-tertiary m-0">
          All Rights Reserved © {new Date().getFullYear()} Bounceback LLC
          <span className="text-color-secondary font-weight-bolder mx-2">
            |
          </span>{' '}
          <a
            className={`font-16 font-poppins text-color-tertiary text-decoration-none`}
            target="_blank"
            href="https://www.mybounceback.com/privacy/"
          >
            Privacy Policy
          </a>
        </p>

        <p className="font-16 font-poppins text-color-tertiary m-0">
          Need Help?{' '}
          <span className="text-color-secondary font-weight-bolder mx-2">
            |
          </span>{' '}
          <a
            className={`font-16 font-poppins text-color-tertiary text-decoration-none`}
            href="mailto:support@mybounceback.com"
          >
            support@mybounceback.com
          </a>
        </p>
      </div>
    </>
  );
};

export default SingInForm;
