import {
  FETCH_KNOWLEDGE_CHECK_FAILURE,
  FETCH_KNOWLEDGE_CHECK_REQUEST,
  FETCH_KNOWLEDGE_CHECK_SUCCESS,
  HIDE_KNOWLEDGE_CHECKS,
  POST_KNOWLEDGE_CHECK_FAILURE,
  POST_KNOWLEDGE_CHECK_REQUEST,
  POST_KNOWLEDGE_CHECK_SUCCESS,
} from '../types/knowledgeCheckTypes';
import { getUserToken } from '../../services/localStorage';
import { BASE_API_URL } from '../../constants/constants';

const fetchKnowledgeCheckRequest = () => ({
  type: FETCH_KNOWLEDGE_CHECK_REQUEST,
});

const fetchKnowledgeCheckSuccess = (knowledgeChecks) => ({
  type: FETCH_KNOWLEDGE_CHECK_SUCCESS,
  payload: knowledgeChecks,
});

const fetchKnowledgeCheckFailure = (error) => ({
  type: FETCH_KNOWLEDGE_CHECK_FAILURE,
  payload: error,
});

export const hideKnowledgeCheck = () => ({
  type: HIDE_KNOWLEDGE_CHECKS,
});

export const postKnowledgeCheckRequest = () => ({
  type: POST_KNOWLEDGE_CHECK_REQUEST,
});

export const postKnowledgeCheckSuccess = (
  knowledgeCheckAnswers,
  answersList,
  screenerDone
) => ({
  type: POST_KNOWLEDGE_CHECK_SUCCESS,
  payload: { knowledgeCheckAnswers, answersList, screenerDone },
});

export const postKnowledgeCheckFailure = (error) => ({
  type: POST_KNOWLEDGE_CHECK_FAILURE,
  payload: error,
});

export const fetchKnowledgeCheck = (module_section_id) => {
  return async (dispatch) => {
    try {
      const token = getUserToken();
      const headers = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };

      dispatch(fetchKnowledgeCheckRequest());
      const response = await fetch(
        `${BASE_API_URL}/knowledge_checks?module_section_id=${module_section_id}`,
        headers
      );

      if (!response.ok) dispatch(fetchKnowledgeCheckFailure(response.status));

      const knowledgeCheckResponse = await response.json();
      const knowledgeCheck = knowledgeCheckResponse.knowledge_checks;
      dispatch(fetchKnowledgeCheckSuccess(knowledgeCheck || []));
    } catch (error) {
      dispatch(fetchKnowledgeCheckFailure(error));
    }
  };
};

export const submitKnowledgeCheck = (
  knowledgeContent,
  answersList,
  isScreener
) => {
  return async (dispatch) => {
    if (isScreener === true) {
      dispatch(postKnowledgeCheckRequest());
      dispatch(postKnowledgeCheckSuccess([], answersList, true));
      return;
    }

    const token = getUserToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ knowledge_check_answers: knowledgeContent }),
    };
    try {
      dispatch(postKnowledgeCheckRequest());
      const response = await fetch(
        `${BASE_API_URL}/knowledge_check_answers`,
        requestOptions
      );

      if (!response.ok) throw new Error(response.status);
      const knowledge_check_answers = await response.json();
      dispatch(
        postKnowledgeCheckSuccess(
          knowledge_check_answers || [],
          answersList,
          false
        )
      );
    } catch (error) {
      dispatch(postKnowledgeCheckFailure(error));
    }
  };
};
