import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import CollapseAll from '../../assets/icons/collapse-all.svg';
import {
  getCurrentMenuFromStorage,
  saveCurrentMenuOnStorage,
  saveCurrentVideoOnStorage,
} from '../../services/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import { onCollapseMenu, openMenu } from '../../redux/actions/menuActions';
import { hideKnowledgeCheck } from '../../redux/actions/knowledgeCheckActions';
import { onSearchElement } from '../../redux/actions/searchActions';
import {
  onOpenFavoriteSection,
  onSelectedFavoriteVideosSuccess,
} from '../../redux/actions/favoriteActions';
import { getSectionById } from '../../redux/actions/sectionActions';
import { getModuleById } from '../../redux/actions/moduleActions';
import {
  getVideoById,
  openVideoFromMenu,
} from '../../redux/actions/videoActions';

const COURSE_LEVEL = 1;
const MODULE_LEVEL = 2;
const SECTION_LEVEL = 3;
const VIDEO_LEVEL = 4;

const capitalizeFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const LEVELS = {
  COURSES: COURSE_LEVEL,
  MODULES: MODULE_LEVEL,
  SECTIONS: SECTION_LEVEL,
  VIDEOS: VIDEO_LEVEL,
  CURRENT_VIDEO: 'currentVideo',
  CURRENT_COURSE: 'currentCourse',
  CURRENT_MODULE: 'currentModule',
  CURRENT_SECTION: 'currentSection',
};
const level_classes = {
  [COURSE_LEVEL]: 'is-course',
  [MODULE_LEVEL]: 'is-module',
  [SECTION_LEVEL]: 'is-section',
  [VIDEO_LEVEL]: 'is-video',
};
const level_items_props = {
  [COURSE_LEVEL]: 'module_items',
  [MODULE_LEVEL]: 'section_items',
  [SECTION_LEVEL]: 'videos_items',
};
export const MenuItem = ({
  item,
  isFlatMenu,
  level,
  onSelectMenu,
  collapseAll,
  isScreener,
  screenerVideo,
}) => {
  const dispatch = useDispatch();

  const { searchValue } = useSelector((state) => state.search);
  const { campaign_content } = useSelector((state) => state.campaign);
  const { openMenuId, openMenuLevel } = useSelector((state) => state.menu);

  const [expanded, setExpanded] = React.useState(item?.expanded ?? true);
  const [isModuleOpen, setIsModuleOpen] = React.useState(item?.isModuleOpen);
  const [isSectionOpen, setIsSectionOpen] = React.useState(item?.isSectionOpen);

  const getItems = () => {
    return level_items_props[level];
  };

  let hasSubmenu = item[getItems()] && item[getItems()].length;

  useEffect(() => {
    if (openMenuId === item.id && openMenuLevel === level) {
      if (openMenuLevel !== LEVELS.VIDEOS) {
        toggle(null, item, true);
      } else {
        toggleVideo(null, item, false);
      }

      dispatch(openMenu(0, 0));
    }
    // eslint-disable-next-line
  }, [openMenuId, openMenuLevel]);

  useEffect(() => {
    if (expanded) {
      toggle(undefined, item, !expanded);
    }

    // eslint-disable-next-line
  }, [collapseAll]);

  useEffect(() => {
    let data = getCurrentMenuFromStorage();
    setExpanded(data && data[level] && data[level].some((e) => e === item.id));

    // for styles
    if (level === LEVELS.COURSES) setExpanded(true);
    if (level === LEVELS.MODULES)
      setIsModuleOpen(
        data && data[level] && data[level].some((e) => e === item.id)
      );
    if (level === LEVELS.SECTIONS)
      setIsSectionOpen(
        data && data[level] && data[level].some((e) => e === item.id)
      );

    // eslint-disable-next-line
  }, []);

  const toggle = (e, module, isExpanded) => {
    if (e) e.stopPropagation();
    // close favorite section if is open
    dispatch(onSelectedFavoriteVideosSuccess(undefined));
    dispatch(onOpenFavoriteSection(false));

    // if (!hasSubmenu && !hasSurvey) return;

    // set expanded and save in storage
    if (hasSubmenu) setExpanded(isExpanded);
    if (level === LEVELS.MODULES) setIsModuleOpen(isExpanded);
    else if (level === LEVELS.SECTIONS) setIsSectionOpen(isExpanded);

    saveCurrentMenuOnStorage(
      level,
      isExpanded,
      module.id,
      level === LEVELS.SECTIONS ? module.course_module_id : 0
    );

    if (searchValue !== '') {
      dispatch(onSearchElement(''));
    }

    if (level === LEVELS.COURSES && !isExpanded) {
      dispatch(getModuleById());
      dispatch(getVideoById());
    }

    if (level === LEVELS.MODULES) {
      dispatch(getModuleById(isExpanded ? module.id : 0));
    }

    if (level === LEVELS.SECTIONS) {
      dispatch(getSectionById(isExpanded ? module.id : 0));
    }
  };
  const toggleVideo = (e, video, isScreener) => {
    if (e && !isScreener) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (isScreener) {
      return;
    }

    dispatch(onSelectedFavoriteVideosSuccess(undefined));
    dispatch(onOpenFavoriteSection(false));
    dispatch(hideKnowledgeCheck());

    dispatch(openVideoFromMenu(video.id));
    saveCurrentVideoOnStorage(video.id);

    if (searchValue !== '') {
      dispatch(onSearchElement(''));
    }
  };

  const sortItem = (a, b) => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  };

  let renderChild = null;
  if (item[getItems()] && item[getItems()].length && expanded) {
    let lastVideo =
      item[getItems()].sort(sortItem)[item[getItems()].length - 1];
    renderChild = (
      <ul className={clsx('menu', { isFlatMenu, isModuleOpen, isSectionOpen })}>
        {item[getItems()].sort(sortItem).map((child, i) => (
          <MenuItem
            isFlatMenu={isFlatMenu}
            item={child}
            collapseAll={collapseAll}
            onSelectMenu={onSelectMenu}
            level={level + 1}
            key={`${child.name || child.title}-item-${i * 10}`}
          />
        ))}
        {level === SECTION_LEVEL &&
        item['screeners_items'] &&
        item['screeners_items'].length > 0 &&
        expanded
          ? item['screeners_items'].map((child, i) => (
              <MenuItem
                isFlatMenu={true}
                item={child}
                collapseAll={collapseAll}
                onSelectMenu={onSelectMenu}
                level={level + 1}
                isScreener={true}
                screenerVideo={lastVideo}
                key={`${child.screener_type}-item-${i * 10}`}
              />
            ))
          : ''}
      </ul>
    );
  }
  const nameLink =
    item.name ||
    item.title ||
    (item.screener_type == 'mindfulness'
      ? 'Are You Mindful?'
      : item.screener_type == 'anxiety'
      ? 'Ever Feel Anxious?'
      : item.screener_type == 'activity'
      ? 'Barriers to Being Active'
      : item.screener_type == 'sel_check_in'
      ? 'SEL Check-In'
      : item.screener_type == 'sel_skills'
      ? 'Rate Your SEL Skills'
      : 'Measure Your Stress');
  return (
    <>
      {level === LEVELS.SECTIONS &&
      campaign_content?.allowed_videos_ids?.length &&
      !campaign_content?.allowed_videos_ids?.includes(
        item[getItems()][0]?.id
      ) ? (
        <>
          <li
            className={clsx('menu-item inactive-video', {
              expanded,
              hasSubmenu,
              isFlatMenu,
              isModuleOpen,
              isSectionOpen,
            })}
          >
            <div
              id={
                level === LEVELS.COURSES
                  ? `course-menu-${item.id}`
                  : level === LEVELS.MODULES
                  ? `module-menu-${item.id}`
                  : level === LEVELS.SECTIONS
                  ? `section-menu-${item.id}`
                  : `video-menu-${item.id}`
              }
              className="menu-item-title"
            >
              <a
                href="#"
                className="course-name"
                role="button"
                aria-expanded={expanded}
              >
                {nameLink}
              </a>
            </div>
          </li>
          {renderChild}
        </>
      ) : (
        // OR

        <>
          <li
            className={clsx(`menu-item ${level_classes[level]}`, {
              expanded,
              hasSubmenu,
              isFlatMenu,
              isModuleOpen,
              isSectionOpen,
            })}
          >
            <div
              id={
                level === LEVELS.COURSES
                  ? `course-menu-${item.id}`
                  : level === LEVELS.MODULES
                  ? `module-menu-${item.id}`
                  : level === LEVELS.SECTIONS
                  ? `section-menu-${item.id}`
                  : `video-menu-${item.id}`
              }
              className="menu-item-title"
              onClick={
                level !== LEVELS.VIDEOS
                  ? (e) => toggle(e, item, !expanded)
                  : (e) => {
                      isScreener
                        ? toggleVideo(e, screenerVideo, true)
                        : toggleVideo(e, item, false);
                    }
              }
            >
              <a
                href={isScreener ? '#screenerHeader' + item.id : '#'}
                className="course-name"
                role="button"
                aria-expanded={expanded}
              >
                {nameLink}
              </a>
              <a
                href="#"
                aria-hidden="true"
                tabIndex="-1"
                style={{ visibility: hasSubmenu ? 'visible' : 'hidden' }}
                className="course-arrow"
              ></a>
            </div>
          </li>

          {renderChild}
        </>
      )}
    </>
  );
};

const Menu = ({ items, isFlatMenu, onSelectMenu }) => {
  const dispatch = useDispatch();
  const [collapseAll, setCollapseAll] = useState(false);
  const { collapseAll: collapseMenu } = useSelector((state) => state.menu);

  useEffect(() => {
    if (collapseMenu) {
      setCollapseAll(!collapseAll);
      dispatch(onCollapseMenu(false));
    }
    // eslint-disable-next-line
  }, [collapseMenu]);

  const sortItem = (a, b) => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  };

  return !items?.length ? null : (
    <>
      <button
        className="btn btn-sm collapse-all-btn pl-0 d-flex align-items-center font-inter"
        onClick={() => setCollapseAll(!collapseAll)}
      >
        <img
          aria-hidden={true}
          src={CollapseAll}
          alt="collapse-all"
          className="mr-2 font-poppins font-16"
          width={12}
          height={12}
        />{' '}
        Collapse All
      </button>
      <ul className={clsx('menu', { isFlatMenu })}>
        {items.sort(sortItem).map((course) => {
          return (
            <MenuItem
              key={`menu-item-${course.id}`}
              item={course}
              level={LEVELS.COURSES}
              isFlatMenu={isFlatMenu}
              collapseAll={collapseAll}
              onSelectMenu={onSelectMenu}
            />
          );
        })}
      </ul>
    </>
  );
};

export default Menu;
