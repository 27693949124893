import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';
import SignInForm from '../components/SignIn';
import SignupForm from '../components/SignUp';
import ResetPassword from '../components/ResetPassword';
import ConfirmPassword from '../components/ConfirmPassword';

import Dashboard from '../pages/Dashboard/Dashboard';
import PageNotFound from '../pages/PageNotFound';
import TermsOfService from '../components/TermsOfService';
import CampaignPage from '../pages/Campaign/CampaignPage';
import CampaignVideoPage from '../pages/Campaign/CampaignVideoPage';
import SignUpOauthForm from '../components/SignUpOauth';
import HelpCenter from '../pages/HelpCenter/HelpCenter';
import CustomUrl from '../components/CustomUrl/CustomUrl';
import Unsubscribe from '../components/Unsubscribe/Unsubscribe';

export const history = require('history').createBrowserHistory;

const Routes = () => {
  return (
    <Switch>
      <PublicRoute exact path="/" component={SignInForm} />
      <PublicRoute exact path="/signin" component={SignInForm} />
      <PublicRoute exact path="/signin/:no_auth" component={SignInForm} />
      <PublicRoute exact path="/signup" component={SignupForm} />
      <PublicRoute exact path="/signup/google" component={SignUpOauthForm} />
      <PublicRoute exact path="/signup/clever" component={SignUpOauthForm} />
      <PublicRoute exact path="/oauth/:token" component={SignInForm} />
      <PublicRoute exact path="/reset-password" component={ResetPassword} />
      <PublicRoute exact path="/terms-of-service" component={TermsOfService} />
      <PublicRoute path="/reset_password" component={ConfirmPassword} />
      <PublicRoute exact path="/campaigns" component={CampaignPage} />
      <PublicRoute
        exact
        path="/campaigns/:campaign_id/video/:video_id"
        component={CampaignVideoPage}
      />
      <PrivateRoute exact path="/opt-out" component={Unsubscribe} />
      <PrivateRoute
        exact
        path="/c/:param1/:model/:param2"
        component={CustomUrl}
      />
      <PrivateRoute
        exact
        path="/require-new-password"
        component={ConfirmPassword}
      />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/help" component={HelpCenter} />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
