import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserInfoByToken,
  UserModel,
} from '../context/authContext/authActions';
import { useHistory, useParams } from 'react-router-dom';
import { setGlobalLoading } from '../redux/actions/dashboardActions';
import { SIGNUP_WITH_TOKEN_SUCCESS } from '../constants/actionTypes';
import { useAuthContext } from '../context/authContext';
import { useLocation } from 'react-router-dom';

const useOauth = () => {
  const { token } = useParams();
  const history = useHistory();
  const [user, setUser] = useState(null);

  const dispatch = useDispatch();
  const { dispatch: authDispatch } = useAuthContext();
  const location = useLocation();

  const { globalLoading } = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (token) {
      window.history.replaceState(null, '', '/oauth/sso');
      dispatch(setGlobalLoading(true));
      getUserInfoByToken(token).then(
        (response) => {
          let userModel = new UserModel(response.user);
          setUser(userModel);
          dispatch(setGlobalLoading(false));

          if (
            userModel.schools.length &&
            (userModel.provider === 'clever' ||
              userModel.provider === 'google_oauth2')
          ) {
            authDispatch({
              type: SIGNUP_WITH_TOKEN_SUCCESS,
              payload: userModel,
            });
            history.push(history.push(location.state && location.state.from ? location.state.from : '/dashboard'));
          }
        },
        (err) => {
          dispatch(setGlobalLoading(true));
        }
      );
    }
    // eslint-disable-next-line
  }, [token]);

  return {
    globalLoading,
    user,
  };
};

export default useOauth;
