import React from 'react';
import { Spinner } from 'react-bootstrap';

const CustomSpinner = ({ label }) => {
  return (
    <div className="custom-spinner-container">
      <Spinner animation="border" />
      {label && <span>{label}</span>}
    </div>
  );
};

export default CustomSpinner;
