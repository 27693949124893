import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import appStyle from '../../styles/App.module.scss';
import CustomButton from '../CustomButton';

const CustomModal = (props) => {
  const {
    isOpen,
    isTCAccepted,
    handleClose,
    modalTitle,
    modalContent,
    showFooter,
    fullscreen,
    onAcceptTC,
  } = props;

  const [isTermsAccepted, setIsTermsAccepted] = useState(isTCAccepted);

  useEffect(() => {
    setIsTermsAccepted(isTCAccepted);
  }, [isTCAccepted]);

  const _onAcceptTC = () => {
    onAcceptTC(isTermsAccepted);
    handleClose();
  };
  const onDeclineTC = () => {
    onAcceptTC(false);
    setIsTermsAccepted(false);
    handleClose();
  };
  return (
    <Modal
      className={`custom-modal-app`}
      show={isOpen}
      fullscreen={fullscreen}
      onHide={handleClose}
      size="lg"
    >
      <section className="flex-1-container">
        <Modal.Header className={`${appStyle['custom-modal-header']} row`}>
          {modalTitle && (
            <div className={appStyle['custom-modal-title']}>{modalTitle}</div>
          )}
        </Modal.Header>
        {modalContent && (
          <Modal.Body className={`flex-1-container`}>{modalContent}</Modal.Body>
        )}

        {showFooter && (
          <div className={`pb-3 footer-cnt`}>
            <div className={appStyle['terms-and-conditions-footer']}>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  className={
                    `cursor-pointer separate ` + appStyle['tac-checkbox']
                  }
                  onChange={() => setIsTermsAccepted(!isTermsAccepted)}
                  checked={isTermsAccepted}
                  type="checkbox"
                  label=" I have read and agree to Bounceback’s Terms & Conditions."
                />
              </Form.Group>
            </div>
            <div className={appStyle['terms-and-conditions-types']}>
              <div className="d-flex align-items-center justify-content-center">
                <CustomButton
                  className="custom-button-white disabled p-12 mr-3"
                  label="Decline"
                  onClick={onDeclineTC}
                />
                <CustomButton
                  disabled={!isTermsAccepted}
                  className={`custom-button-white p-12 mr-3 ${
                    !isTermsAccepted ? 'disabled' : ''
                  }`}
                  label="Accept"
                  onClick={_onAcceptTC}
                />
              </div>
            </div>
          </div>
        )}
      </section>
    </Modal>
  );
};
CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  modalContent: PropTypes.any,
  showFooter: PropTypes.bool,
  fullscreen: PropTypes.bool,
};

export default CustomModal;
