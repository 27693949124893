export const LOGIN_WITH_EMAIL_REQUEST = 'LOGIN_WITH_EMAIL_REQUEST';
export const LOGIN_WITH_EMAIL_SUCCESS = 'LOGIN_WITH_EMAIL_SUCCESS';
export const LOGIN_WITH_EMAIL_ERROR = 'LOGIN_WITH_EMAIL_ERROR';

export const SIGNUP_WITH_EMAIL_REQUEST = 'SIGNUP_WITH_EMAIL_REQUEST';
export const SIGNUP_WITH_EMAIL_SUCCESS = 'SIGNUP_WITH_EMAIL_SUCCESS';
export const SIGNUP_WITH_EMAIL_ERROR = 'SIGNUP_WITH_EMAIL_ERROR';

export const SIGNUP_WITH_TOKEN_SUCCESS = 'SIGNUP_WITH_TOKEN_SUCCESS';
export const SIGNUP_WITH_TOKEN_ERROR = 'SIGNUP_WITH_TOKEN_ERROR';

export const LOGIN_WITH_GOOGLE_REQUEST = 'LOGIN_WITH_GOOGLE_REQUEST';
export const LOGIN_WITH_GOOGLE_SUCCESS = 'LOGIN_WITH_GOOGLE_SUCCESS';
export const LOGIN_WITH_GOOGLE_ERROR = 'LOGIN_WITH_GOOGLE_ERROR';

export const RESET_PASSWORD_SUCCESS_EMAIL = 'RESET_PASSWORD_SUCCESS_EMAIL';

export const RESET_PASSWORD_UPDATE_REQUEST = 'RESET_PASSWORD_UPDATE_REQUEST';
export const RESET_PASSWORD_UPDATE_SUCCESS_EMAIL =
  'RESET_PASSWORD_UPDATE_SUCCESS_EMAIL';
export const RESET_PASSWORD_UPDATE_ERROR_EMAIL =
  'RESET_PASSWORD_UPDATE_ERROR_EMAIL';

export const PASSWORD_UPDATE_REQUEST = 'PASSWORD_UPDATE_REQUEST';
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_ERROR = 'PASSWORD_UPDATE_ERROR';

export const REGISTRATION_CODE_UPDATE_REQUEST =
  'REGISTRATION_CODE_UPDATE_REQUEST';
export const REGISTRATION_CODE_UPDATE_SUCCESS =
  'REGISTRATION_CODE_UPDATE_SUCCESS';
export const REGISTRATION_CODE_UPDATE_ERROR = 'REGISTRATION_CODE_UPDATE_ERROR';

export const LOGOUT = 'LOGOUT';
