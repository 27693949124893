import {
  SEARCH_ELEMENT_FAILURE,
  SEARCH_ELEMENT_REQUEST,
  SEARCH_ELEMENT_SUCCESS,
} from '../types/searchTypes';
import { requestGetApi } from '../../services/api/api';
import { BASE_API_URL } from '../../constants/constants';

const searchElementRequest = (searchValue) => ({
  type: SEARCH_ELEMENT_REQUEST,
  payload: searchValue,
});

const searchElementSuccess = ({
  courses,
  course_modules,
  module_sections,
  module_section_videos,
}) => ({
  type: SEARCH_ELEMENT_SUCCESS,
  payload: { courses, course_modules, module_sections, module_section_videos },
});

const searchElementFailure = (error) => ({
  type: SEARCH_ELEMENT_FAILURE,
  payload: error,
});

export const onSearchElement = (
  searchValue,
  favoriteSectionIsOpen = false,
  isHelpCenter = false
) => {
  return async (dispatch) => {
    dispatch(searchElementRequest(searchValue));

        const search_by = (!favoriteSectionIsOpen && !isHelpCenter)
          ? '1'
          : favoriteSectionIsOpen ? '1' : '3';

    try {
      if (searchValue !== '') {
        requestGetApi(
          `${BASE_API_URL}/module_section_videos/global_search?search=${searchValue}&search_by=${search_by}`
        ).then(
          (data) => {
            dispatch(
              searchElementSuccess({
                courses: [],
                course_modules: [],
                module_sections: [],
                module_section_videos: data.module_section_videos,
              })
            );
          },
          () => {
            dispatch(searchElementFailure('Error searching elements'));
          }
        );
      } else {
        dispatch(
          searchElementSuccess({
            courses: [],
            course_modules: [],
            module_sections: [],
            module_section_videos: [],
          })
        );
      }
    } catch (ex) {
      dispatch(searchElementFailure('Error searching elements'));
    }
  };
};
