import { combineReducers } from 'redux';
import { menuReducer } from './menuReducer';
import { courseReducer } from './courseReducer';
import { dashboardReducer } from './dashboardReducer';
import { certificateReducer } from './certificateReducer';
import { knowledgeCheckReducer } from './knowledgeCheckReducer';
import { searchReducer } from './searchReducer';
import { campaignReducer } from './campaignReducer';
import { favoriteReducer } from './favoriteReducer';
import { surveyReducer } from './surveyReducer';
import { sectionReducer } from './sectionReducer';
import { videoReducer } from './videoReducer';
import { moduleReducer } from './moduleReducer';

export const reducers = combineReducers({
  menu: menuReducer,
  search: searchReducer,
  course: courseReducer,
  favorite: favoriteReducer,
  dashboard: dashboardReducer,
  certificate: certificateReducer,
  knowledgeCheck: knowledgeCheckReducer,
  campaign: campaignReducer,
  survey: surveyReducer,
  module: moduleReducer,
  section: sectionReducer,
  video: videoReducer,
});
