// MODEL
export default class KnowledgeCheck {
  created_at = '';
  has_pending_checks = false;
  has_pending_checks_score = 0;
  id = null;
  knowledge_check_questions_options_answers = [];
  module_section = null;
  name = '';
  updated_at = '';
  url = '';
  answers = []; // Virtual prop
  retake = false; // Virtual prop

  constructor(props) {
    if (props) {
      this.created_at = props.created_at;
      this.has_pending_checks = props.has_pending_checks;
      this.has_pending_checks_score = props.has_pending_checks_score;
      this.id = props.id;
      this.is_order_random = props.is_order_random;
      this.knowledge_check_questions_options_answers =
        props.knowledge_check_questions_options_answers.map(
          (x) =>
            new KnowledgeCheckQuestionAnswer({
              ...x,
              ...{ is_order_random: this.is_order_random },
            })
        );
      this.module_section = props.module_section;
      this.name = props.name;
      this.updated_at = props.updated_at;
      this.url = props.url;
    }
  }
}

class KnowledgeCheckQuestionAnswer {
  created_at = '';
  id = null;
  knowledge_check_answers = [];
  knowledge_check_id = null;
  knowledge_check_options = [];
  multiple = false;
  name = '';
  updated_at = '';
  is_order_random = false; // virtual prop

  constructor(props) {
    if (props) {
      this.created_at = props.created_at;
      this.id = props.id;
      this.knowledge_check_answers = props.knowledge_check_answers;
      this.knowledge_check_id = props.knowledge_check_id;
      this.knowledge_check_options = props.knowledge_check_options.map(
        (x) => new KnowledgeCheckOption(x)
      );
      this.multiple = props.multiple;
      this.name = props.name;
      this.updated_at = props.updated_at;

      if (props.is_order_random)
        this.knowledge_check_options =
          KnowledgeCheckQuestionAnswer.shuffleAnswers(
            this.knowledge_check_options
          );
    }
  }

  static shuffleAnswers = (answers) => {
    for (let i = answers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [answers[i], answers[j]] = [answers[j], answers[i]];
    }
    return answers;
  };
}

class KnowledgeCheckOption {
  correct = false;
  created_at = '';
  id = null;
  knowledge_check_question_id = null;
  name = '';
  updated_at = '';

  constructor(props) {
    if (props) {
      this.correct = props.correct;
      this.id = props.id;
      this.knowledge_check_question_id = props.knowledge_check_question_id;
      this.name = props.name;
      this.created_at = props.created_at;
      this.updated_at = props.updated_at;
    }
  }
}
