import React from 'react';

const RateControl = ({ onChange, rating }) => {
  return (
    <>
      Like
      <i
        className={
          'fa fa-light video-rate-up ' +
          (rating === 5 ? 'rating-selected fa-thumbs-up ' : 'fa-thumbs-o-up ')
        }
        onClick={() => {
          onChange(rating === 5 ? 0 : 5);
        }}
      ></i>
      <i
        className={
          'fa fa-light video-rate-down ' +
          (rating === 1
            ? 'rating-selected fa-thumbs-down '
            : 'fa-thumbs-o-down ')
        }
        onClick={() => {
          onChange(rating === 1 ? 0 : 1);
        }}
      ></i>
    </>
  );
};

RateControl.defaultProps = {
  onChange: () => {},
  rating: 0,
};

export default RateControl;
