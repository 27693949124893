import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import SectionHome from '../SectionHome';

const FavoriteVideos = () => {
  const { favoriteVideos, errorMessage } = useSelector(
    (state) => state.favorite
  );
  const [showVideos, setShowVideos] = useState(false);

  useEffect(() => {
    setShowVideos(
      favoriteVideos && favoriteVideos.length && favoriteVideos.length > 0
    );
  }, [favoriteVideos]);

  return (
    <>
      <h5 className="mt-3 search-title fw-300 font-poppins pb-3 mb-5 font-25 text-color-primary border-bottom-2">
        Bookmarks
      </h5>
      {!!showVideos && (
        <>
          <div className="mt-3">
            {errorMessage !== '' && errorMessage}
            {errorMessage === '' && (
              <SectionHome stopVideo={false} fromFavorites={true} />
            )}
          </div>
        </>
      )}
      {!showVideos && (
        <p className="my-3 m-0 fw-300 font-poppins font-18 text-color-primary">
          No bookmarks added
        </p>
      )}
    </>
  );
};

export default FavoriteVideos;
