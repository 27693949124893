import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthContext } from '../context/authContext';
import {
  fetchCampaignByVideo,
  fetchCampaignEnrollment,
} from '../redux/actions/campaignActions';
import { UserModel } from '../context/authContext/authActions';
import { SIGNUP_WITH_TOKEN_SUCCESS } from '../constants/actionTypes';

const useCampaign = ({
  campaign_id = undefined,
  video_id = undefined,
} = {}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const enrollToken = queryParams.get('enroll');

  const dispatch = useDispatch();

  const { globalLoading } = useSelector((state) => state.dashboard);
  const { campaign } = useSelector((state) => state.campaign);

  const { dispatch: authDispatch } = useAuthContext();

  // Case enrollment ID
  useEffect(() => {
    if (enrollToken) {
      dispatch(fetchCampaignEnrollment(enrollToken));
    }

    // eslint-disable-next-line
  }, [enrollToken]);

  useEffect(() => {
    const initializeCampaign = async () => {
      if (campaign) {
        const userModel = new UserModel({
          ...campaign.user,
          ...{ token: campaign.token },
        });
        authDispatch({ type: SIGNUP_WITH_TOKEN_SUCCESS, payload: userModel });
      }
    };
    initializeCampaign().catch(console.error);

    // eslint-disable-next-line
  }, [campaign]);

  // CASE CAMPAIGN ID AND VIDEO ID
  useEffect(() => {
    if (campaign_id && video_id) {
      dispatch(fetchCampaignByVideo(video_id));
    }

    // eslint-disable-next-line
  }, [campaign_id, video_id]);

  return {
    globalLoading,
  };
};

export default useCampaign;
