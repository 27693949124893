import React from 'react';
import signInStyle from '../styles/SignIn.module.scss';
import GoogleIcon from '../assets/icons/google-icon.svg';

const GoogleButton = ({ onClick, text, disabled }) => {
  return (
    <button
      className={signInStyle['sign-in-button-external']}
      onClick={onClick}
      disabled={disabled}
    >
      <img src={GoogleIcon} alt={'Google Logo'} className="float-left" />
      <span>{text}</span>
    </button>
  );
};

GoogleButton.defaultProps = {
  onClick: () => {},
  text: '',
};

export default GoogleButton;
