import * as React from 'react';
import {
  LOGIN_WITH_EMAIL_ERROR,
  LOGIN_WITH_EMAIL_REQUEST,
  LOGIN_WITH_EMAIL_SUCCESS,
  LOGIN_WITH_GOOGLE_ERROR,
  LOGIN_WITH_GOOGLE_REQUEST,
  LOGIN_WITH_GOOGLE_SUCCESS,
  LOGOUT,
  SIGNUP_WITH_EMAIL_REQUEST,
  SIGNUP_WITH_EMAIL_SUCCESS,
  SIGNUP_WITH_EMAIL_ERROR,
  RESET_PASSWORD_SUCCESS_EMAIL,
  RESET_PASSWORD_UPDATE_REQUEST,
  RESET_PASSWORD_UPDATE_SUCCESS_EMAIL,
  RESET_PASSWORD_UPDATE_ERROR_EMAIL,
  PASSWORD_UPDATE_REQUEST,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_ERROR,
  SIGNUP_WITH_TOKEN_SUCCESS,
  REGISTRATION_CODE_UPDATE_REQUEST,
  REGISTRATION_CODE_UPDATE_SUCCESS,
  REGISTRATION_CODE_UPDATE_ERROR,
} from '../../constants/actionTypes';

//Context
const AuthContext = React.createContext();

const user = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user'))
  : null;
const token = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user')).token
  : '';

//Reducer
const initialState = {
  user: user,
  token: token,
  isLoading: false,
  error: null,
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_WITH_EMAIL_REQUEST:
    case LOGIN_WITH_GOOGLE_REQUEST:
    case RESET_PASSWORD_UPDATE_REQUEST:
    case PASSWORD_UPDATE_REQUEST:
    case SIGNUP_WITH_EMAIL_REQUEST:
    case REGISTRATION_CODE_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case SIGNUP_WITH_EMAIL_SUCCESS:
      return {
        ...state,
      };
    case LOGIN_WITH_EMAIL_SUCCESS:
    case LOGIN_WITH_GOOGLE_SUCCESS:
    case PASSWORD_UPDATE_SUCCESS:
    case SIGNUP_WITH_TOKEN_SUCCESS:
    case REGISTRATION_CODE_UPDATE_SUCCESS:
      localStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
        isLoading: false,
        error: null,
      };
    case LOGIN_WITH_EMAIL_ERROR:
    case LOGIN_WITH_GOOGLE_ERROR:
    case SIGNUP_WITH_EMAIL_ERROR:
    case RESET_PASSWORD_UPDATE_ERROR_EMAIL:
    case PASSWORD_UPDATE_ERROR:
    case REGISTRATION_CODE_UPDATE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case LOGOUT:
      localStorage.clear();
      return {
        user: null,
        token: null,
        error: null,
      };
    case RESET_PASSWORD_UPDATE_SUCCESS_EMAIL:
    case RESET_PASSWORD_SUCCESS_EMAIL:
      return {
        ...state,
      };
    default:
      throw new Error(`Unknown action type ${action.type}`);
  }
};

//Provider
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(authReducer, initialState);
  const value = { state, dispatch };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

//hook
export const useAuthContext = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be within a AuthProvider');
  }
  return context;
};
