import React from 'react';
import Logo from './Logo';
import TermsAndConditions from './TermsAndConditions';

const TermsOfService = () => {
  return (
    <>
      <div className={`container`}>
        <div className={`row`}>
          <div className="col-12 text-center mt-4">
            <Logo />
          </div>
        </div>
        <div className={`row`}>
          <div className="col-12 text-center mt-4">
            <h2>Terms & Conditions</h2>
          </div>
        </div>
        <div className={`row`}>
          <div className="col-12">
            <TermsAndConditions />
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
