import {
  FETCH_COURSES_FAILURE,
  FETCH_COURSES_HELP_CENTER,
  FETCH_COURSES_REQUEST,
  FETCH_COURSES_SUCCESS,
} from '../types/courseTypes';
import { BASE_API_URL } from '../../constants/constants';
import { requestGetApi } from '../../services/api/api';

const fetchCoursesRequest = () => ({
  type: FETCH_COURSES_REQUEST,
});

export const fetchCoursesSuccess = (courses) => ({
  type: FETCH_COURSES_SUCCESS,
  payload: courses,
});

const fetchCoursesFailure = (error) => ({
  type: FETCH_COURSES_FAILURE,
  payload: error,
});

export const fetchCoursesHelpCenter = () => ({
  type: FETCH_COURSES_HELP_CENTER,
});

export const fetchCoursesMenu = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchCoursesRequest());
      const response = await requestGetApi(`${BASE_API_URL}/courses/flat`);

      const courses = response.courses;
      dispatch(fetchCoursesSuccess([...courses] || []));
    } catch (error) {
      dispatch(fetchCoursesFailure(error));
    }
  };
};
