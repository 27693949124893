import {
  FETCH_SURVEY_FAILURE,
  FETCH_SURVEY_REQUEST,
  FETCH_SURVEY_SUCCESS,
  POST_SURVEY_FAILURE,
  POST_SURVEY_REQUEST,
  POST_SURVEY_SUCCESS,
  RESET_SURVEY_DATA,
} from '../types/surveyTypes';
import Survey from '../../models/survey';

const initialState = {
  showSurvey: false,
  surveys: [],
  score: 0,
  error: '',
  loading: false,
  fetchLoading: false,
};

export const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SURVEY_REQUEST:
      return {
        ...state,
        fetchLoading: true,
        error: '',
      };
    case RESET_SURVEY_DATA:
      return {
        ...state,
        surveys: [],
        fetchLoading: false,
        error: '',
      };
    case FETCH_SURVEY_SUCCESS:
      return {
        ...state,
        fetchLoading: false,
        surveys: action.payload
          .map((survey) => new Survey(survey))
          .filter((x) => !x.user_survey_attempts.length)
          .sort((a, b) => a.order - b.order),
      };
    case FETCH_SURVEY_FAILURE:
      return {
        ...state,
        fetchLoading: false,
        error: action.payload,
      };
    case POST_SURVEY_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case POST_SURVEY_SUCCESS:
      return {
        ...state,
        score: 100,
        loading: false,
      };
    case POST_SURVEY_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
